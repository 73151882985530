import { Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, anonRecord_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { append, ofArray, singleton, empty, tail, head, isEmpty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { Route_$007CQuery$007C_$007C } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { RouterModule_trySeparateLast, RouterModule_encodeQueryString, RouterModule_nav } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { map, defaultArgWith } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { Cmd_ofEffect } from "../fable_modules/Feliz.Router.4.0.0/../Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";

export class Page extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Index", "Products", "StressMaster", "RememberWhereInfo", "LogMaster", "WorkMate", "VehicleSearch", "UserSearch", "LeavesSearch", "UserMemberships", "SkillSearch", "JobSearch", "TimesheetSearch", "ShiftSearch", "TimesheetPaymentSearch", "LogMasterDashboard", "WorkMateDashboard", "WorkMateReports", "LogReport", "SignUp", "SignIn", "ResetPassword", "Dashboard", "ScanBankStatement", "Organisation", "Customer", "CustomerEdit", "About", "PrivacyPolicy", "Terms", "AcceptInvite", "ConnectToXero", "LinkToXero", "SelectDataToSyncFromXero", "InvoiceRunSearch", "HolidaySearch", "ExpenseSearch", "SimproConnectionDetailSearch"];
    }
}

export function Page_$reflection() {
    return union_type("Presentation.Router.Page", [], Page, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [["Item", string_type]], [], [], [], [["Item", string_type]], [], [["Item", anonRecord_type(["Code", string_type], ["Scope", string_type], ["SessionState", string_type], ["State", string_type])]], [], [], [], [], []]);
}

export const PageModule_defaultPage = new Page(0, []);

export function PageModule_parseFromUrlSegments(_arg) {
    let matchResult, id, id_1, code, scope, session_state, state;
    if (!isEmpty(_arg)) {
        switch (head(_arg)) {
            case "about": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 0;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "signup": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 1;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "signin": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 2;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "resetPassword": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 3;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "dashboard": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 4;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "scanBankStatement": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 5;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "organisation": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 6;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "customer": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 7;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "customerEdit": {
                if (!isEmpty(tail(_arg))) {
                    if (isEmpty(tail(tail(_arg)))) {
                        matchResult = 8;
                        id = head(tail(_arg));
                    }
                    else {
                        matchResult = 32;
                    }
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "products": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 9;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "stressMaster": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 10;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "rememberWhereInfo": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 11;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "logMaster": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 12;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "workMate": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 13;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "vehicleSearch": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 14;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "userSearch": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 15;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "leavesSearch": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 16;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "skillSearch": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 17;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "jobSearch": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 18;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "timesheetSearch": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 19;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "shiftSearch": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 20;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "timesheetPaymentSearch": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 21;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "userMemberships": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 22;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "logMasterDashboard": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 23;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "workMateDashboard": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 24;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "workMateReports": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 25;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "logReport": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 26;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "privacyPolicy": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 27;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "terms": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 28;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "acceptInvite": {
                if (!isEmpty(tail(_arg))) {
                    if (isEmpty(tail(tail(_arg)))) {
                        matchResult = 29;
                        id_1 = head(tail(_arg));
                    }
                    else {
                        matchResult = 32;
                    }
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "connectToXero": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 30;
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            case "linkToXero": {
                if (!isEmpty(tail(_arg))) {
                    const activePatternResult = Route_$007CQuery$007C_$007C(head(tail(_arg)));
                    if (activePatternResult != null) {
                        if (!isEmpty(activePatternResult)) {
                            if (head(activePatternResult)[0] === "code") {
                                if (!isEmpty(tail(activePatternResult))) {
                                    if (head(tail(activePatternResult))[0] === "scope") {
                                        if (!isEmpty(tail(tail(activePatternResult)))) {
                                            if (head(tail(tail(activePatternResult)))[0] === "state") {
                                                if (!isEmpty(tail(tail(tail(activePatternResult))))) {
                                                    if (head(tail(tail(tail(activePatternResult))))[0] === "session_state") {
                                                        if (isEmpty(tail(tail(tail(tail(activePatternResult)))))) {
                                                            if (isEmpty(tail(tail(_arg)))) {
                                                                matchResult = 31;
                                                                code = head(activePatternResult)[1];
                                                                scope = head(tail(activePatternResult))[1];
                                                                session_state = head(tail(tail(tail(activePatternResult))))[1];
                                                                state = head(tail(tail(activePatternResult)))[1];
                                                            }
                                                            else {
                                                                matchResult = 32;
                                                            }
                                                        }
                                                        else {
                                                            matchResult = 32;
                                                        }
                                                    }
                                                    else {
                                                        matchResult = 32;
                                                    }
                                                }
                                                else {
                                                    matchResult = 32;
                                                }
                                            }
                                            else {
                                                matchResult = 32;
                                            }
                                        }
                                        else {
                                            matchResult = 32;
                                        }
                                    }
                                    else {
                                        matchResult = 32;
                                    }
                                }
                                else {
                                    matchResult = 32;
                                }
                            }
                            else {
                                matchResult = 32;
                            }
                        }
                        else {
                            matchResult = 32;
                        }
                    }
                    else {
                        matchResult = 32;
                    }
                }
                else {
                    matchResult = 32;
                }
                break;
            }
            default:
                matchResult = 32;
        }
    }
    else {
        matchResult = 32;
    }
    switch (matchResult) {
        case 0:
            return new Page(27, []);
        case 1:
            return new Page(19, []);
        case 2:
            return new Page(20, []);
        case 3:
            return new Page(21, []);
        case 4:
            return new Page(22, []);
        case 5:
            return new Page(23, []);
        case 6:
            return new Page(24, []);
        case 7:
            return new Page(25, []);
        case 8:
            return new Page(26, [id]);
        case 9:
            return new Page(1, []);
        case 10:
            return new Page(2, []);
        case 11:
            return new Page(3, []);
        case 12:
            return new Page(4, []);
        case 13:
            return new Page(5, []);
        case 14:
            return new Page(6, []);
        case 15:
            return new Page(7, []);
        case 16:
            return new Page(8, []);
        case 17:
            return new Page(10, []);
        case 18:
            return new Page(11, []);
        case 19:
            return new Page(12, []);
        case 20:
            return new Page(13, []);
        case 21:
            return new Page(14, []);
        case 22:
            return new Page(9, []);
        case 23:
            return new Page(15, []);
        case 24:
            return new Page(16, []);
        case 25:
            return new Page(17, []);
        case 26:
            return new Page(18, []);
        case 27:
            return new Page(28, []);
        case 28:
            return new Page(29, []);
        case 29:
            return new Page(30, [id_1]);
        case 30:
            return new Page(31, []);
        case 31:
            return new Page(32, [{
                Code: code,
                Scope: scope,
                SessionState: session_state,
                State: state,
            }]);
        default: {
            let matchResult_1;
            if (isEmpty(_arg)) {
                matchResult_1 = 5;
            }
            else {
                switch (head(_arg)) {
                    case "selectDataToSyncFromXero": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 0;
                        }
                        else {
                            matchResult_1 = 6;
                        }
                        break;
                    }
                    case "invoiceRunSearch": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 1;
                        }
                        else {
                            matchResult_1 = 6;
                        }
                        break;
                    }
                    case "holidaySearch": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 2;
                        }
                        else {
                            matchResult_1 = 6;
                        }
                        break;
                    }
                    case "expenseSearch": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 3;
                        }
                        else {
                            matchResult_1 = 6;
                        }
                        break;
                    }
                    case "simproConnectionDetailSearch": {
                        if (isEmpty(tail(_arg))) {
                            matchResult_1 = 4;
                        }
                        else {
                            matchResult_1 = 6;
                        }
                        break;
                    }
                    default:
                        matchResult_1 = 6;
                }
            }
            switch (matchResult_1) {
                case 0:
                    return new Page(33, []);
                case 1:
                    return new Page(34, []);
                case 2:
                    return new Page(35, []);
                case 3:
                    return new Page(36, []);
                case 4:
                    return new Page(37, []);
                case 5:
                    return new Page(0, []);
                default:
                    return PageModule_defaultPage;
            }
        }
    }
}

export function PageModule_noQueryString(segments) {
    return [segments, empty()];
}

export function PageModule_toUrlSegments(_arg) {
    switch (_arg.tag) {
        case 19:
            return PageModule_noQueryString(singleton("signup"));
        case 20:
            return PageModule_noQueryString(singleton("signin"));
        case 21:
            return PageModule_noQueryString(singleton("resetPassword"));
        case 22:
            return PageModule_noQueryString(singleton("dashboard"));
        case 23:
            return PageModule_noQueryString(singleton("scanBankStatement"));
        case 24:
            return PageModule_noQueryString(singleton("organisation"));
        case 25:
            return PageModule_noQueryString(singleton("customer"));
        case 26:
            return PageModule_noQueryString(ofArray(["customerEdit", _arg.fields[0]]));
        case 27:
            return PageModule_noQueryString(singleton("about"));
        case 1:
            return PageModule_noQueryString(singleton("products"));
        case 2:
            return PageModule_noQueryString(singleton("stressMaster"));
        case 3:
            return PageModule_noQueryString(singleton("rememberWhereInfo"));
        case 4:
            return PageModule_noQueryString(singleton("logMaster"));
        case 5:
            return PageModule_noQueryString(singleton("workMate"));
        case 6:
            return PageModule_noQueryString(singleton("vehicleSearch"));
        case 7:
            return PageModule_noQueryString(singleton("userSearch"));
        case 8:
            return PageModule_noQueryString(singleton("leavesSearch"));
        case 10:
            return PageModule_noQueryString(singleton("skillSearch"));
        case 11:
            return PageModule_noQueryString(singleton("jobSearch"));
        case 12:
            return PageModule_noQueryString(singleton("timesheetSearch"));
        case 13:
            return PageModule_noQueryString(singleton("shiftSearch"));
        case 14:
            return PageModule_noQueryString(singleton("timesheetPaymentSearch"));
        case 9:
            return PageModule_noQueryString(singleton("userMemberships"));
        case 15:
            return PageModule_noQueryString(singleton("logMasterDashboard"));
        case 16:
            return PageModule_noQueryString(singleton("workMateDashboard"));
        case 17:
            return PageModule_noQueryString(singleton("workMateReports"));
        case 18:
            return PageModule_noQueryString(singleton("logReport"));
        case 28:
            return PageModule_noQueryString(singleton("privacyPolicy"));
        case 29:
            return PageModule_noQueryString(singleton("terms"));
        case 30:
            return PageModule_noQueryString(ofArray(["acceptInvite", _arg.fields[0]]));
        case 31:
            return PageModule_noQueryString(singleton("connectToXero"));
        case 32: {
            const x = _arg.fields[0];
            return PageModule_noQueryString(ofArray(["linkToXero", x.Code, x.Scope, x.State, x.SessionState]));
        }
        case 33:
            return PageModule_noQueryString(singleton("selectDataToSyncFromXero"));
        case 34:
            return PageModule_noQueryString(singleton("invoiceRunSearch"));
        case 35:
            return PageModule_noQueryString(singleton("holidaySearch"));
        case 36:
            return PageModule_noQueryString(singleton("expenseSearch"));
        case 37:
            return PageModule_noQueryString(singleton("simproConnectionDetailSearch"));
        default:
            return PageModule_noQueryString(empty());
    }
}

export function Router_goToUrl(e) {
    e.preventDefault();
    RouterModule_nav(singleton(e.currentTarget.attributes.href.value), 1, 2);
}

export function Router_navigatePage(p) {
    const tupledArg = PageModule_toUrlSegments(p);
    const queryString = tupledArg[1];
    defaultArgWith(map((tupledArg_1) => {
        RouterModule_nav(append(tupledArg_1[0], singleton(tupledArg_1[1] + RouterModule_encodeQueryString(queryString))), 1, 2);
    }, RouterModule_trySeparateLast(tupledArg[0])), () => {
        RouterModule_nav(singleton(RouterModule_encodeQueryString(queryString)), 1, 2);
    });
}

export function Cmd_navigateToPage(p) {
    const tupledArg = PageModule_toUrlSegments(p);
    return Cmd_ofEffect((_arg_1) => {
        const queryString_1 = tupledArg[1];
        defaultArgWith(map((tupledArg_1) => {
            RouterModule_nav(append(tupledArg_1[0], singleton(tupledArg_1[1] + RouterModule_encodeQueryString(queryString_1))), 1, 2);
        }, RouterModule_trySeparateLast(tupledArg[0])), () => {
            RouterModule_nav(singleton(RouterModule_encodeQueryString(queryString_1)), 1, 2);
        });
    });
}

export function Cmd_goBack() {
    history.go(-1);
    return Cmd_none();
}

