import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, decimal_type, string_type, record_type, option_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";

export class StatementPeriod extends Record {
    constructor(Start, End) {
        super();
        this.Start = Start;
        this.End = End;
    }
}

export function StatementPeriod_$reflection() {
    return record_type("Domain.Entity.Document.StatementPeriod", [], StatementPeriod, () => [["Start", option_type(class_type("System.DateTime"))], ["End", option_type(class_type("System.DateTime"))]]);
}

export class ScanResultStatementDetail extends Record {
    constructor(Id, TransactionDate, Description, Amount) {
        super();
        this.Id = Id;
        this.TransactionDate = TransactionDate;
        this.Description = Description;
        this.Amount = Amount;
    }
}

export function ScanResultStatementDetail_$reflection() {
    return record_type("Domain.Entity.Document.ScanResultStatementDetail", [], ScanResultStatementDetail, () => [["Id", option_type(class_type("System.Guid"))], ["TransactionDate", string_type], ["Description", string_type], ["Amount", decimal_type]]);
}

export class ScanResultBase$1 extends Record {
    constructor(Id, OrganisationId, DateScanned, VendorName, CustomerName, Address, Period, TotalAmount, Details) {
        super();
        this.Id = Id;
        this.OrganisationId = OrganisationId;
        this.DateScanned = DateScanned;
        this.VendorName = VendorName;
        this.CustomerName = CustomerName;
        this.Address = Address;
        this.Period = Period;
        this.TotalAmount = TotalAmount;
        this.Details = Details;
    }
}

export function ScanResultBase$1_$reflection(gen0) {
    return record_type("Domain.Entity.Document.ScanResultBase`1", [gen0], ScanResultBase$1, () => [["Id", class_type("System.Guid")], ["OrganisationId", class_type("System.Guid")], ["DateScanned", class_type("System.DateTime")], ["VendorName", string_type], ["CustomerName", string_type], ["Address", string_type], ["Period", StatementPeriod_$reflection()], ["TotalAmount", option_type(decimal_type)], ["Details", list_type(gen0)]]);
}

