import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchShifts } from "../../../AppDomain/UseCase/Timesheet.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { singleton, ofArray } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { singleton as singleton_1, append, map, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_h5, Daisy_DialogWidth, alignIconCenter, alignCellTextTop } from "../../Component/Component.fs.js";
import { DateTime_dateTimeToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { map as map_1, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { IndexView as IndexView_1 } from "./ShiftEdit.fs.js";
import { IndexView as IndexView_2, Msg$2 } from "../BaseSearchAdvanced.fs.js";
import { StatusToSearch as StatusToSearch_1, statusesToSearch, StatusToSearchModule_ofString, StatusToSearchModule_toString, ShiftSearchDetails } from "../../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { PropHelpers_createOnKey } from "../../../fable_modules/Feliz.2.8.0/./Properties.fs.js";
import { key_enter } from "../../../fable_modules/Feliz.2.8.0/Key.fs.js";
import { name as name_56 } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { String_splitWord } from "../../../../Logos.Shared/Util.fs.js";

export function searchFunc(shiftSearchDetails) {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (pagination) => searchShifts(appRoot.ShiftService, selectedOrgId, shiftSearchDetails, pagination);
}

export function shiftTable(props) {
    let elems_6, children_2, children, children_8;
    const children_11 = singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_6 = [(children_2 = singleton((children = ofArray([createElement("th", {
        width: 30 + "%",
        children: "Job",
    }), createElement("th", {
        width: 20 + "%",
        children: "User",
    }), createElement("th", {
        width: 10 + "%",
        children: "Created",
    }), createElement("th", {
        width: 10 + "%",
        children: "Shift Started",
    }), createElement("th", {
        width: 10 + "%",
        children: "Shift Finished",
    }), createElement("th", {
        width: 10 + "%",
        children: "Status",
    }), createElement("th", {
        width: 10 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_8 = toList(delay(() => map((shift) => {
        let elems_5, elems_2, elems_1, elems, children_4, elems_3, children_6, elems_4;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_5 = [createElement("td", createObj(ofArray([alignCellTextTop, (elems_2 = [createElement("div", createObj(singleton((elems_1 = [createElement("span", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "center",
        }], (elems = [createElement("i", {
            className: join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => (shift.IsFinished ? singleton_1("fa-check") : singleton_1("fa-hourglass"))))))),
            style: createObj(toList(delay(() => append(singleton_1(["marginRight", "8px"]), delay(() => (shift.IsFinished ? singleton_1(["color", "green"]) : singleton_1(["color", "orange"]))))))),
        }), createElement("div", createObj(ofArray([alignCellTextTop, ["children", shift.JobTitle]])))], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("td", createObj(ofArray([alignCellTextTop, ["children", shift.Username]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["children", DateTime_dateTimeToStringWithDayName(shift.Created)]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["children", defaultArg(map_1(DateTime_dateTimeToStringWithDayName, shift.ShiftStarted), "")]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["children", defaultArg(map_1(DateTime_dateTimeToStringWithDayName, shift.ShiftFinished), "")]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["children", shift.IsFinished ? "Finished" : "In Progress"]]))), (children_4 = singleton(createElement("div", createObj(ofArray([alignCellTextTop, ["key", shift.Id], (elems_3 = [createElement("div", {
            className: join(" ", ["font-bold"]),
            children: "",
        })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_4)),
        })), (children_6 = singleton(createElement("div", createObj(ofArray([alignIconCenter, (elems_4 = [createElement(showModalEx, {
            dialogId: shift.Id,
            header: "",
            buttonType: new ButtonType(1, ["fa-edit"]),
            width: new Daisy_DialogWidth(4, []),
            heightPercent: "80",
            body: () => {
                const matchValue = props.state.EntityToEdit;
                if (matchValue == null) {
                    const xs = [Daisy_h5("No Shift selected.")];
                    return react.createElement(react.Fragment, {}, ...xs);
                }
                else {
                    return createElement(IndexView_1, {
                        shiftId: matchValue,
                    });
                }
            },
            onShow: () => {
                props.dispatch(new Msg$2(4, [shift.Id]));
            },
            onClose: () => {
                props.dispatch(new Msg$2(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_6)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
    }, props.state.Entities))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_11)),
    });
}

export function searchPanel(props) {
    let children_2, elems, children_6, elems_3, elems_2;
    const children_8 = ofArray([(children_2 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Search"])))), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "w-full"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["placeholder", "Search"], ["readOnly", false], ["onChange", (ev) => {
        let bind$0040;
        props.dispatch(new Msg$2(0, [(bind$0040 = props.state.SearchDetails, new ShiftSearchDetails(ev.target.value, bind$0040.StatusToSearch, bind$0040.DateRange))]));
    }], ["onKeyUp", (ev_1) => {
        PropHelpers_createOnKey(key_enter, (_arg) => {
            props.dispatch(new Msg$2(1, []));
        }, ev_1);
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Search"], ["onClick", (_arg_1) => {
        props.dispatch(new Msg$2(1, []));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_6 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Status"])))), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems_3 = [createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Status"], ["value", StatusToSearchModule_toString(props.state.SearchDetails.StatusToSearch)], ["onChange", (ev_2) => {
        let bind$0040_1;
        props.dispatch(new Msg$2(0, [(bind$0040_1 = props.state.SearchDetails, new ShiftSearchDetails(bind$0040_1.SearchText, StatusToSearchModule_ofString(ev_2.target.value), bind$0040_1.DateRange))]));
    }], (elems_2 = toList(delay(() => map((case$) => createElement("option", {
        value: name_56(case$),
        children: String_splitWord(name_56(case$)),
    }), statusesToSearch))), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    });
}

export function IndexView() {
    return createElement(IndexView_2, {
        entityName: "Shifts",
        entityTable: shiftTable,
        searchDetails: new ShiftSearchDetails("", new StatusToSearch_1(0, []), undefined),
        searchFunc: uncurry2(searchFunc),
        searchPanel: searchPanel,
    });
}

