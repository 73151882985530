import { Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { WorkerJobAllocation_$reflection } from "../../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { class_type, union_type, record_type, string_type, list_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getWorkerJobAllocations } from "../../../../AppDomain/UseCase/Job.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { map as map_1, toArray, sumBy, ofArray, singleton as singleton_1, empty } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { int64ToString, createObj } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { format, join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { map, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { toInt32, op_Addition, toInt64 } from "../../../../fable_modules/fable-library-js.4.19.3/BigInt.js";
import { doughnut } from "../../../Component/Doughnut.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { DataItem, IndexView as IndexView_1 } from "../../../Component/DoghnutViewBase.fs.js";

export class State extends Record {
    constructor(WorkerJobAllocations, IsBusy, Errors) {
        super();
        this.WorkerJobAllocations = WorkerJobAllocations;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.WorkMate.WorkerJobAllocation.State", [], State, () => [["WorkerJobAllocations", list_type(WorkerJobAllocation_$reflection())], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetWorkerJobAllocationsResult", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.WorkMate.WorkerJobAllocation.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(WorkerJobAllocation_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(WorkerJobAllocation_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getWorkerJobAllocationsCmd() {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(1, [Item_1])), () => singleton.Delay(() => getWorkerJobAllocations(appRoot.JobService, AppRootModule_getSelectedOrgId())));
}

export function init() {
    return [new State(empty(), new Busy(1, []), empty()), getWorkerJobAllocationsCmd()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(state.WorkerJobAllocations, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
    }
    else if (msg.fields[0].tag === 1) {
        return [new State(state.WorkerJobAllocations, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
    }
    else {
        return [new State(msg.fields[0].fields[0], new Busy(0, []), state.Errors), Cmd_none()];
    }
}

function IndexWorkerJobAllocation(state) {
    let elems_4, children_2, children, children_4, children_6, elems_3, elems_2, elems_5;
    const children_10 = ofArray([createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_4 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        children: "Job",
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        children: "Workers Allocated",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = toList(delay(() => map((workerJobAllocation) => {
        let elems_1, elems;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_1 = [createElement("td", {
            children: [workerJobAllocation.JobName],
        }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems = [format('{0:' + "#,#" + '}', workerJobAllocation.TotalWorkersAllocated)], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
    }, state.WorkerJobAllocations))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    })), (children_6 = singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_3 = [createElement("td", {
        children: ["Total"],
    }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_2 = [int64ToString(sumBy((x) => x.TotalWorkersAllocated, state.WorkerJobAllocations, {
        GetZero: () => (0n),
        Add: (x_1, y) => toInt64(op_Addition(x_1, y)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), createElement("tfoot", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-1", "gap-4"])], (elems_5 = [doughnut("Worker Job Allocations", toArray(map_1((x_2) => (`${x_2.JobName}`), state.WorkerJobAllocations)), toArray(map_1((x_3) => ~~toInt32(x_3.TotalWorkersAllocated), state.WorkerJobAllocations)), [], [])], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function IndexView() {
    const state_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, [])[0];
    return createElement(IndexView_1, {
        title: "Worker Job Allocations",
        errors: state_1.Errors,
        isBusy: state_1.IsBusy,
        dataItems: map_1((x) => (new DataItem(x.JobName, x.TotalWorkersAllocated)), state_1.WorkerJobAllocations),
    });
}

