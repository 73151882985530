import { seqToString, toString, Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { Router_navigatePage, Page, Router_goToUrl, PageModule_toUrlSegments, PageModule_defaultPage, Cmd_navigateToPage, PageModule_parseFromUrlSegments, Page_$reflection } from "./Router.fs.js";
import { record_type, option_type, class_type, bool_type, union_type, list_type, string_type, unit_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";
import { LocalUserDataModule_getUserDetails, LocalUserDataModule_UserDetails_$reflection, LocalUserData_$reflection } from "../AppDomain/SystemService.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { IndexView } from "./Pages/TimesheetPayment/TimesheetPaymenRunEdit.fs.js";
import { cmdOfAsync } from "../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { signOut } from "../AppDomain/UseCase/Auth.fs.js";
import { appRoot } from "../Infrastructure/AppRoot.fs.js";
import { RouterModule_router, RouterModule_trySeparateLast, RouterModule_encodeQueryString, RouterModule_encodeParts, RouterModule_urlSegments } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { ofArray, append as append_1, singleton as singleton_2, empty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { unwrap, map, defaultArgWith, defaultArg } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { ModulePermission, PermissionType, ModulePermissionModule_isAllowed } from "../../Logos.Shared/Domain/Entity/User.fs.js";
import { compare, equals, createObj } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { op_PlusPlus } from "../fable_modules/Feliz.DaisyUI.4.2.1/Operators.fs.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { IndexView as IndexView_1 } from "./Pages/Signup.fs.js";
import { IndexView as IndexView_2 } from "./Pages/SignIn.fs.js";
import { IndexView as IndexView_3 } from "./Pages/ResetPassword.fs.js";
import { IndexView as IndexView_4 } from "./Pages/Dashboard.fs.js";
import { IndexViewWithSubscription } from "./Component/MemberCheck.fs.js";
import { IndexView as IndexView_5 } from "./Pages/Organisation.fs.js";
import { IndexView as IndexView_6 } from "./Pages/Customer/CustomerSearch.fs.js";
import { IndexView as IndexView_7 } from "./Pages/Customer/CustomerEdit.fs.js";
import { IndexView as IndexView_8 } from "./Pages/Product/Products.fs.js";
import { IndexView as IndexView_9 } from "./Pages/Product/StressMaster.fs.js";
import { IndexView as IndexView_10 } from "./Pages/Product/RememberWhereInfo.fs.js";
import { IndexView as IndexView_11 } from "./Pages/Product/LogMaster.fs.js";
import { IndexView as IndexView_12 } from "./Pages/Product/WorkMate.fs.js";
import { IndexView as IndexView_13 } from "./Pages/Vehicle/VehicleSearch.fs.js";
import { IndexView as IndexView_14 } from "./Pages/User/UserSearch.fs.js";
import { IndexView as IndexView_15 } from "./Pages/Leaves/LeavesSearch.fs.js";
import { IndexView as IndexView_16 } from "./Pages/Skill/SkillSearch.fs.js";
import { IndexView as IndexView_17 } from "./Pages/Job/JobSearch.fs.js";
import { IndexView as IndexView_18 } from "./Pages/Timesheet/TimesheetSearch.fs.js";
import { IndexView as IndexView_19 } from "./Pages/Shift/ShiftSearch.fs.js";
import { IndexView as IndexView_20 } from "./Pages/TimesheetPayment/TimesheetPaymentSearch.fs.js";
import { IndexView as IndexView_21 } from "./Pages/User/UserMembership.fs.js";
import { IndexView as IndexView_22 } from "./Pages/LogMaster/LogMasterDashboard.fs.js";
import { IndexView as IndexView_23 } from "./Pages/WorkMate/WorkMateDashboard.fs.js";
import { IndexView as IndexView_24 } from "./Pages/WorkMate/WorkMateReports.fs.js";
import { IndexView as IndexView_25 } from "./Pages/Xero/ConnectToXero.fs.js";
import { IndexView as IndexView_26 } from "./Pages/Xero/LinkToXero.fs.js";
import { IndexView as IndexView_27 } from "./Pages/Xero/SelectDataToSyncFromXero.fs.js";
import { IndexView as IndexView_28 } from "./Pages/Invoice/InvoiceSearch.fs.js";
import { IndexView as IndexView_29 } from "./Pages/Holiday/HolidaySearch.fs.js";
import { IndexView as IndexView_30 } from "./Pages/Expense/ExpenseSearch.fs.js";
import { IndexView as IndexView_31 } from "./Pages/Simpro/SimproConnectionDetail/SimproConnectionDetailSearch.fs.js";
import { IndexView as IndexView_32 } from "./Pages/LogMaster/LogReport.fs.js";
import { IndexView as IndexView_33 } from "./Pages/PrivacyPolicy.fs.js";
import { IndexView as IndexView_34 } from "./Pages/Terms.fs.js";
import { indexView } from "./Pages/User/AcceptInvite.fs.js";
import { IndexView as IndexView_35 } from "./Pages/Index.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { IndexView as IndexView_36 } from "./Pages/OrganisationSelector.fs.js";
import { printf, toConsole, isNullOrEmpty } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { Daisy_error } from "./Component/Component.fs.js";
import { ofSeq } from "../fable_modules/fable-library-js.4.19.3/Set.js";
import { unauthorizedAction as unauthorizedAction_1 } from "../Infrastructure/WebApiService/Remoting.fs.js";

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UrlChanged", "ToggleDrawer", "SignOut", "SignOutResponse", "OrgSelectorShowing", "IsValidOrganisationSelected", "SelectOrg", "CancelOrgSelector", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Logos.Client.AppContainer.Msg", [], Msg, () => [[["Item", Page_$reflection()]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", bool_type]], [["Item", bool_type]], [], [], [["Item", class_type("System.Exception")]]]);
}

export class State extends Record {
    constructor(Page, IsDrawerOpen, UserData, UserDetails, IsOrgSelectorShowing, IsValidOrganisation, Error$) {
        super();
        this.Page = Page;
        this.IsDrawerOpen = IsDrawerOpen;
        this.UserData = UserData;
        this.UserDetails = UserDetails;
        this.IsOrgSelectorShowing = IsOrgSelectorShowing;
        this.IsValidOrganisation = IsValidOrganisation;
        this.Error = Error$;
    }
}

export function State_$reflection() {
    return record_type("Logos.Client.AppContainer.State", [], State, () => [["Page", Page_$reflection()], ["IsDrawerOpen", bool_type], ["UserData", option_type(LocalUserData_$reflection())], ["UserDetails", LocalUserDataModule_UserDetails_$reflection()], ["IsOrgSelectorShowing", bool_type], ["IsValidOrganisation", bool_type], ["Error", list_type(string_type)]]);
}

export const appPageService = {
    TimesheetPaymentRunEdit(jobId) {
        return createElement(IndexView, {
            paymentRunId: jobId,
        });
    },
};

export function signOutCmd() {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(8, [Item_1])), () => singleton.Delay(() => {
        const value_1 = signOut(appRoot.SystemService);
        return singleton.Return(value_1);
    }));
}

export function getUserData() {
    const matchValue = appRoot.SystemService.Load("UserData");
    if (matchValue.tag === 1) {
        return undefined;
    }
    else {
        return matchValue.fields[0];
    }
}

export function init() {
    const nextPage = PageModule_parseFromUrlSegments(RouterModule_urlSegments(window.location.pathname + window.location.search, 2));
    const userData = getUserData();
    return [new State(nextPage, false, userData, LocalUserDataModule_getUserDetails(userData), false, false, empty()), Cmd_navigateToPage(nextPage)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.Page, !state.IsDrawerOpen, state.UserData, state.UserDetails, state.IsOrgSelectorShowing, state.IsValidOrganisation, state.Error), Cmd_none()];
        case 2:
            return [state, Cmd_batch(toList(delay(() => append(singleton_1(signOutCmd()), delay(() => (state.IsDrawerOpen ? singleton_1(singleton_2((dispatch) => {
                dispatch(new Msg(1, []));
            })) : empty_1()))))))];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Page, state.IsDrawerOpen, state.UserData, state.UserDetails, state.IsOrgSelectorShowing, state.IsValidOrganisation, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Page, state.IsDrawerOpen, undefined, LocalUserDataModule_getUserDetails(undefined), state.IsOrgSelectorShowing, state.IsValidOrganisation, state.Error), Cmd_navigateToPage(PageModule_defaultPage)];
            }
        case 4:
            return [new State(state.Page, state.IsDrawerOpen, state.UserData, state.UserDetails, msg.fields[0], state.IsValidOrganisation, state.Error), Cmd_none()];
        case 5: {
            const userData = getUserData();
            return [new State(state.Page, state.IsDrawerOpen, userData, LocalUserDataModule_getUserDetails(userData), state.IsOrgSelectorShowing, msg.fields[0], state.Error), Cmd_none()];
        }
        case 6:
            return [new State(state.Page, state.IsDrawerOpen, state.UserData, state.UserDetails, false, state.IsValidOrganisation, state.Error), Cmd_none()];
        case 7:
            return [new State(state.Page, state.IsDrawerOpen, state.UserData, state.UserDetails, false, state.IsValidOrganisation, state.Error), Cmd_none()];
        case 8:
            return [new State(state.Page, state.IsDrawerOpen, state.UserData, state.UserDetails, state.IsOrgSelectorShowing, state.IsValidOrganisation, singleton_2(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [new State(msg.fields[0], state.IsDrawerOpen, getUserData(), state.UserDetails, state.IsOrgSelectorShowing, state.IsValidOrganisation, state.Error), Cmd_none()];
    }
}

export function AppView() {
    let input_1, elems_14, elems_10, elems_13, elems_12, elm_2, elems_1;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isSignedIn = defaultArg((input_1 = state_1.UserData, (input_1 == null) ? undefined : (input_1.Token != null)), false);
    const mi = (requiredPermissions, t, icon, mp) => {
        let value_1;
        if ((value_1 = ModulePermissionModule_isAllowed(state_1.UserDetails.UserRole, requiredPermissions), !(value_1.tag === 1))) {
            const children = singleton_2(createElement("a", createObj(toList(delay(() => append(equals(state_1.Page, mp) ? singleton_1(op_PlusPlus(["className", "active"], ["className", "p-2 rounded-lg"])) : singleton_1(["className", "p-2 rounded-lg"]), delay(() => append(singleton_1(["children", t]), delay(() => {
                let tupledArg, queryString;
                return append(singleton_1(["href", (tupledArg = PageModule_toUrlSegments(mp), (queryString = tupledArg[1], defaultArgWith(map((tupledArg_1) => RouterModule_encodeParts(append_1(tupledArg_1[0], singleton_2(tupledArg_1[1] + RouterModule_encodeQueryString(queryString))), 2), RouterModule_trySeparateLast(tupledArg[0])), () => RouterModule_encodeParts(singleton_2(RouterModule_encodeQueryString(queryString)), 2))))]), delay(() => singleton_1(["onClick", (x_1) => {
                    Router_goToUrl(x_1);
                    dispatch(new Msg(1, []));
                }])));
            })))))))));
            return createElement("li", {
                children: reactApi.Children.toArray(Array.from(children)),
            });
        }
        else {
            return react.createElement(react.Fragment, {});
        }
    };
    const miTitle = (requiredPermissions_1, t_1) => {
        let value_15, elems;
        if ((value_15 = ModulePermissionModule_isAllowed(state_1.UserDetails.UserRole, requiredPermissions_1), !(value_15.tag === 1))) {
            return createElement("li", createObj(ofArray([["className", "menu-title"], (elems = [createElement("a", {
                children: t_1,
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
        }
        else {
            return react.createElement(react.Fragment, {});
        }
    };
    let pageView;
    const matchValue = state_1.Page;
    switch (matchValue.tag) {
        case 19: {
            pageView = createElement(IndexView_1, null);
            break;
        }
        case 20: {
            pageView = createElement(IndexView_2, {
                OnValidOrganisationSelected: (arg) => {
                    dispatch(new Msg(5, [arg]));
                },
            });
            break;
        }
        case 21: {
            pageView = createElement(IndexView_3, null);
            break;
        }
        case 22: {
            pageView = createElement(IndexView_4, null);
            break;
        }
        case 23: {
            pageView = react.createElement(react.Fragment, {});
            break;
        }
        case 24: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn,
                element: () => createElement(IndexView_5, null),
            });
            break;
        }
        case 25: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn,
                element: () => createElement(IndexView_6, null),
            });
            break;
        }
        case 26: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn,
                element: (customerId) => createElement(IndexView_7, {
                    customerId: customerId,
                }),
                args: matchValue.fields[0],
            });
            break;
        }
        case 27: {
            pageView = "10thDoor";
            break;
        }
        case 1: {
            pageView = createElement(IndexView_8, {
                userData: unwrap(state_1.UserData),
            });
            break;
        }
        case 2: {
            pageView = createElement(IndexView_9, null);
            break;
        }
        case 3: {
            pageView = createElement(IndexView_10, null);
            break;
        }
        case 4: {
            pageView = createElement(IndexView_11, null);
            break;
        }
        case 5: {
            pageView = createElement(IndexView_12, null);
            break;
        }
        case 6: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn,
                element: () => createElement(IndexView_13, null),
            });
            break;
        }
        case 7: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasAnySubscription,
                element: () => createElement(IndexView_14, null),
            });
            break;
        }
        case 8: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_15, null),
            });
            break;
        }
        case 10: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_16, null),
            });
            break;
        }
        case 11: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: (appPageService_1) => createElement(IndexView_17, {
                    appPageService: appPageService_1,
                }),
                args: appPageService,
            });
            break;
        }
        case 12: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_18, null),
            });
            break;
        }
        case 13: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_19, null),
            });
            break;
        }
        case 14: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_20, null),
            });
            break;
        }
        case 9: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasAnySubscription,
                element: () => createElement(IndexView_21, null),
            });
            break;
        }
        case 15: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasLogMasterSubscription,
                element: () => createElement(IndexView_22, null),
            });
            break;
        }
        case 16: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_23, null),
            });
            break;
        }
        case 17: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_24, null),
            });
            break;
        }
        case 31: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_25, null),
            });
            break;
        }
        case 32: {
            const x_32 = matchValue.fields[0];
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: (props_13) => createElement(IndexView_26, props_13),
                args: {
                    Code: x_32.Code,
                    Scope: x_32.Scope,
                    SessionState: x_32.SessionState,
                    State: x_32.State,
                },
            });
            break;
        }
        case 33: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_27, null),
            });
            break;
        }
        case 34: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_28, null),
            });
            break;
        }
        case 35: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_29, null),
            });
            break;
        }
        case 36: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_30, null),
            });
            break;
        }
        case 37: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasWorkMateSubscription,
                element: () => createElement(IndexView_31, null),
            });
            break;
        }
        case 18: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn && state_1.UserDetails.HasLogMasterSubscription,
                element: () => createElement(IndexView_32, null),
            });
            break;
        }
        case 28: {
            pageView = createElement(IndexView_33, null);
            break;
        }
        case 29: {
            pageView = createElement(IndexView_34, null);
            break;
        }
        case 30: {
            pageView = createElement(IndexViewWithSubscription, {
                hasSubscription: isSignedIn,
                element: (inviteId) => createElement(indexView, {
                    inviteId: inviteId,
                }),
                args: matchValue.fields[0],
            });
            break;
        }
        default:
            pageView = createElement(IndexView_35, null);
    }
    const drawer = createElement("div", createObj(Helpers_combineClasses("drawer", ofArray([["className", "lg:drawer-open sm:drawer-close"], (elems_14 = [createElement("input", createObj(Helpers_combineClasses("drawer-toggle", ofArray([["type", "checkbox"], ["id", "main-menu"], ["checked", state_1.IsDrawerOpen], ["onChange", (ev) => {
        const _arg_5 = ev.target.checked;
    }]])))), createElement("div", createObj(Helpers_combineClasses("drawer-content", singleton_2((elems_10 = toList(delay(() => {
        let elems_6, children_6, elems_2, children_8, elm;
        return append(singleton_1(createElement("div", createObj(Helpers_combineClasses("navbar", ofArray([["className", "lg:hidden"], (elems_6 = [(children_6 = singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-square"], ["className", "btn-ghost"], (elems_2 = [createElement("i", {
            className: "fas fa-bars",
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))]), ["onClick", (_arg_6) => {
            dispatch(new Msg(1, []));
        }]]))))), createElement("div", {
            className: "navbar-start",
            children: reactApi.Children.toArray(Array.from(children_6)),
        })), (children_8 = singleton_2((elm = singleton_2(createElement("span", {
            className: "text-primary",
            children: "10thDoor",
        })), createElement("div", {
            className: "text-xl font-bold",
            children: reactApi.Children.toArray(Array.from(elm)),
        }))), createElement("div", {
            className: "navbar-center",
            children: reactApi.Children.toArray(Array.from(children_8)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))))), delay(() => {
            let children_18, elems_9, children_15, children_13;
            return append(state_1.IsOrgSelectorShowing ? singleton_1((children_18 = ofArray([createElement("input", createObj(Helpers_combineClasses("modal-toggle", ofArray([["type", "checkbox"], ["id", "app-org-selector"], ["checked", true], ["readOnly", true]])))), createElement("div", createObj(Helpers_combineClasses("modal", singleton_2((elems_9 = [(children_15 = ofArray([createElement(IndexView_36, {
                IsValidOrganisationSelected: (arg_1) => {
                    dispatch(new Msg(5, [arg_1]));
                },
                ShowNoneOption: false,
            }), (children_13 = ofArray([createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", "app-org-selector"], ["className", "btn-primary"], ["children", "Cancel"], ["onClick", (_arg_7) => {
                dispatch(new Msg(7, []));
            }]])))), createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", "app-org-selector"], ["className", "btn-primary"], ["children", "Select"], ["onClick", (_arg_8) => {
                dispatch(new Msg(6, []));
            }], ["disabled", !state_1.IsValidOrganisation]]))))]), createElement("div", {
                className: "modal-action",
                children: reactApi.Children.toArray(Array.from(children_13)),
            }))]), createElement("div", {
                className: "modal-box",
                children: reactApi.Children.toArray(Array.from(children_15)),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_9))])))))]), createElement("div", {
                children: reactApi.Children.toArray(Array.from(children_18)),
            }))) : empty_1(), delay(() => singleton_1(pageView)));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_10))]))))), createElement("div", createObj(Helpers_combineClasses("drawer-side", singleton_2((elems_13 = [createElement("label", createObj(Helpers_combineClasses("drawer-overlay", singleton_2(["htmlFor", "main-menu"])))), createElement("aside", createObj(ofArray([["className", "flex flex-col border-r w-60 bg-base-100 text-base-content"], (elems_12 = [(elm_2 = toList(delay(() => append(singleton_1(createElement("span", {
        className: "text-primary",
        children: "10thDoor",
    })), delay(() => (!isNullOrEmpty(state_1.UserDetails.SelectedOrgName) ? singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", state_1.UserDetails.SelectedOrgName], ["onClick", (_arg_9) => {
        dispatch(new Msg(4, [true]));
    }]]))))) : empty_1()))))), createElement("div", {
        className: "sticky top-0 bg-base-100 z-20 inline-block text-3xl font-title px-4 py-2 font-bold",
        children: reactApi.Children.toArray(Array.from(elm_2)),
    })), createElement("ul", createObj(Helpers_combineClasses("menu", ofArray([["className", "menu-sm"], ["className", "p-4 h-full overflow-y-auto bg-base-100 text-base-content"], (elems_1 = toList(delay(() => append((isSignedIn && !state_1.UserDetails.HasAnySubscription) ? append(singleton_1(Daisy_error(singleton_2("No Active Subscription found."))), delay(() => singleton_1(createElement("li", {})))) : empty_1(), delay(() => append(singleton_1(mi(new ModulePermission(0, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "Home", ofArray(["fas", "fa-home"]), new Page(0, []))), delay(() => append(singleton_1(createElement("li", {})), delay(() => append(singleton_1(miTitle(new ModulePermission(0, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "Products")), delay(() => append(singleton_1(mi(new ModulePermission(0, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "WorkMate", ofArray(["fas", "fa-icons"]), new Page(5, []))), delay(() => append(singleton_1(createElement("li", {})), delay(() => append(isSignedIn ? append(singleton_1(miTitle(new ModulePermission(5, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Organisation")), delay(() => append(singleton_1(mi(new ModulePermission(5, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Settings", ofArray(["fas", "fa-icons"]), new Page(24, []))), delay(() => append(state_1.UserDetails.HasAnySubscription ? append(singleton_1(mi(new ModulePermission(1, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Users", ofArray(["fas", "fa-icons"]), new Page(7, []))), delay(() => append(singleton_1(mi(new ModulePermission(1, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "User Memberships", ofArray(["fas", "fa-icons"]), new Page(9, []))), delay(() => append(singleton_1(mi(new ModulePermission(1, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Skill", ofArray(["fas", "fa-icons"]), new Page(10, []))), delay(() => singleton_1(mi(new ModulePermission(3, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Customers", ofArray(["fas", "fa-icons"]), new Page(25, []))))))))) : empty_1(), delay(() => append(state_1.UserDetails.HasWorkMateSubscription ? append(singleton_1(miTitle(new ModulePermission(0, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "WorkMate")), delay(() => append(singleton_1(mi(new ModulePermission(11, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "Dashboard", ofArray(["fas", "fa-icons"]), new Page(16, []))), delay(() => append(singleton_1(mi(new ModulePermission(6, [ofSeq([new PermissionType(0, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Job", ofArray(["fas", "fa-icons"]), new Page(11, []))), delay(() => append(singleton_1(mi(new ModulePermission(7, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Shift", ofArray(["fas", "fa-icons"]), new Page(13, []))), delay(() => append(singleton_1(mi(new ModulePermission(8, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Timesheet", ofArray(["fas", "fa-icons"]), new Page(12, []))), delay(() => append(singleton_1(mi(new ModulePermission(9, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Payroll", ofArray(["fas", "fa-icons"]), new Page(14, []))), delay(() => append(singleton_1(mi(new ModulePermission(11, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "Reports", ofArray(["fas", "fa-icons"]), new Page(17, []))), delay(() => append(singleton_1(mi(new ModulePermission(2, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Leaves", ofArray(["fas", "fa-icons"]), new Page(8, []))), delay(() => append(singleton_1(mi(new ModulePermission(2, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Holidays", ofArray(["fas", "fa-icons"]), new Page(35, []))), delay(() => append(state_1.UserDetails.IsUsingXero ? singleton_1(mi(new ModulePermission(10, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Invoice Runs", ofArray(["fas", "fa-icons"]), new Page(34, []))) : empty_1(), delay(() => append(singleton_1(mi(new ModulePermission(12, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Expenses", ofArray(["fas", "fa-icons"]), new Page(36, []))), delay(() => (state_1.UserDetails.IsUsingSimpro ? singleton_1(mi(new ModulePermission(6, [ofSeq([new PermissionType(0, []), new PermissionType(1, []), new PermissionType(2, [])], {
        Compare: compare,
    })]), "Simpro Connection Details", ofArray(["fas", "fa-icons"]), new Page(37, []))) : empty_1()))))))))))))))))))))))) : empty_1(), delay(() => append(state_1.UserDetails.HasLogMasterSubscription ? append(singleton_1(miTitle(new ModulePermission(0, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "LogMaster")), delay(() => append(singleton_1(mi(new ModulePermission(4, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "Dashboard", ofArray(["fas", "fa-icons"]), new Page(15, []))), delay(() => append(singleton_1(mi(new ModulePermission(4, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "Log Report", ofArray(["fas", "fa-icons"]), new Page(18, []))), delay(() => append(singleton_1(mi(new ModulePermission(4, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "Vehicles", ofArray(["fas", "fa-icons"]), new Page(6, []))), delay(() => singleton_1(createElement("li", {})))))))))) : empty_1(), delay(() => {
        let children_2;
        return singleton_1((children_2 = singleton_2(createElement("a", {
            className: "p-2 rounded-lg",
            children: "Sign Out",
            onClick: (e) => {
                e.preventDefault();
                dispatch(new Msg(2, []));
            },
        })), createElement("li", {
            children: reactApi.Children.toArray(Array.from(children_2)),
        })));
    })))))))))) : append(singleton_1(mi(new ModulePermission(0, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "Sign up", ofArray(["fas", "fa-user-plus"]), new Page(19, []))), delay(() => singleton_1(mi(new ModulePermission(0, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "Sign in", ofArray(["fas", "fa-key"]), new Page(20, []))))), delay(() => append(singleton_1(createElement("li", {})), delay(() => append(singleton_1(mi(new ModulePermission(0, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "Privacy Policy", ofArray(["fas", "fa-user-secret"]), new Page(28, []))), delay(() => singleton_1(mi(new ModulePermission(0, [ofSeq([new PermissionType(0, [])], {
        Compare: compare,
    })]), "Terms of Use", ofArray(["fas", "fa-user-secret"]), new Page(29, []))))))))))))))))))))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", reactApi.Children.toArray(Array.from(elems_13))])))))], ["children", reactApi.Children.toArray(Array.from(elems_14))])]))));
    const navigation = createElement("div", {
        children: reactApi.Children.toArray([drawer]),
    });
    unauthorizedAction_1(() => {
        const matchValue_1 = signOut(appRoot.SystemService);
        if (matchValue_1.tag === 1) {
            const arg_2 = singleton_2(seqToString(matchValue_1.fields[0]));
            toConsole(printf("-----> %A"))(arg_2);
        }
        else {
            toConsole(printf("-----> Signing out"));
            Router_navigatePage(new Page(20, []));
        }
    });
    return RouterModule_router({
        hashMode: 2,
        onUrlChanged: (arg_4) => {
            dispatch(new Msg(0, [PageModule_parseFromUrlSegments(arg_4)]));
        },
        application: react.createElement(react.Fragment, {}, navigation),
    });
}

