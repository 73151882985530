import { saveByteArray } from "../public/js/saveByteArray.js";
import { endOfWeek, subWeeks, startOfWeek, format as format_1 } from "date-fns";
import { toShortTimeString, toLocalTime } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { minutes, hours, days } from "../fable_modules/fable-library-js.4.19.3/TimeSpan.js";

export const HttpUtil_saveByteArray = saveByteArray;

export function ByteArray_ofArrayBuffer(buffer) {
    return new Uint8Array(buffer);
}

export function ByteArray_toArrayBuffer(bytes) {
    const unit8Array = new Uint8Array(bytes);
    return unit8Array.buffer;
}

export function DateTime_toString(date) {
    return format_1(date, "d MMM yyyy");
}

export function DateTime_toLocalTime(date) {
    return toLocalTime(date);
}

export function DateTime_getDayName(date) {
    return format_1(date, "EEE");
}

export function DateTime_dateToString(date) {
    return format_1(date, "d MMM yyyy");
}

export function DateTime_dateToStringWithoutYear(date) {
    return format_1(date, "d MMM");
}

export function DateTime_dateToStringWithDayName(date) {
    return (DateTime_dateToString(date) + " - ") + DateTime_getDayName(date);
}

export function DateTime_dateToStringWithDayNameNoYear(date) {
    return (DateTime_dateToStringWithoutYear(date) + " - ") + DateTime_getDayName(date);
}

export function DateTime_timeToString(date) {
    return toShortTimeString(date);
}

export function DateTime_dateTimeToString(date) {
    return (DateTime_dateToString(date) + ", ") + DateTime_timeToString(date);
}

export function DateTime_dateTimeToStringWithDayName(date) {
    return (DateTime_getDayName(date) + ", ") + DateTime_dateTimeToString(date);
}

export function DateTime_getLastWeekStartEnd(date) {
    const start = startOfWeek(subWeeks(date, 1));
    return {
        End: endOfWeek(start),
        Start: start,
    };
}

export function TimeSpan_toString(value) {
    return `${days(value)}.${hours(value)}:${minutes(value)}`;
}

export function DayOfWeek_toString(dayOfWeek) {
    switch (dayOfWeek) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        default:
            return "Unknown";
    }
}

