import { toString } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { AppRootModule_getSelectedOrgId, appRoot, AppRoot__get_SignedInUserRole } from "../../../Infrastructure/AppRoot.fs.js";
import { UserRole } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { searchJobs } from "../../../AppDomain/UseCase/Job.fs.js";
import { IndexView as IndexView_3, Msg$2, update as update_2, init as init_2 } from "../BaseSearchAdvanced.fs.js";
import { StatusModule_allStatuses, StatusModule_ofString, Status as Status_1 } from "../../../../Logos.Shared/Domain/Entity/Common.fs.js";
import { JobSearchDetails } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { int64ToString, createObj, uncurry2 } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import { IndexView as IndexView_1 } from "./JobEdit.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { Daisy_h4, alignIconCenter, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_2 } from "./UserJobOverrideEdit.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { singleton, ofArray } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { map, singleton as singleton_1, collect, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { AddressDtoModule_toAddressString } from "../../../../Logos.Shared/Domain/Dto/Address.fs.js";
import { DateTime_dateToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { toLocalTime } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { PropHelpers_createOnKey } from "../../../fable_modules/Feliz.2.8.0/./Properties.fs.js";
import { key_enter } from "../../../fable_modules/Feliz.2.8.0/Key.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";

export const signedInUserRole = toString(AppRoot__get_SignedInUserRole(appRoot));

export const isSiteManager = signedInUserRole === toString(new UserRole(4, []));

export function searchFunc(jobSearchDetails) {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (pagination) => searchJobs(appRoot.JobService, selectedOrgId, jobSearchDetails, pagination);
}

export function init() {
    return init_2(new JobSearchDetails("", new Status_1(1, [])), uncurry2(searchFunc));
}

export function update(msg, state) {
    return update_2(uncurry2(searchFunc), msg, state);
}

export function jobTable(appPageService, props) {
    let elems, elems_3, children_3, children_1, children_7;
    const body = () => {
        const matchValue = props.state.EntityToEdit;
        if (matchValue == null) {
            return createElement(IndexView_1, {
                AppPageService: appPageService,
                jobId: "",
            });
        }
        else {
            return createElement(IndexView_1, {
                AppPageService: appPageService,
                jobId: matchValue,
            });
        }
    };
    const children_10 = ofArray([createElement("div", createObj(Helpers_combineClasses("navbar", ofArray([["className", "space-x-2"], (elems = [createElement(showModalEx, {
        dialogId: "id-job-search-add-job",
        header: "",
        buttonType: new ButtonType(0, ["Add Job"]),
        width: new Daisy_DialogWidth(5, []),
        heightPercent: "90",
        body: body,
        onShow: () => {
            props.dispatch(new Msg$2(4, [undefined]));
        },
        onClose: () => {
            props.dispatch(new Msg$2(4, [undefined]));
        },
    }), createElement(showModalEx, {
        dialogId: "id-user-job-override",
        header: "",
        buttonType: new ButtonType(0, ["User Job Overrides"]),
        width: new Daisy_DialogWidth(3, []),
        heightPercent: "80",
        body: () => createElement(IndexView_2, null),
        onShow: () => {
            props.dispatch(new Msg$2(4, [undefined]));
        },
        onClose: () => {
            props.dispatch(new Msg$2(4, [undefined]));
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_3 = [(children_3 = singleton((children_1 = ofArray([createElement("th", {
        width: 15 + "%",
        children: "Customer",
    }), createElement("th", {
        width: 15 + "%",
        children: "Job",
    }), createElement("th", {
        width: 20 + "%",
        children: "Address",
    }), createElement("th", {
        width: 18 + "%",
        children: "Start",
    }), createElement("th", {
        width: 18 + "%",
        children: "End",
    }), createElement("th", {
        width: 3 + "%",
        children: "Required Workers",
    }), createElement("th", {
        width: 3 + "%",
        children: "Allocated Workers",
    }), createElement("th", {
        width: 8 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), (children_7 = toList(delay(() => collect((jobSearchResult) => {
        let elems_2, children_5, elems_1;
        const jobColor = jobSearchResult.Job.IsActive ? "text-black" : "text-red-500";
        const tdColored_1 = (text) => createElement("td", {
            className: jobColor,
            children: text,
        });
        const job = jobSearchResult.Job;
        return singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_2 = [tdColored_1(jobSearchResult.BusinessName), tdColored_1(job.Title), tdColored_1(AddressDtoModule_toAddressString(job.Address)), tdColored_1(DateTime_dateToStringWithDayName(toLocalTime(job.StartDate))), tdColored_1(DateTime_dateToStringWithDayName(toLocalTime(job.EndDate))), createElement("td", {
            className: join(" ", ["text-right", jobColor]),
            children: int64ToString(jobSearchResult.RequiredUserAllocationCount),
        }), createElement("td", {
            className: join(" ", ["text-right", jobColor]),
            children: int64ToString(jobSearchResult.AllocatedUserCount),
        }), (children_5 = singleton(createElement("div", createObj(ofArray([alignIconCenter, (elems_1 = [createElement(showModalEx, {
            dialogId: job.Id,
            header: "",
            buttonType: new ButtonType(1, ["fa-edit"]),
            width: new Daisy_DialogWidth(5, []),
            heightPercent: "90",
            body: body,
            onShow: () => {
                props.dispatch(new Msg$2(4, [job.Id]));
            },
            onClose: () => {
                props.dispatch(new Msg$2(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_5)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
    }, props.state.Entities))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_7)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function searchPanel(props) {
    let children_2, elems, elems_6, children_8, children_5, elems_4, elems_3;
    const children_10 = ofArray([(children_2 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Search"])))), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "w-full"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["placeholder", "Search"], ["readOnly", false], ["onChange", (ev) => {
        props.dispatch(new Msg$2(0, [new JobSearchDetails(ev.target.value, props.state.SearchDetails.Status)]));
    }], ["onKeyUp", (ev_1) => {
        PropHelpers_createOnKey(key_enter, (_arg) => {
            props.dispatch(new Msg$2(1, []));
        }, ev_1);
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Search"], ["onClick", (_arg_1) => {
        props.dispatch(new Msg$2(1, []));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("div", createObj(ofArray([["className", "flex space-x-2 mt-2"], (elems_6 = [(children_8 = ofArray([(children_5 = singleton(createElement("span", {
        className: "label-text",
        children: "Status",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems_4 = [createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Status"], ["value", toString(props.state.SearchDetails.Status)], ["onChange", (ev_2) => {
        props.dispatch(new Msg$2(0, [new JobSearchDetails(props.state.SearchDetails.SearchText, StatusModule_ofString(ev_2.target.value))]));
    }], (elems_3 = toList(delay(() => map((case$) => createElement("option", {
        value: case$,
        children: case$,
    }), StatusModule_allStatuses))), ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_8)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function IndexView(indexViewInputProps) {
    let children, children_3, elems;
    const appPageService = indexViewInputProps.appPageService;
    if (isSiteManager) {
        const dispatch = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
        }), undefined, [])[1];
        return container([(children = singleton(Daisy_h4("Jobs")), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        })), (children_3 = singleton(createElement("div", createObj(Helpers_combineClasses("navbar", ofArray([["className", "space-x-2"], (elems = [createElement(showModalEx, {
            dialogId: "id-user-job-override",
            header: "",
            buttonType: new ButtonType(0, ["User Job Overrides"]),
            width: new Daisy_DialogWidth(3, []),
            heightPercent: "80",
            body: () => createElement(IndexView_2, null),
            onShow: () => {
                dispatch(new Msg$2(4, [undefined]));
            },
            onClose: () => {
                dispatch(new Msg$2(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])]))))), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children_3)),
        }))]);
    }
    else {
        return createElement(IndexView_3, {
            entityName: "Jobs",
            entityTable: (props_4) => jobTable(appPageService, props_4),
            searchDetails: new JobSearchDetails("", new Status_1(1, [])),
            searchFunc: uncurry2(searchFunc),
            searchPanel: searchPanel,
        });
    }
}

