import { Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, int32_type, record_type, option_type, string_type, decimal_type, class_type, bool_type, getUnionCases, name, union_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { sortBy } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Array.js";
import { comparePrimitives } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";
import { Address_$reflection } from "./Address.fs.js";
import { MeasurementSystem_$reflection } from "./MeasurementUnits.fs.js";
import { ShiftRule_$reflection } from "./ShiftRule.fs.js";
import { UserRole_$reflection } from "./User.fs.js";

export class DurationRoundMethod extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["None", "Up", "Down"];
    }
}

export function DurationRoundMethod_$reflection() {
    return union_type("Domain.Entity.Organisation.DurationRoundMethod", [], DurationRoundMethod, () => [[], [], []]);
}

export const DurationRoundMethodModule_roundMethods = sortBy(name, getUnionCases(DurationRoundMethod_$reflection(), 0), {
    Compare: comparePrimitives,
});

export function DurationRoundMethodModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 1;
        case 2:
            return 2;
        default:
            return 0;
    }
}

export function DurationRoundMethodModule_ofInt(_arg) {
    switch (_arg) {
        case 0:
            return new DurationRoundMethod(0, []);
        case 1:
            return new DurationRoundMethod(1, []);
        case 2:
            return new DurationRoundMethod(2, []);
        default:
            throw new Error(`Invalid DurationRound ${_arg}.`);
    }
}

export function DurationRoundMethodModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "Up";
        case 2:
            return "Down";
        default:
            return "None";
    }
}

export function DurationRoundMethodModule_ofString(x) {
    switch (x) {
        case "None":
            return new DurationRoundMethod(0, []);
        case "Up":
            return new DurationRoundMethod(1, []);
        case "Down":
            return new DurationRoundMethod(2, []);
        default:
            throw new Error(`Invalid DurationRound ${x}.`);
    }
}

export class OrganisationOptions extends Record {
    constructor(SetDefaultBreakDuration, DefaultBreakDuration, ApplyDefaultBreakDurationAfterWorkDurationOf, WorkDurationRoundMethod, WorkDurationRounding, IsUsingOvertimeAfterWorkHours, OvertimeAfterWorkHours, OvertimeRateMultiplier, SmartlyPayRateCode, SmartlyOvertimePayRateCode, SmartlyAllowanceCode, IgnoreUserSkillRatingForJob, Region, IsUsingXero, XeroInvoiceItemAccountCode, XeroInvoiceItemTaxType, IsUsingSimpro, IsShiftStartAndFinishOutsideGeofenceDisallowed, IsShiftStartAndFinishOutsideScheduleDisallowed, IsUsingOverheadRate, OverheadRate, DefaultShiftPreCheckTemplateId, DefaultShiftPostCheckTemplateId) {
        super();
        this.SetDefaultBreakDuration = SetDefaultBreakDuration;
        this.DefaultBreakDuration = DefaultBreakDuration;
        this.ApplyDefaultBreakDurationAfterWorkDurationOf = ApplyDefaultBreakDurationAfterWorkDurationOf;
        this.WorkDurationRoundMethod = WorkDurationRoundMethod;
        this.WorkDurationRounding = WorkDurationRounding;
        this.IsUsingOvertimeAfterWorkHours = IsUsingOvertimeAfterWorkHours;
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.OvertimeRateMultiplier = OvertimeRateMultiplier;
        this.SmartlyPayRateCode = SmartlyPayRateCode;
        this.SmartlyOvertimePayRateCode = SmartlyOvertimePayRateCode;
        this.SmartlyAllowanceCode = SmartlyAllowanceCode;
        this.IgnoreUserSkillRatingForJob = IgnoreUserSkillRatingForJob;
        this.Region = Region;
        this.IsUsingXero = IsUsingXero;
        this.XeroInvoiceItemAccountCode = XeroInvoiceItemAccountCode;
        this.XeroInvoiceItemTaxType = XeroInvoiceItemTaxType;
        this.IsUsingSimpro = IsUsingSimpro;
        this.IsShiftStartAndFinishOutsideGeofenceDisallowed = IsShiftStartAndFinishOutsideGeofenceDisallowed;
        this.IsShiftStartAndFinishOutsideScheduleDisallowed = IsShiftStartAndFinishOutsideScheduleDisallowed;
        this.IsUsingOverheadRate = IsUsingOverheadRate;
        this.OverheadRate = OverheadRate;
        this.DefaultShiftPreCheckTemplateId = DefaultShiftPreCheckTemplateId;
        this.DefaultShiftPostCheckTemplateId = DefaultShiftPostCheckTemplateId;
    }
}

export function OrganisationOptions_$reflection() {
    return record_type("Domain.Entity.Organisation.OrganisationOptions", [], OrganisationOptions, () => [["SetDefaultBreakDuration", bool_type], ["DefaultBreakDuration", class_type("System.TimeSpan")], ["ApplyDefaultBreakDurationAfterWorkDurationOf", class_type("System.TimeSpan")], ["WorkDurationRoundMethod", DurationRoundMethod_$reflection()], ["WorkDurationRounding", class_type("System.TimeSpan")], ["IsUsingOvertimeAfterWorkHours", bool_type], ["OvertimeAfterWorkHours", decimal_type], ["OvertimeRateMultiplier", decimal_type], ["SmartlyPayRateCode", string_type], ["SmartlyOvertimePayRateCode", string_type], ["SmartlyAllowanceCode", string_type], ["IgnoreUserSkillRatingForJob", bool_type], ["Region", string_type], ["IsUsingXero", bool_type], ["XeroInvoiceItemAccountCode", string_type], ["XeroInvoiceItemTaxType", string_type], ["IsUsingSimpro", bool_type], ["IsShiftStartAndFinishOutsideGeofenceDisallowed", bool_type], ["IsShiftStartAndFinishOutsideScheduleDisallowed", bool_type], ["IsUsingOverheadRate", bool_type], ["OverheadRate", decimal_type], ["DefaultShiftPreCheckTemplateId", option_type(class_type("System.Guid"))], ["DefaultShiftPostCheckTemplateId", option_type(class_type("System.Guid"))]]);
}

export class Organisation extends Record {
    constructor(Id, Name, Address, MeasurementSystem, PayrollSystemIndex, MinWorkdayDuration, UseBreaks, ShiftRules, OrganisationOptions) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Address = Address;
        this.MeasurementSystem = MeasurementSystem;
        this.PayrollSystemIndex = (PayrollSystemIndex | 0);
        this.MinWorkdayDuration = MinWorkdayDuration;
        this.UseBreaks = UseBreaks;
        this.ShiftRules = ShiftRules;
        this.OrganisationOptions = OrganisationOptions;
    }
}

export function Organisation_$reflection() {
    return record_type("Domain.Entity.Organisation.Organisation", [], Organisation, () => [["Id", option_type(class_type("System.Guid"))], ["Name", string_type], ["Address", Address_$reflection()], ["MeasurementSystem", MeasurementSystem_$reflection()], ["PayrollSystemIndex", int32_type], ["MinWorkdayDuration", decimal_type], ["UseBreaks", bool_type], ["ShiftRules", list_type(ShiftRule_$reflection())], ["OrganisationOptions", OrganisationOptions_$reflection()]]);
}

export class OrganisationDetail extends Record {
    constructor(Id, Name, UserRole, ShiftRules, UseBreaks, IsUsingXero, IsUsingSimpro, IsShiftStartAndFinishOutsideGeofenceDisallowed, IsShiftStartAndFinishOutsideScheduleDisallowed) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.UserRole = UserRole;
        this.ShiftRules = ShiftRules;
        this.UseBreaks = UseBreaks;
        this.IsUsingXero = IsUsingXero;
        this.IsUsingSimpro = IsUsingSimpro;
        this.IsShiftStartAndFinishOutsideGeofenceDisallowed = IsShiftStartAndFinishOutsideGeofenceDisallowed;
        this.IsShiftStartAndFinishOutsideScheduleDisallowed = IsShiftStartAndFinishOutsideScheduleDisallowed;
    }
}

export function OrganisationDetail_$reflection() {
    return record_type("Domain.Entity.Organisation.OrganisationDetail", [], OrganisationDetail, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["UserRole", UserRole_$reflection()], ["ShiftRules", list_type(ShiftRule_$reflection())], ["UseBreaks", bool_type], ["IsUsingXero", bool_type], ["IsUsingSimpro", bool_type], ["IsShiftStartAndFinishOutsideGeofenceDisallowed", bool_type], ["IsShiftStartAndFinishOutsideScheduleDisallowed", bool_type]]);
}

export class OrganisationUpsertResult extends Record {
    constructor(NewShiftRules) {
        super();
        this.NewShiftRules = NewShiftRules;
    }
}

export function OrganisationUpsertResult_$reflection() {
    return record_type("Domain.Entity.Organisation.OrganisationUpsertResult", [], OrganisationUpsertResult, () => [["NewShiftRules", list_type(ShiftRule_$reflection())]]);
}

