import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { SubscriptionUserAllocation, SubscriptionUserAllocation_$reflection, Product_$reflection } from "../../../../Logos.Shared/Domain/Entity/Product.fs.js";
import { unit_type, anonRecord_type, union_type, record_type, tuple_type, bool_type, class_type, option_type, list_type, string_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Pagination_$reflection } from "../../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { UserId as UserId_1, UserId_$reflection } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { SubscriptionUserAllocationDtoModule_toSubscriptionUserAllocation, SubscriptionUserAllocationsDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/Product.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { removeUserSubscription, allocateUserToSubscription, getSubscriptionUserAllocations } from "../../../AppDomain/UseCase/Stripe.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { exists, ofArray, tryFind, map, choose, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { createObj, equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { getDefaultPagination } from "../Pagination.fs.js";
import { utcNow as utcNow_1 } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { singleton as singleton_2, append, map as map_1, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_error, confirmSimple, Daisy_DialogWidth, Daisy_confirm, alignCellTextCenter } from "../Component.fs.js";
import { IndexViewPicker } from "../../Pages/User/UserSearch.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { showModalDialog } from "../ModalDialog.fs.js";
import { IndexView as IndexView_1 } from "../../Pages/User/UserEdit.fs.js";
import { TitleSize, viewBox } from "../ViewBox.fs.js";

export class State extends Record {
    constructor(Product, SearchText, AllocatedSubscriptions, UnallocatedSubscriptions, SubscriptionToAllocate, NextPagination, CurrentPagination, UserToShow, IsConfirmingSubscriptionRemoval, RemoveSubscriptionForUserId, IsBusy, Errors) {
        super();
        this.Product = Product;
        this.SearchText = SearchText;
        this.AllocatedSubscriptions = AllocatedSubscriptions;
        this.UnallocatedSubscriptions = UnallocatedSubscriptions;
        this.SubscriptionToAllocate = SubscriptionToAllocate;
        this.NextPagination = NextPagination;
        this.CurrentPagination = CurrentPagination;
        this.UserToShow = UserToShow;
        this.IsConfirmingSubscriptionRemoval = IsConfirmingSubscriptionRemoval;
        this.RemoveSubscriptionForUserId = RemoveSubscriptionForUserId;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Component.Stripe.SubscriptionUserAllocation.State", [], State, () => [["Product", Product_$reflection()], ["SearchText", string_type], ["AllocatedSubscriptions", list_type(SubscriptionUserAllocation_$reflection())], ["UnallocatedSubscriptions", list_type(SubscriptionUserAllocation_$reflection())], ["SubscriptionToAllocate", option_type(SubscriptionUserAllocation_$reflection())], ["NextPagination", Pagination_$reflection()], ["CurrentPagination", Pagination_$reflection()], ["UserToShow", option_type(class_type("System.Guid"))], ["IsConfirmingSubscriptionRemoval", bool_type], ["RemoveSubscriptionForUserId", option_type(tuple_type(class_type("System.Guid"), string_type, UserId_$reflection()))], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoAction", "GetAllocations", "GetAllocationsResponse", "SetUser", "AssignUserSubscription", "GetUserAllocationResponse", "ShowUser", "HideUser", "ConfirmAndRemoveSubscription", "CheckAndRemoveSubscription", "RemoveSubscriptionResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Component.Stripe.SubscriptionUserAllocation.Msg", [], Msg, () => [[], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [SubscriptionUserAllocationsDto_$reflection(), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", SubscriptionUserAllocationsDto_$reflection()]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", anonRecord_type(["SubscriptionId", class_type("System.Guid")], ["UserFullName", string_type], ["UserId", class_type("System.Guid")])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Guid")]], [], [["Item", tuple_type(class_type("System.Guid"), string_type, UserId_$reflection())]], [["Item", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getAllocationsCmd(state) {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(11, [Item_1])), () => singleton.Delay(() => getSubscriptionUserAllocations(appRoot.StripeService, AppRootModule_getSelectedOrgId(), state.Product, state.SearchText, state.NextPagination)));
}

export function allocateUserToSubscriptionCmd(state) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(11, [Item_1])), () => singleton.Delay(() => {
        let input_1;
        const subscriptionAllocatedToUsers = defaultArg((input_1 = state.SubscriptionToAllocate, (input_1 == null) ? undefined : singleton_1(input_1)), empty());
        return allocateUserToSubscription(appRoot.StripeService, AppRootModule_getSelectedOrgId(), subscriptionAllocatedToUsers);
    }));
}

export function removeUserSubscriptionCmd(subscriptionAndUserId) {
    return cmdOfAsync((Item) => (new Msg(10, [Item])), (Item_1) => (new Msg(11, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        let value_1;
        const option_1 = subscriptionAndUserId;
        value_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Subscription and user id must be provided.")])) : (new FSharpResult$2(0, [option_1])));
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                const _arg_1 = input_2.fields[0];
                return removeUserSubscription(appRoot.StripeService, AppRootModule_getSelectedOrgId(), _arg_1[0], _arg_1[1], _arg_1[2]);
            }
        });
    }));
}

function changePage(state, f, searchFunc) {
    const currentState = state;
    const newState = new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, state.SubscriptionToAllocate, f(state.CurrentPagination), state.CurrentPagination, state.UserToShow, state.IsConfirmingSubscriptionRemoval, state.RemoveSubscriptionForUserId, new Busy(1, []), state.Errors);
    if (equals(currentState, newState)) {
        return [currentState, Cmd_none()];
    }
    else {
        return [newState, getAllocationsCmd(newState)];
    }
}

export function init(product) {
    const pagination = getDefaultPagination();
    const state = new State(product, "", empty(), empty(), undefined, pagination, pagination, undefined, false, undefined, new Busy(1, []), empty());
    return [state, getAllocationsCmd(state)];
}

export function update(msg, state) {
    let input_1, y_1;
    switch (msg.tag) {
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, state.SubscriptionToAllocate, state.NextPagination, state.CurrentPagination, state.UserToShow, state.IsConfirmingSubscriptionRemoval, state.RemoveSubscriptionForUserId, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Product, state.SearchText, choose((_arg) => {
                    if (_arg.tag === 1) {
                        return undefined;
                    }
                    else {
                        return _arg.fields[0];
                    }
                }, map(SubscriptionUserAllocationDtoModule_toSubscriptionUserAllocation, msg.fields[0].fields[0].AllocatedSubscriptions)), choose((_arg_1) => {
                    if (_arg_1.tag === 1) {
                        return undefined;
                    }
                    else {
                        return _arg_1.fields[0];
                    }
                }, map(SubscriptionUserAllocationDtoModule_toSubscriptionUserAllocation, msg.fields[0].fields[0].UnallocatedSubscriptions)), state.SubscriptionToAllocate, state.NextPagination, state.CurrentPagination, state.UserToShow, state.IsConfirmingSubscriptionRemoval, state.RemoveSubscriptionForUserId, new Busy(0, []), empty()), Cmd_none()];
            }
        case 3:
            return [state, allocateUserToSubscriptionCmd(state)];
        case 4: {
            const utcNow = utcNow_1();
            return [new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, (input_1 = tryFind((y) => (y.Id === msg.fields[0].SubscriptionId), state.UnallocatedSubscriptions), (input_1 == null) ? undefined : ((y_1 = input_1, new SubscriptionUserAllocation(y_1.Id, y_1.SubscriptionId, msg.fields[0].UserId, msg.fields[0].UserFullName, y_1.Product, utcNow, y_1.DateDeallocated)))), state.NextPagination, state.CurrentPagination, state.UserToShow, state.IsConfirmingSubscriptionRemoval, state.RemoveSubscriptionForUserId, state.IsBusy, state.Errors), Cmd_none()];
        }
        case 0:
            return [state, Cmd_none()];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, state.SubscriptionToAllocate, state.NextPagination, state.CurrentPagination, state.UserToShow, state.IsConfirmingSubscriptionRemoval, state.RemoveSubscriptionForUserId, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, singleton_1((dispatch) => {
                    dispatch(new Msg(1, []));
                })];
            }
        case 6:
            return [new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, state.SubscriptionToAllocate, state.NextPagination, state.CurrentPagination, msg.fields[0], state.IsConfirmingSubscriptionRemoval, state.RemoveSubscriptionForUserId, state.IsBusy, state.Errors), Cmd_none()];
        case 7:
            return [new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, state.SubscriptionToAllocate, state.NextPagination, state.CurrentPagination, undefined, state.IsConfirmingSubscriptionRemoval, state.RemoveSubscriptionForUserId, state.IsBusy, state.Errors), Cmd_none()];
        case 8:
            return [new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, state.SubscriptionToAllocate, state.NextPagination, state.CurrentPagination, state.UserToShow, true, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 9:
            if (msg.fields[0]) {
                return [new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, state.SubscriptionToAllocate, state.NextPagination, state.CurrentPagination, state.UserToShow, false, state.RemoveSubscriptionForUserId, new Busy(1, []), state.Errors), removeUserSubscriptionCmd(state.RemoveSubscriptionForUserId)];
            }
            else {
                return [new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, state.SubscriptionToAllocate, state.NextPagination, state.CurrentPagination, state.UserToShow, false, state.RemoveSubscriptionForUserId, state.IsBusy, state.Errors), Cmd_none()];
            }
        case 10:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, state.SubscriptionToAllocate, state.NextPagination, state.CurrentPagination, state.UserToShow, state.IsConfirmingSubscriptionRemoval, state.RemoveSubscriptionForUserId, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, singleton_1((dispatch_1) => {
                    dispatch_1(new Msg(1, []));
                })];
            }
        case 11:
            return [new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, state.SubscriptionToAllocate, state.NextPagination, state.CurrentPagination, state.UserToShow, state.IsConfirmingSubscriptionRemoval, state.RemoveSubscriptionForUserId, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [new State(state.Product, state.SearchText, state.AllocatedSubscriptions, state.UnallocatedSubscriptions, state.SubscriptionToAllocate, state.NextPagination, state.CurrentPagination, state.UserToShow, state.IsConfirmingSubscriptionRemoval, state.RemoveSubscriptionForUserId, new Busy(1, []), state.Errors), getAllocationsCmd(state)];
    }
}

function viewUserButton(userId, dispatch) {
    let elems_1, elems;
    return createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", "View User"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["btn btn-square btn-xs text-primary"])], (elems = [createElement("i", {
        className: join(" ", ["fa", "fa-book"]),
    })], ["children", reactApi.Children.toArray(Array.from(elems))]), ["onClick", (_arg) => {
        dispatch(new Msg(6, [userId]));
    }]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
}

function userRemoveSubscriptionButton(subscriptionAllocationId, subscriptionId, userId, dispatch) {
    let elems_1, elems;
    return createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", "Remove subscription"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["btn btn-square btn-xs"])], (elems = [createElement("i", {
        className: join(" ", ["fa", "fa-trash"]),
    })], ["children", reactApi.Children.toArray(Array.from(elems))]), ["style", {
        color: "red",
    }], ["onClick", (_arg) => {
        dispatch(new Msg(8, [[subscriptionAllocationId, subscriptionId, userId]]));
    }]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
}

function AllocatedSubscriptions(allocatedSubscriptionsInputProps) {
    let elems_3, children_2, children, children_4;
    const dispatch = allocatedSubscriptionsInputProps.dispatch;
    const state = allocatedSubscriptionsInputProps.state;
    return createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", join(" ", ["flex", "w-full"])], (elems_3 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        width: 5 + "%",
        children: "Subscription id",
    }), createElement("th", {
        width: 5 + "%",
        children: "User",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = toList(delay(() => map_1((subscription) => {
        let elems_2, elems_1, elems;
        return createElement("tr", createObj(singleton_1((elems_2 = [createElement("td", createObj(ofArray([alignCellTextCenter, ["children", subscription.SubscriptionId]]))), createElement("td", createObj(ofArray([["className", "flex flex-row justify-between items-center"], (elems_1 = [createElement("div", createObj(ofArray([alignCellTextCenter, ["children", subscription.UserFullName]]))), createElement("div", createObj(ofArray([["className", "flex flex-row justify-between items-center gap-2"], (elems = [viewUserButton(subscription.UserId, dispatch), userRemoveSubscriptionButton(subscription.Id, subscription.SubscriptionId, new UserId_1(subscription.UserId), dispatch)], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))));
    }, state.AllocatedSubscriptions))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
}

export function picker(subscriptionId, onFilter, dispatch) {
    return createElement(IndexViewPicker, {
        OnDisableRow: onFilter,
        OnSetUser: (user) => {
            dispatch(new Msg(4, [{
                SubscriptionId: subscriptionId,
                UserFullName: toString(user),
                UserId: user.Id,
            }]));
        },
    });
}

function UnallocatedSubscriptions(unallocatedSubscriptionsInputProps) {
    let elems_1, children_2, children, children_8;
    const dispatch = unallocatedSubscriptionsInputProps.dispatch;
    const state = unallocatedSubscriptionsInputProps.state;
    return createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", join(" ", ["flex", "w-full"])], (elems_1 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        width: 90 + "%",
        children: "Subscription id",
    }), createElement("th", {
        width: 10 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_8 = toList(delay(() => map_1((subscription) => {
        let elems, children_6, children_4;
        return createElement("tr", createObj(singleton_1((elems = [createElement("td", {
            children: subscription.SubscriptionId,
        }), (children_6 = singleton_1((children_4 = singleton_1(createElement(Daisy_confirm, {
            buttonColor: ["className", "btn-primary"],
            width: new Daisy_DialogWidth(4, []),
            buttonTitle: "Select",
            body: picker(subscription.Id, (user) => exists((x) => (x.UserId === user.Id), state.AllocatedSubscriptions), dispatch),
            isEnabled: true,
            yesAction: new Msg(3, []),
            noAction: new Msg(0, []),
            dispatch: dispatch,
        })), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children_4)),
        }))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_6)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems))]))));
    }, state.UnallocatedSubscriptions))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
}

export function IndexView(props) {
    let elems;
    let patternInput;
    const init_1 = init(props.Product);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.SubscriptionCount]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", createObj(singleton_1((elems = toList(delay(() => {
        let matchValue, userId;
        return append((matchValue = state_1.UserToShow, (matchValue == null) ? singleton_2(react.createElement(react.Fragment, {})) : ((userId = matchValue, singleton_2(createElement(showModalDialog, {
            dialogId: userId,
            header: "",
            width: new Daisy_DialogWidth(4, []),
            heightPercent: "80",
            body: () => createElement(IndexView_1, {
                userId: userId,
            }),
            onShow: () => {
            },
            onClose: () => {
                dispatch(new Msg(7, []));
            },
        }))))), delay(() => append(state_1.IsConfirmingSubscriptionRemoval ? singleton_2(confirmSimple(new Daisy_DialogWidth(1, []), "Are you sure you want to remove this subscription?", (_arg_5) => {
            dispatch(new Msg(9, [false]));
        }, (_arg_6) => {
            dispatch(new Msg(9, [true]));
        })) : singleton_2(react.createElement(react.Fragment, {})), delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => append(singleton_2(viewBox("Subscriptions Allocated to Users", new TitleSize(0, []), singleton_1(createElement(AllocatedSubscriptions, {
            state: state_1,
            dispatch: dispatch,
        })))), delay(() => append(singleton_2(createElement("br", {})), delay(() => singleton_2(viewBox("Subscriptions not Allocated to Users", new TitleSize(0, []), singleton_1(createElement(UnallocatedSubscriptions, {
            state: state_1,
            dispatch: dispatch,
        }))))))))))))));
    })), ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

