import { printf, toText, join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { ofArray, isEmpty, singleton, map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { newGuid } from "../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { BusyModule_isLoading } from "../SharedView.fs.js";

export const alignCellTextTop = ["className", join(" ", ["align-text-top"])];

export const alignCellTextCenter = ["className", join(" ", ["align-text-center"])];

export const alignIconCenter = ["className", join(" ", ["flex", "flex-row", "justify-end", "vertical"])];

export function Daisy_toString(items) {
    const listItems = map((x) => createElement("li", {
        className: join(" ", ["text-sm"]),
        children: x,
    }), items);
    const children = singleton(createElement("ol", {
        children: reactApi.Children.toArray(Array.from(listItems)),
    }));
    return createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

export function Daisy_error(errors) {
    let elems_1, elems;
    if (!isEmpty(errors)) {
        return createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems_1 = [createElement("br", {}), createElement("div", createObj(Helpers_combineClasses("alert", ofArray([["className", "alert-error"], (elems = [Daisy_toString(errors)], ["children", reactApi.Children.toArray(Array.from(elems))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
    }
    else {
        return react.createElement(react.Fragment, {});
    }
}

export function Daisy_info(info) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["hidden", isEmpty(info)], (elems_1 = [createElement("br", {}), createElement("div", createObj(Helpers_combineClasses("alert", ofArray([["className", "alert-info"], (elems = [Daisy_toString(info)], ["children", reactApi.Children.toArray(Array.from(elems))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export const Daisy_h1Classes = ofArray(["mb-6", "text-5xl", "font-bold"]);

export function Daisy_h1(text) {
    return createElement("h1", {
        className: join(" ", Daisy_h1Classes),
        children: text,
    });
}

export const Daisy_h2Classes = ofArray(["mb-5", "text-4xl", "font-bold"]);

export function Daisy_h2(text) {
    return createElement("h2", {
        className: join(" ", Daisy_h2Classes),
        children: text,
    });
}

export const Daisy_h3Classes = ofArray(["mb-4", "text-3xl", "font-bold"]);

export function Daisy_h3(text) {
    return createElement("h3", {
        className: join(" ", Daisy_h3Classes),
        children: text,
    });
}

export const Daisy_h4Classes = ofArray(["mb-3", "text-2xl", "font-bold"]);

export function Daisy_h4(text) {
    return createElement("h4", {
        className: join(" ", Daisy_h4Classes),
        children: text,
    });
}

export const Daisy_h5Classes = ofArray(["mb-2", "text-xl", "font-bold"]);

export function Daisy_h5(text) {
    return createElement("h5", {
        className: join(" ", Daisy_h5Classes),
        children: text,
    });
}

export const Daisy_h6Classes = ofArray(["mb-1", "text-sm", "font-bold"]);

export function Daisy_h6(text) {
    return createElement("h6", {
        className: join(" ", Daisy_h6Classes),
        children: text,
    });
}

export const Daisy_h7Classes = ofArray(["mb-1", "text-xs", "font-bold"]);

export function Daisy_h7(text) {
    return createElement("h6", {
        className: join(" ", Daisy_h7Classes),
        children: text,
    });
}

export function Daisy_title6(text) {
    return createElement("div", {
        className: join(" ", ["box-border", "mb-1", "text-sm", "font-bold"]),
        children: text,
    });
}

export const Daisy_spinnerSmall = createElement("div", {
    className: "animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900",
});

export function Daisy_labelWithTooltip(labelText, tooltip, tooltipIcon) {
    let elems;
    const children_2 = singleton(createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", tooltip], (elems = [createElement("span", {
        className: "label-text",
        children: `${labelText} `,
    }), createElement("i", {
        className: join(" ", [tooltipIcon]),
        style: {
            fontSize: 14 + "px",
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))));
    return createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_2)),
    });
}

export class Daisy_DialogWidth extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["XS", "SM", "SMMD", "MD", "LG", "XLG", "Full"];
    }
}

export function Daisy_DialogWidth_$reflection() {
    return union_type("Presentation.Component.Daisy.DialogWidth", [], Daisy_DialogWidth, () => [[], [], [], [], [], [], []]);
}

export function Daisy_DialogWidth__toString(this$) {
    switch (this$.tag) {
        case 1:
            return "max-w-sm";
        case 2:
            return "max-w-3xl";
        case 3:
            return "max-w-5xl";
        case 4:
            return "max-w-6xl";
        case 5:
            return "max-w-7xl";
        case 6:
            return "max-w-full";
        default:
            return "max-w-xs";
    }
}

export function Daisy_DialogWidth__toStringEx(this$) {
    switch (this$.tag) {
        case 1:
            return "w-3/5";
        case 2:
            return "w-2/3";
        case 3:
            return "w-3/4";
        case 4:
            return "w-4/5";
        case 5:
            return "w-5/6";
        case 6:
            return "w-full";
        default:
            return "w-1/2";
    }
}

export function Daisy_confirm(daisy_confirmInputProps) {
    let elems_2, elems_1, children_3;
    const dispatch = daisy_confirmInputProps.dispatch;
    const noAction = daisy_confirmInputProps.noAction;
    const yesAction = daisy_confirmInputProps.yesAction;
    const isEnabled = daisy_confirmInputProps.isEnabled;
    const body = daisy_confirmInputProps.body;
    const buttonTitle = daisy_confirmInputProps.buttonTitle;
    const width = daisy_confirmInputProps.width;
    const buttonColor = daisy_confirmInputProps.buttonColor;
    let id;
    let arg;
    let copyOfStruct = newGuid();
    arg = copyOfStruct;
    id = toText(printf("logos-client-confirm-dialog-%s"))(arg);
    const xs_13 = [createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", id], buttonColor, ["className", "btn-sm"], ["children", buttonTitle], ["disabled", !isEnabled]])))), createElement("input", createObj(Helpers_combineClasses("modal-toggle", ofArray([["type", "checkbox"], ["id", id]])))), createElement("div", createObj(Helpers_combineClasses("modal", singleton((elems_2 = [createElement("div", createObj(ofArray([["className", `modal-box ${Daisy_DialogWidth__toString(width)}`], (elems_1 = [body, (children_3 = ofArray([createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", id], ["className", "btn-warning"], ["className", "btn-sm"], ["children", "No"], ["onClick", (_arg) => {
        dispatch(noAction);
    }]])))), createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", id], ["className", "btn-primary"], ["className", "btn-sm"], ["children", "Yes"], ["onClick", (_arg_1) => {
        dispatch(yesAction);
    }]]))))]), createElement("div", {
        className: "modal-action",
        children: reactApi.Children.toArray(Array.from(children_3)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])))))];
    return react.createElement(react.Fragment, {}, ...xs_13);
}

export function Daisy_progressState(daisy_progressStateInputProps) {
    const busy = daisy_progressStateInputProps.busy;
    if (BusyModule_isLoading(busy)) {
        return createElement("progress", createObj(Helpers_combineClasses("progress", ofArray([["className", "progress-primary"], ["className", "w-100"]]))));
    }
    else {
        return createElement("div", {
            className: join(" ", ["h-2"]),
        });
    }
}

export function confirmSimple(width, message, noAction, yesAction) {
    let elems_2, elems_1, children_2;
    let dialogId;
    let arg;
    let copyOfStruct = newGuid();
    arg = copyOfStruct;
    dialogId = toText(printf("logos-client-confirm-simple-%s"))(arg);
    const xs_11 = [createElement("input", createObj(Helpers_combineClasses("modal-toggle", ofArray([["type", "checkbox"], ["id", dialogId], ["checked", true], ["readOnly", true]])))), createElement("div", createObj(Helpers_combineClasses("modal", singleton((elems_2 = [createElement("div", createObj(ofArray([["className", `modal-box ${Daisy_DialogWidth__toString(width)}`], (elems_1 = [Daisy_h6(message), (children_2 = ofArray([createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", dialogId], ["className", "btn-warning"], ["className", "btn-sm"], ["children", "No"], ["onClick", noAction]])))), createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", dialogId], ["className", "btn-primary"], ["className", "btn-sm"], ["children", "Yes"], ["onClick", yesAction]]))))]), createElement("div", {
        className: "modal-action",
        children: reactApi.Children.toArray(Array.from(children_2)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])))))];
    return react.createElement(react.Fragment, {}, ...xs_11);
}

export function confirmSimpleWithBody(width, body, noAction, yesAction) {
    let elems_2, elems_1, children_2;
    let dialogId;
    let arg;
    let copyOfStruct = newGuid();
    arg = copyOfStruct;
    dialogId = toText(printf("logos-client-confirm-simple-%s"))(arg);
    const xs_11 = [createElement("input", createObj(Helpers_combineClasses("modal-toggle", ofArray([["type", "checkbox"], ["id", dialogId], ["checked", true], ["readOnly", true]])))), createElement("div", createObj(Helpers_combineClasses("modal", singleton((elems_2 = [createElement("div", createObj(ofArray([["className", `modal-box ${Daisy_DialogWidth__toString(width)}`], (elems_1 = [body, (children_2 = ofArray([createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", dialogId], ["className", "btn-warning"], ["className", "btn-sm"], ["children", "No"], ["onClick", noAction]])))), createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", dialogId], ["className", "btn-primary"], ["className", "btn-sm"], ["children", "Yes"], ["onClick", yesAction]]))))]), createElement("div", {
        className: "modal-action",
        children: reactApi.Children.toArray(Array.from(children_2)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])))))];
    return react.createElement(react.Fragment, {}, ...xs_11);
}

export function printButton(onClickHandler) {
    let elems_1, elems;
    return createElement("div", createObj(singleton((elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["btn btn-square btn-sm text-primary"])], (elems = [createElement("i", {
        className: "fas fa-print",
    })], ["children", reactApi.Children.toArray(Array.from(elems))]), ["onClick", onClickHandler]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))]))));
}

export function csvExportButton(onClickHandler) {
    let elems_1, elems;
    return createElement("div", createObj(singleton((elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["btn btn-square btn-sm text-primary"])], (elems = [createElement("i", {
        className: "fas fa-file-csv",
    })], ["children", reactApi.Children.toArray(Array.from(elems))]), ["onClick", onClickHandler]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))]))));
}

