import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { TimesheetRunStatusModule_allStatuses } from "../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { ShiftLogDtoModule_toShiftLog, TimesheetOfUserDtoModule_toTimesheet, ShiftDetailDtoModule_toShiftDetail, ShiftComprehensiveDtoModule_toShift, PaymentComprehensiveDtoModule_toPayment, PaymentRunComprehensiveDtoModule_toPaymentRun, TimesheetComprehensiveDtoModule_ofTimesheet } from "../../../Logos.Shared/Domain/Dto/Shift.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { JobPayrollDetailDtoModule_toJobPayrollDetail } from "../../../Logos.Shared/Domain/Dto/Job.fs.js";
import { DataFormatPayload$1 } from "../../../Logos.Shared/Util.fs.js";

export function searchTimesheets(shiftService, selectedOrgId, searchText, pagination) {
    return singleton.Delay(() => shiftService.SearchTimesheets(selectedOrgId, searchText, TimesheetRunStatusModule_allStatuses, pagination));
}

export function searchTimesheetsForPaymentRun(shiftService, selectedOrgId, payPeriod) {
    return singleton.Delay(() => shiftService.SearchTimesheetsForPaymentRun(selectedOrgId, payPeriod));
}

export function generateTimesheetRun(shiftService, selectedOrgId, dateRange, jobs) {
    return singleton.Delay(() => shiftService.GenerateTimesheetRun(selectedOrgId, dateRange, jobs));
}

export function getTimesheetRun(shiftService, selectedOrgId, timesheetRunId) {
    return singleton.Delay(() => shiftService.GetTimesheetRun(selectedOrgId, timesheetRunId));
}

export function getTimesheets(shiftService, selectedOrgId, timesheetRunId) {
    return singleton.Delay(() => shiftService.GetTimesheets(selectedOrgId, timesheetRunId));
}

export function approveTimesheets(shiftService, selectedOrgId, timesheets) {
    return singleton.Delay(() => {
        const timesheets_1 = map(TimesheetComprehensiveDtoModule_ofTimesheet, timesheets);
        return shiftService.ApproveTimesheets(selectedOrgId, timesheets_1);
    });
}

export function undoApprovalForTimesheets(shiftService, selectedOrgId, timesheetRunId, timesheets) {
    return singleton.Delay(() => {
        const timesheets_1 = map(TimesheetComprehensiveDtoModule_ofTimesheet, timesheets);
        return shiftService.UndoApprovalForTimesheets(selectedOrgId, timesheetRunId, timesheets_1);
    });
}

export function approveTimesheetRun(shiftService, selectedOrgId, timesheetRunId) {
    return singleton.Delay(() => shiftService.ApproveTimesheetRun(selectedOrgId, timesheetRunId));
}

export function cancelTimesheetRun(shiftService, selectedOrgId, timesheetRunId) {
    return singleton.Delay(() => shiftService.CancelTimesheetRun(selectedOrgId, timesheetRunId));
}

export function searchTimeSheetPayments(shiftService, selectedOrgId, searchText, pagination) {
    return singleton.Delay(() => shiftService.SearchTimesheetPayments(selectedOrgId, searchText, pagination));
}

export function generatePaymentRun(shiftService, selectedOrgId, payPeriod, timesheetRunIds) {
    return singleton.Delay(() => shiftService.GeneratePaymentRun(selectedOrgId, payPeriod, timesheetRunIds));
}

export function getPaymentRun(shiftService, selectedOrgId, paymentRunId) {
    return singleton.Delay(() => {
        const input_2 = shiftService.GetPaymentRun(selectedOrgId, paymentRunId);
        return singleton.Bind(input_2, (x$0027) => {
            let tupledArg, input_1;
            let value;
            const input_4 = x$0027;
            value = ((input_4.tag === 1) ? (new FSharpResult$2(1, [input_4.fields[0]])) : (new FSharpResult$2(0, [(tupledArg = input_4.fields[0], [(input_1 = tupledArg[0], (input_1 == null) ? undefined : PaymentRunComprehensiveDtoModule_toPaymentRun(input_1)), map(JobPayrollDetailDtoModule_toJobPayrollDetail, tupledArg[1])])])));
            return singleton.Return(value);
        });
    });
}

export function getPayments(shiftService, selectedOrgId, paymentRunId) {
    return singleton.Delay(() => {
        const input = shiftService.GetPayments(selectedOrgId, paymentRunId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(PaymentComprehensiveDtoModule_toPayment, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function exportSmartlyCsv(shiftService, selectedOrgId, paymentRunId) {
    return singleton.Delay(() => shiftService.ExportSmartlyCsv(selectedOrgId, paymentRunId));
}

export function markPaymentRunAsPaid(shiftService, selectedOrgId, paymentRunId) {
    return singleton.Delay(() => shiftService.MarkPaymentRunAsPaid(selectedOrgId, paymentRunId));
}

export function cancelPaymentRun(shiftService, selectedOrgId, paymentRunId) {
    return singleton.Delay(() => shiftService.CancelPaymentRun(selectedOrgId, paymentRunId));
}

export function searchShifts(shiftService, selectedOrgId, shiftSearchDetails, pagination) {
    return singleton.Delay(() => {
        const input = shiftService.SearchShifts(selectedOrgId, shiftSearchDetails, pagination);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(ShiftComprehensiveDtoModule_toShift, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getShift(shiftService, selectedOrgId, shiftId) {
    return singleton.Delay(() => {
        const input_2 = shiftService.GetShift(selectedOrgId, shiftId);
        return singleton.Bind(input_2, (x$0027) => {
            let input_1;
            let value;
            const input_4 = x$0027;
            value = ((input_4.tag === 1) ? (new FSharpResult$2(1, [input_4.fields[0]])) : (new FSharpResult$2(0, [(input_1 = input_4.fields[0], (input_1 == null) ? undefined : ShiftDetailDtoModule_toShiftDetail(input_1))])));
            return singleton.Return(value);
        });
    });
}

export function saveTimesheet(shiftService, selectedOrgId, timesheet) {
    return singleton.Delay(() => {
        const timesheet_1 = TimesheetComprehensiveDtoModule_ofTimesheet(timesheet);
        return shiftService.SaveTimesheet(selectedOrgId, timesheet_1);
    });
}

export function getTimesheetsForUser(shiftService, selectedOrgId, paymentRunId, userId) {
    return singleton.Delay(() => {
        const input = shiftService.GetTimesheetsForUser(selectedOrgId, paymentRunId, userId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(TimesheetOfUserDtoModule_toTimesheet, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getTimesheetsBetweenDates(shiftService, selectedOrgId, responseDataFormat, dateFrom, dateTo) {
    return singleton.Delay(() => shiftService.GetTimesheetsBetweenDates(selectedOrgId, responseDataFormat, dateFrom, dateTo));
}

export function getTimesheetReportByWorkers(shiftService, selectedOrgId, responseDataFormat, dateFrom, dateTo) {
    return singleton.Delay(() => shiftService.GetTimesheetReportByWorkers(selectedOrgId, responseDataFormat, dateFrom, dateTo));
}

export function getWorkerDurationByJob(shiftService, selectedOrgId, responseDataFormat, dateFrom, dateTo) {
    return singleton.Delay(() => shiftService.GetWorkerDurationByJob(selectedOrgId, responseDataFormat, dateFrom, dateTo));
}

export function getSimproContractWorkOrders(shiftService, selectedOrgId, paymentRunId) {
    return singleton.Delay(() => shiftService.GetSimproContractWorkOrders(selectedOrgId, paymentRunId));
}

export function updateShiftJob(shiftService, selectedOrgId, shiftId, jobId) {
    return singleton.Delay(() => shiftService.UpdateShiftJob(selectedOrgId, shiftId, jobId));
}

export function getShiftUsersBetweenDates(shiftService, selectedOrgId, responseDataFormat, dateFrom, dateTo) {
    return singleton.Delay(() => shiftService.GetShiftUsersBetweenDates(selectedOrgId, responseDataFormat, dateFrom, dateTo));
}

export function getAllShiftLogRecords(shiftService, selectedOrgId, shiftLogId) {
    return singleton.Delay(() => {
        const input = shiftService.GetAllShiftLogRecords(selectedOrgId, shiftLogId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(ShiftLogDtoModule_toShiftLog, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getPaymentReport(shiftService, selectedOrgId, paymentRunId, dataFormat) {
    return singleton.Delay(() => {
        const input = shiftService.GetPaymentReport(selectedOrgId, paymentRunId, dataFormat);
        return singleton.Bind(input, (x$0027) => {
            let x;
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(x = input_2.fields[0], (x.tag === 1) ? (new DataFormatPayload$1(1, [x.fields[0]])) : ((x.tag === 2) ? (new DataFormatPayload$1(2, [x.fields[0]])) : (new DataFormatPayload$1(0, [map(PaymentComprehensiveDtoModule_toPayment, x.fields[0])]))))])));
            return singleton.Return(value);
        });
    });
}

export function getPaymentReportPdf(shiftService, selectedOrgId, paymentRunId) {
    return singleton.Delay(() => shiftService.GetPaymentReportPdf(selectedOrgId, paymentRunId));
}

export function getTimesheetRunPdf(shiftService, selectedOrgId, timesheetReportGroupedBy, timesheetRunId, jobId) {
    return singleton.Delay(() => shiftService.GetTimesheetRunPdf(selectedOrgId, timesheetReportGroupedBy, timesheetRunId, jobId));
}

export function getShiftPdf(shiftService, selectedOrgId, shiftId) {
    return singleton.Delay(() => shiftService.GetShiftPdf(selectedOrgId, shiftId));
}

