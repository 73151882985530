import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, record_type, option_type, class_type, string_type, decimal_type, int32_type, bool_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { OrganisationDetail, Organisation, OrganisationOptions, DurationRoundMethod_$reflection } from "../Entity/Organisation.fs.js";
import { fromHours, fromMinutes, totalHours, minutes } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { toNumber } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Decimal.js";
import Decimal from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Decimal.js";
import { AddressDto, AddressDto_$reflection } from "./Address.fs.js";
import { MeasurementSystem_$reflection } from "../Entity/MeasurementUnits.fs.js";
import { ShiftRuleRawDtoModule_ofShiftRule, ShiftRuleRawDto_$reflection, ShiftRuleDtoModule_toShiftRule, ShiftRuleDtoModule_ofShiftRule, ShiftRuleDto_$reflection } from "./ShiftRule.fs.js";
import { map } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { defaultArg } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";
import { Address } from "../Entity/Address.fs.js";
import { UserRoleModule_toInt, UserRole_$reflection } from "../Entity/User.fs.js";

export const xeroScope = "openid profile email accounting.transactions payroll.employees payroll.settings offline_access accounting.contacts&state";

export const xeroRedirectURI = "https://www.10thdoor.com/linkToXero";

export class OrganisationOptionsDto extends Record {
    constructor(SetDefaultBreakDuration, DefaultBreakDuration, ApplyDefaultBreakDurationAfterWorkDurationOf, WorkDurationRoundMethod, WorkDurationRounding, IsUsingOvertimeAfterWorkHours, OvertimeAfterWorkHours, OvertimeRateMultiplier, SmartlyPayRateCode, SmartlyOvertimePayRateCode, SmartlyAllowanceCode, IgnoreUserSkillRatingForJob, Region, IsUsingXero, XeroInvoiceItemAccountCode, XeroInvoiceItemTaxType, IsUsingSimpro, IsShiftStartAndFinishOutsideGeofenceDisallowed, IsShiftStartAndFinishOutsideScheduleDisallowed, IsUsingOverheadRate, OverheadRate, DefaultShiftPreCheckTemplateId, DefaultShiftPostCheckTemplateId) {
        super();
        this.SetDefaultBreakDuration = SetDefaultBreakDuration;
        this.DefaultBreakDuration = (DefaultBreakDuration | 0);
        this.ApplyDefaultBreakDurationAfterWorkDurationOf = ApplyDefaultBreakDurationAfterWorkDurationOf;
        this.WorkDurationRoundMethod = WorkDurationRoundMethod;
        this.WorkDurationRounding = (WorkDurationRounding | 0);
        this.IsUsingOvertimeAfterWorkHours = IsUsingOvertimeAfterWorkHours;
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.OvertimeRateMultiplier = OvertimeRateMultiplier;
        this.SmartlyPayRateCode = SmartlyPayRateCode;
        this.SmartlyOvertimePayRateCode = SmartlyOvertimePayRateCode;
        this.SmartlyAllowanceCode = SmartlyAllowanceCode;
        this.IgnoreUserSkillRatingForJob = IgnoreUserSkillRatingForJob;
        this.Region = Region;
        this.IsUsingXero = IsUsingXero;
        this.XeroInvoiceItemAccountCode = XeroInvoiceItemAccountCode;
        this.XeroInvoiceItemTaxType = XeroInvoiceItemTaxType;
        this.IsUsingSimpro = IsUsingSimpro;
        this.IsShiftStartAndFinishOutsideGeofenceDisallowed = IsShiftStartAndFinishOutsideGeofenceDisallowed;
        this.IsShiftStartAndFinishOutsideScheduleDisallowed = IsShiftStartAndFinishOutsideScheduleDisallowed;
        this.IsUsingOverheadRate = IsUsingOverheadRate;
        this.OverheadRate = OverheadRate;
        this.DefaultShiftPreCheckTemplateId = DefaultShiftPreCheckTemplateId;
        this.DefaultShiftPostCheckTemplateId = DefaultShiftPostCheckTemplateId;
    }
}

export function OrganisationOptionsDto_$reflection() {
    return record_type("Domain.Dto.Organisation.OrganisationOptionsDto", [], OrganisationOptionsDto, () => [["SetDefaultBreakDuration", bool_type], ["DefaultBreakDuration", int32_type], ["ApplyDefaultBreakDurationAfterWorkDurationOf", decimal_type], ["WorkDurationRoundMethod", DurationRoundMethod_$reflection()], ["WorkDurationRounding", int32_type], ["IsUsingOvertimeAfterWorkHours", bool_type], ["OvertimeAfterWorkHours", decimal_type], ["OvertimeRateMultiplier", decimal_type], ["SmartlyPayRateCode", string_type], ["SmartlyOvertimePayRateCode", string_type], ["SmartlyAllowanceCode", string_type], ["IgnoreUserSkillRatingForJob", bool_type], ["Region", string_type], ["IsUsingXero", bool_type], ["XeroInvoiceItemAccountCode", string_type], ["XeroInvoiceItemTaxType", string_type], ["IsUsingSimpro", bool_type], ["IsShiftStartAndFinishOutsideGeofenceDisallowed", bool_type], ["IsShiftStartAndFinishOutsideScheduleDisallowed", bool_type], ["IsUsingOverheadRate", bool_type], ["OverheadRate", decimal_type], ["DefaultShiftPreCheckTemplateId", option_type(class_type("System.Guid"))], ["DefaultShiftPostCheckTemplateId", option_type(class_type("System.Guid"))]]);
}

export function OrganisationOptionsDtoModule_ofOrganisationOptions(organisationOptions) {
    return new OrganisationOptionsDto(organisationOptions.SetDefaultBreakDuration, minutes(organisationOptions.DefaultBreakDuration), new Decimal(totalHours(organisationOptions.ApplyDefaultBreakDurationAfterWorkDurationOf)), organisationOptions.WorkDurationRoundMethod, minutes(organisationOptions.WorkDurationRounding), organisationOptions.IsUsingOvertimeAfterWorkHours, organisationOptions.OvertimeAfterWorkHours, organisationOptions.OvertimeRateMultiplier, organisationOptions.SmartlyPayRateCode, organisationOptions.SmartlyOvertimePayRateCode, organisationOptions.SmartlyAllowanceCode, organisationOptions.IgnoreUserSkillRatingForJob, organisationOptions.Region, organisationOptions.IsUsingXero, organisationOptions.XeroInvoiceItemAccountCode, organisationOptions.XeroInvoiceItemTaxType, organisationOptions.IsUsingSimpro, organisationOptions.IsShiftStartAndFinishOutsideGeofenceDisallowed, organisationOptions.IsShiftStartAndFinishOutsideScheduleDisallowed, organisationOptions.IsUsingOverheadRate, organisationOptions.OverheadRate, organisationOptions.DefaultShiftPreCheckTemplateId, organisationOptions.DefaultShiftPostCheckTemplateId);
}

export function OrganisationOptionsDtoModule_toOrganisationOptions(organisationOptions) {
    return new OrganisationOptions(organisationOptions.SetDefaultBreakDuration, fromMinutes(organisationOptions.DefaultBreakDuration), fromHours(toNumber(organisationOptions.ApplyDefaultBreakDurationAfterWorkDurationOf)), organisationOptions.WorkDurationRoundMethod, fromMinutes(organisationOptions.WorkDurationRounding), organisationOptions.IsUsingOvertimeAfterWorkHours, organisationOptions.OvertimeAfterWorkHours, organisationOptions.OvertimeRateMultiplier, organisationOptions.SmartlyPayRateCode, organisationOptions.SmartlyOvertimePayRateCode, organisationOptions.SmartlyAllowanceCode, organisationOptions.IgnoreUserSkillRatingForJob, organisationOptions.Region, organisationOptions.IsUsingXero, organisationOptions.XeroInvoiceItemAccountCode, organisationOptions.XeroInvoiceItemTaxType, organisationOptions.IsUsingSimpro, organisationOptions.IsShiftStartAndFinishOutsideGeofenceDisallowed, organisationOptions.IsShiftStartAndFinishOutsideScheduleDisallowed, organisationOptions.IsUsingOverheadRate, organisationOptions.OverheadRate, organisationOptions.DefaultShiftPreCheckTemplateId, organisationOptions.DefaultShiftPostCheckTemplateId);
}

export class OrganisationDto extends Record {
    constructor(Id, Name, Address, MeasurementSystem, PayrollSystemIndex, MinWorkdayDuration, UseBreaks, ShiftRules, OrganisationOptions) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Address = Address;
        this.MeasurementSystem = MeasurementSystem;
        this.PayrollSystemIndex = (PayrollSystemIndex | 0);
        this.MinWorkdayDuration = MinWorkdayDuration;
        this.UseBreaks = UseBreaks;
        this.ShiftRules = ShiftRules;
        this.OrganisationOptions = OrganisationOptions;
    }
}

export function OrganisationDto_$reflection() {
    return record_type("Domain.Dto.Organisation.OrganisationDto", [], OrganisationDto, () => [["Id", option_type(class_type("System.Guid"))], ["Name", string_type], ["Address", AddressDto_$reflection()], ["MeasurementSystem", MeasurementSystem_$reflection()], ["PayrollSystemIndex", int32_type], ["MinWorkdayDuration", decimal_type], ["UseBreaks", bool_type], ["ShiftRules", list_type(ShiftRuleDto_$reflection())], ["OrganisationOptions", OrganisationOptionsDto_$reflection()]]);
}

export function OrganisationDtoModule_ofOrganisation(organisation) {
    const address = organisation.Address;
    return new OrganisationDto(organisation.Id, organisation.Name, new AddressDto(address.Id, address.UnitNumber, address.Street, address.Suburb, address.City, address.Country, address.PostCode, address.Latitude, address.Longitude, address.IsActive), organisation.MeasurementSystem, organisation.PayrollSystemIndex, organisation.MinWorkdayDuration, organisation.UseBreaks, map(ShiftRuleDtoModule_ofShiftRule, organisation.ShiftRules), OrganisationOptionsDtoModule_ofOrganisationOptions(organisation.OrganisationOptions));
}

export function OrganisationDtoModule_toOrganisation(organisation) {
    const address = organisation.Address;
    return new Organisation(organisation.Id, organisation.Name, new Address(defaultArg(address.Id, "00000000-0000-0000-0000-000000000000"), defaultArg(organisation.Id, "00000000-0000-0000-0000-000000000000"), address.UnitNumber, address.Street, address.Suburb, address.City, address.Country, address.PostCode, address.Latitude, address.Longitude, address.IsActive), organisation.MeasurementSystem, organisation.PayrollSystemIndex, organisation.MinWorkdayDuration, organisation.UseBreaks, map(ShiftRuleDtoModule_toShiftRule, organisation.ShiftRules), OrganisationOptionsDtoModule_toOrganisationOptions(organisation.OrganisationOptions));
}

export class OrganisationDetailDto extends Record {
    constructor(Id, Name, UserRole, ShiftRules, UseBreaks, IsUsingXero, IsUsingSimpro, IsShiftStartAndFinishOutsideGeofenceDisallowed, IsShiftStartAndFinishOutsideScheduleDisallowed) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.UserRole = UserRole;
        this.ShiftRules = ShiftRules;
        this.UseBreaks = UseBreaks;
        this.IsUsingXero = IsUsingXero;
        this.IsUsingSimpro = IsUsingSimpro;
        this.IsShiftStartAndFinishOutsideGeofenceDisallowed = IsShiftStartAndFinishOutsideGeofenceDisallowed;
        this.IsShiftStartAndFinishOutsideScheduleDisallowed = IsShiftStartAndFinishOutsideScheduleDisallowed;
    }
}

export function OrganisationDetailDto_$reflection() {
    return record_type("Domain.Dto.Organisation.OrganisationDetailDto", [], OrganisationDetailDto, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["UserRole", UserRole_$reflection()], ["ShiftRules", list_type(ShiftRuleDto_$reflection())], ["UseBreaks", bool_type], ["IsUsingXero", bool_type], ["IsUsingSimpro", bool_type], ["IsShiftStartAndFinishOutsideGeofenceDisallowed", bool_type], ["IsShiftStartAndFinishOutsideScheduleDisallowed", bool_type]]);
}

export function OrganisationDetailDtoModule_ofOrganisationDetail(organisation) {
    return new OrganisationDetailDto(organisation.Id, organisation.Name, organisation.UserRole, map(ShiftRuleDtoModule_ofShiftRule, organisation.ShiftRules), organisation.UseBreaks, organisation.IsUsingXero, organisation.IsUsingSimpro, organisation.IsShiftStartAndFinishOutsideGeofenceDisallowed, organisation.IsShiftStartAndFinishOutsideScheduleDisallowed);
}

export function OrganisationDetailDtoModule_toOrganisation(organisation) {
    return new OrganisationDetail(organisation.Id, organisation.Name, organisation.UserRole, map(ShiftRuleDtoModule_toShiftRule, organisation.ShiftRules), organisation.UseBreaks, organisation.IsUsingXero, organisation.IsUsingSimpro, organisation.IsShiftStartAndFinishOutsideGeofenceDisallowed, organisation.IsShiftStartAndFinishOutsideScheduleDisallowed);
}

export class OrganisationDetailRawDto extends Record {
    constructor(Id, Name, UserRole, ShiftRules, UseBreaks, IsShiftStartAndFinishOutsideGeofenceDisallowed, IsShiftStartAndFinishOutsideScheduleDisallowed) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.UserRole = (UserRole | 0);
        this.ShiftRules = ShiftRules;
        this.UseBreaks = UseBreaks;
        this.IsShiftStartAndFinishOutsideGeofenceDisallowed = IsShiftStartAndFinishOutsideGeofenceDisallowed;
        this.IsShiftStartAndFinishOutsideScheduleDisallowed = IsShiftStartAndFinishOutsideScheduleDisallowed;
    }
}

export function OrganisationDetailRawDto_$reflection() {
    return record_type("Domain.Dto.Organisation.OrganisationDetailRawDto", [], OrganisationDetailRawDto, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["UserRole", int32_type], ["ShiftRules", list_type(ShiftRuleRawDto_$reflection())], ["UseBreaks", bool_type], ["IsShiftStartAndFinishOutsideGeofenceDisallowed", bool_type], ["IsShiftStartAndFinishOutsideScheduleDisallowed", bool_type]]);
}

export function OrganisationDetailRawDtoModule_ofOrganisationDetail(organisation) {
    return new OrganisationDetailRawDto(organisation.Id, organisation.Name, UserRoleModule_toInt(organisation.UserRole), map(ShiftRuleRawDtoModule_ofShiftRule, organisation.ShiftRules), organisation.UseBreaks, organisation.IsShiftStartAndFinishOutsideGeofenceDisallowed, organisation.IsShiftStartAndFinishOutsideScheduleDisallowed);
}

