import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchVehicles } from "../../../AppDomain/UseCase/Vehicle.fs.js";
import { IndexView as IndexView_2, Msg$1, update as update_1, init as init_1 } from "../BaseSearch.fs.js";
import { defaultOf, createObj, uncurry2 } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { singleton, ofArray } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { map, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { unwrap, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { toString } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { FuelUnitModule_toString } from "../../../../Logos.Shared/Domain/Entity/LogMaster.fs.js";
import { Daisy_DialogWidth, alignIconCenter } from "../../Component/Component.fs.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { IndexView as IndexView_1 } from "./VehicleEdit.fs.js";

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchVehicles(appRoot.VehicleService, selectedOrgId, searchText, pagination));
}

export function init() {
    return init_1(uncurry2(searchFunc()));
}

export function update(msg, state) {
    return update_1(uncurry2(searchFunc()), msg, state);
}

export function vehicleTable(props) {
    let elems_2, children_2, children, children_6;
    const children_9 = singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_2 = [(children_2 = singleton((children = ofArray([createElement("th", {
        width: 20 + "%",
        children: "Make",
    }), createElement("th", {
        width: 20 + "%",
        children: "Model",
    }), createElement("th", {
        width: 10 + "%",
        children: "Year",
    }), createElement("th", {
        width: 20 + "%",
        children: "Registration",
    }), createElement("th", {
        width: 10 + "%",
        children: "Fuel",
    }), createElement("th", {
        width: 10 + "%",
        children: "Fuel Unit",
    }), createElement("th", {
        width: 10 + "%",
        children: "Distance Unit",
    }), createElement("th", {
        width: 10 + "%",
        children: "Default Tag",
    }), createElement("th", {
        width: 10 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_6 = toList(delay(() => map((vehicle) => {
        let elems_1, children_4, elems;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_1 = [createElement("td", {
            children: [vehicle.Make],
        }), createElement("td", {
            children: [defaultArg(vehicle.Model, "")],
        }), createElement("td", {
            children: [defaultArg(vehicle.Year, "")],
        }), createElement("td", {
            children: [defaultArg(vehicle.Registration, "")],
        }), createElement("td", {
            children: [toString(vehicle.FuelType)],
        }), createElement("td", {
            children: [FuelUnitModule_toString(vehicle.FuelUnit)],
        }), createElement("td", {
            children: [toString(vehicle.DistanceUnit)],
        }), createElement("td", {
            children: [vehicle.DefaultTag.Name],
        }), (children_4 = singleton(createElement("div", createObj(ofArray([alignIconCenter, (elems = [createElement(showModalEx, {
            dialogId: unwrap(vehicle.Id),
            header: (`Vehicle - ${vehicle.Make} ${vehicle.Model} ${vehicle.Registration}`).trim(),
            buttonType: new ButtonType(1, ["fa-edit"]),
            width: new Daisy_DialogWidth(3, []),
            body: () => {
                const matchValue = props.state.EntityToEdit;
                if (matchValue == null) {
                    return createElement(IndexView_1, {
                        vehicleId: defaultOf(),
                    });
                }
                else {
                    const x = matchValue;
                    return createElement(IndexView_1, {
                        vehicleId: x,
                    });
                }
            },
            onShow: () => {
                props.dispatch(new Msg$1(4, [vehicle.Id]));
            },
            onClose: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_4)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
    }, props.state.Entities))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_9)),
    });
}

export function IndexView() {
    return createElement(IndexView_2, {
        entityName: "Vehicle",
        entityTable: vehicleTable,
        searchFunc: uncurry2(searchFunc()),
    });
}

