import { toString, Record, Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { tuple_type, list_type, decimal_type, record_type, bool_type, int32_type, option_type, class_type, union_type, string_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { create, value as value_157 } from "../../../../Logos.Shared/Domain/Entity/Email.fs.js";
import { create as create_1, value as value_158 } from "../../../../Logos.Shared/Domain/Entity/Phone.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { XeroCustomer, XeroCustomer_$reflection, Contact, ContactType as ContactType_2 } from "../../../../Logos.Shared/Domain/Entity/Customer.fs.js";
import { AddressDto } from "../../../../Logos.Shared/Domain/Dto/Address.fs.js";
import { CustomerDto, CustomerDto_$reflection, CustomerMemberDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/Customer.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Option_toString, Option_ofString, AsyncResult_bindOfResult } from "../../../../Logos.Shared/Util.fs.js";
import { filter, mapIndexed, ofArray, length, append, concat, map, empty, cons, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { getOrganisation } from "../../../AppDomain/UseCase/Organisation.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { saveCustomer, addNewCustomer, getCustomer } from "../../../AppDomain/UseCase/Customer.fs.js";
import { getXeroCustomers as getXeroCustomers_1 } from "../../../AppDomain/UseCase/Xero.fs.js";
import { traverseResultA } from "../../../fable_modules/FsToolkit.ErrorHandling.4.16.0/List.fs.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { join, isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { createObj, equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { fromParts } from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { Page, Router_navigatePage } from "../../Router.fs.js";
import { createElement } from "react";
import React from "react";
import { Daisy_progressState, Daisy_error, Daisy_h4, Daisy_title6, Daisy_h5 } from "../../Component/Component.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { empty as empty_1, singleton as singleton_2, append as append_1, map as map_1, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { TitleSize, viewBox } from "../../Component/ViewBox.fs.js";
import { IndexView as IndexView_1 } from "./CustomerBankStatementAssessment.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";

export class ContactTypeVM extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Phone", "Email"];
    }
}

export function ContactTypeVM_$reflection() {
    return union_type("Presentation.Pages.CustomerEdit.ContactTypeVM", [], ContactTypeVM, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export class ContactVM extends Record {
    constructor(Id, ParentId, Index, ContactType, IsActive) {
        super();
        this.Id = Id;
        this.ParentId = ParentId;
        this.Index = (Index | 0);
        this.ContactType = ContactType;
        this.IsActive = IsActive;
    }
}

export function ContactVM_$reflection() {
    return record_type("Presentation.Pages.CustomerEdit.ContactVM", [], ContactVM, () => [["Id", option_type(class_type("System.Guid"))], ["ParentId", option_type(class_type("System.Guid"))], ["Index", int32_type], ["ContactType", ContactTypeVM_$reflection()], ["IsActive", bool_type]]);
}

export class AddressVM extends Record {
    constructor(Id, Index, UnitNumber, Street, Suburb, City, Country, PostCode, Latitude, Longitude, IsActive) {
        super();
        this.Id = Id;
        this.Index = (Index | 0);
        this.UnitNumber = UnitNumber;
        this.Street = Street;
        this.Suburb = Suburb;
        this.City = City;
        this.Country = Country;
        this.PostCode = PostCode;
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.IsActive = IsActive;
    }
}

export function AddressVM_$reflection() {
    return record_type("Presentation.Pages.CustomerEdit.AddressVM", [], AddressVM, () => [["Id", option_type(class_type("System.Guid"))], ["Index", int32_type], ["UnitNumber", string_type], ["Street", string_type], ["Suburb", string_type], ["City", string_type], ["Country", string_type], ["PostCode", string_type], ["Latitude", decimal_type], ["Longitude", decimal_type], ["IsActive", bool_type]]);
}

export function ContactTypeVMModule_ofContactType(contactType) {
    if (contactType.tag === 1) {
        return new ContactTypeVM(1, [value_157(contactType.fields[0])]);
    }
    else {
        return new ContactTypeVM(0, [value_158(contactType.fields[0])]);
    }
}

export function ContactTypeVMModule_toContactType(contactType) {
    if (contactType.tag === 1) {
        const input_1 = create(contactType.fields[0]);
        if (input_1.tag === 1) {
            return new FSharpResult$2(1, [input_1.fields[0]]);
        }
        else {
            return new FSharpResult$2(0, [new ContactType_2(1, [input_1.fields[0]])]);
        }
    }
    else {
        const input = create_1(contactType.fields[0]);
        if (input.tag === 1) {
            return new FSharpResult$2(1, [input.fields[0]]);
        }
        else {
            return new FSharpResult$2(0, [new ContactType_2(0, [input.fields[0]])]);
        }
    }
}

export function ContactVMModule_ofContactDto(contact) {
    return new ContactVM(contact.Id, contact.ParentId, -1, ContactTypeVMModule_ofContactType(contact.ContactType), contact.IsActive);
}

export function ContactVMModule_toContactDto(contact) {
    const input = ContactTypeVMModule_toContactType(contact.ContactType);
    if (input.tag === 1) {
        return new FSharpResult$2(1, [input.fields[0]]);
    }
    else {
        return new FSharpResult$2(0, [new Contact(contact.Id, contact.ParentId, input.fields[0], contact.IsActive)]);
    }
}

export function ContactVMModule_typeName(contact) {
    if (contact.ContactType.tag === 1) {
        return "Email";
    }
    else {
        return "Phone";
    }
}

export function ContactVMModule_value(contact) {
    const matchValue = contact.ContactType;
    if (matchValue.tag === 1) {
        return matchValue.fields[0];
    }
    else {
        return matchValue.fields[0];
    }
}

export function AddressVMModule_ofAddressDto(address_1) {
    return new AddressVM(address_1.Id, -1, address_1.UnitNumber, address_1.Street, address_1.Suburb, address_1.City, address_1.Country, address_1.PostCode, address_1.Latitude, address_1.Longitude, address_1.IsActive);
}

export function AddressVMModule_toAddressDto(address_1) {
    return new FSharpResult$2(0, [new AddressDto(address_1.Id, address_1.UnitNumber, address_1.Street, address_1.Suburb, address_1.City, address_1.Country, address_1.PostCode, address_1.Latitude, address_1.Longitude, address_1.IsActive)]);
}

export class Tab extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PersonalDetail", "Assessment"];
    }
}

export function Tab_$reflection() {
    return union_type("Presentation.Pages.CustomerEdit.Tab", [], Tab, () => [[], []]);
}

export class State extends Record {
    constructor(Id, ActiveTab, IsNewCustomer, BusinessName, Title, FirstName, MiddleName, LastName, Addresses, Contacts, CustomerMembers, FetchedCustomer, IsUsingXero, XeroCustomerId, XeroCustomers, IsBusy, Errors) {
        super();
        this.Id = Id;
        this.ActiveTab = ActiveTab;
        this.IsNewCustomer = IsNewCustomer;
        this.BusinessName = BusinessName;
        this.Title = Title;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.Addresses = Addresses;
        this.Contacts = Contacts;
        this.CustomerMembers = CustomerMembers;
        this.FetchedCustomer = FetchedCustomer;
        this.IsUsingXero = IsUsingXero;
        this.XeroCustomerId = XeroCustomerId;
        this.XeroCustomers = XeroCustomers;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.CustomerEdit.State", [], State, () => [["Id", class_type("System.Guid")], ["ActiveTab", Tab_$reflection()], ["IsNewCustomer", bool_type], ["BusinessName", string_type], ["Title", string_type], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["Addresses", list_type(AddressVM_$reflection())], ["Contacts", list_type(ContactVM_$reflection())], ["CustomerMembers", list_type(CustomerMemberDto_$reflection())], ["FetchedCustomer", option_type(CustomerDto_$reflection())], ["IsUsingXero", bool_type], ["XeroCustomerId", string_type], ["XeroCustomers", list_type(XeroCustomer_$reflection())], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetBusinessName", "SetTitle", "SetFirstName", "SetMiddleName", "SetLastName", "GetCustomersResponse", "SetUnitNumber", "SetStreet", "SetSuburb", "SetCity", "SetCountry", "SetPostCode", "SetAddressActive", "SetXeroCustomerId", "AddEmail", "SetEmail", "AddPhone", "SetPhone", "SetContactActive", "AddAddress", "SaveCustomer", "SaveCustomerResponse", "SetActiveTab", "LoadCustomer", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.CustomerEdit.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(XeroCustomer_$reflection()), CustomerDto_$reflection(), bool_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(XeroCustomer_$reflection()), CustomerDto_$reflection(), bool_type)]], [["ErrorValue", list_type(string_type)]]])]], [["Item1", AddressVM_$reflection()], ["Item2", string_type]], [["Item1", AddressVM_$reflection()], ["Item2", string_type]], [["Item1", AddressVM_$reflection()], ["Item2", string_type]], [["Item1", AddressVM_$reflection()], ["Item2", string_type]], [["Item1", AddressVM_$reflection()], ["Item2", string_type]], [["Item1", AddressVM_$reflection()], ["Item2", string_type]], [["Item1", AddressVM_$reflection()], ["Item2", bool_type]], [["Item", string_type]], [], [["Item1", ContactVM_$reflection()], ["Item2", string_type]], [], [["Item1", ContactVM_$reflection()], ["Item2", string_type]], [["Item1", ContactVM_$reflection()], ["Item2", bool_type]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(class_type("System.Guid")), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(class_type("System.Guid"))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", Tab_$reflection()]], [["Item", class_type("System.Guid")]], [["Item", class_type("System.Exception")]]]);
}

export function getCustomerCmd(customerId) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(24, [Item_1])), () => singleton.Delay(() => {
        const asyncResult_1 = AsyncResult_bindOfResult((option) => {
            const option_1 = option;
            if (option_1 == null) {
                return new FSharpResult$2(1, [singleton_1("Organisation not found.")]);
            }
            else {
                return new FSharpResult$2(0, [option_1]);
            }
        }, getOrganisation(appRoot.OrganisationService, AppRootModule_getSelectedOrgId()));
        return singleton.Bind(asyncResult_1, (input_1_3) => {
            const input_13 = input_1_3;
            if (input_13.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_13.fields[0]]));
            }
            else {
                const isUsingXero = input_13.fields[0].OrganisationOptions.IsUsingXero;
                const asyncResult = getCustomer(appRoot.CustomerService, AppRootModule_getSelectedOrgId(), customerId);
                return singleton.Bind(asyncResult, (input_1_2) => {
                    const input_10 = input_1_2;
                    if (input_10.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_10.fields[0]]));
                    }
                    else {
                        const x_4 = singleton.Delay(() => {
                            if (isUsingXero) {
                                const x_2 = getXeroCustomers_1(appRoot.XeroService, AppRootModule_getSelectedOrgId());
                                return singleton.Bind(x_2, (x$0027) => {
                                    let value;
                                    const input_2 = x$0027;
                                    value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [cons(new XeroCustomer("00000000-0000-0000-0000-000000000000", "Select Xero Customer"), input_2.fields[0])])));
                                    return singleton.Return(value);
                                });
                            }
                            else {
                                return singleton.Return(new FSharpResult$2(0, [empty()]));
                            }
                        });
                        return singleton.Bind(x_4, (x$0027_1) => {
                            let value_4;
                            const input_6 = x$0027_1;
                            value_4 = ((input_6.tag === 1) ? (new FSharpResult$2(1, [input_6.fields[0]])) : (new FSharpResult$2(0, [[input_6.fields[0], input_10.fields[0], isUsingXero]])));
                            return singleton.Return(value_4);
                        });
                    }
                });
            }
        });
    }));
}

export function saveCustomerCmd(state) {
    return cmdOfAsync((Item) => (new Msg(21, [Item])), (Item_1) => (new Msg(24, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_2;
        let value_1;
        const input_1 = traverseResultA((x) => x, map(ContactVMModule_toContactDto, state.Contacts));
        value_1 = ((input_1.tag === 1) ? (new FSharpResult$2(1, [concat(input_1.fields[0])])) : (new FSharpResult$2(0, [input_1.fields[0]])));
        asyncResult_2 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_2, (input_1_5) => {
            const input_22 = input_1_5;
            if (input_22.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_22.fields[0]]));
            }
            else {
                let asyncResult_1;
                let value_1_1;
                const input_3 = traverseResultA((x_2) => x_2, map(AddressVMModule_toAddressDto, state.Addresses));
                value_1_1 = ((input_3.tag === 1) ? (new FSharpResult$2(1, [concat(input_3.fields[0])])) : (new FSharpResult$2(0, [input_3.fields[0]])));
                asyncResult_1 = singleton.Return(value_1_1);
                return singleton.Bind(asyncResult_1, (input_1_4) => {
                    const input_19 = input_1_4;
                    if (input_19.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_19.fields[0]]));
                    }
                    else {
                        let asyncResult;
                        let value_1_2;
                        if (state.IsNewCustomer) {
                            value_1_2 = (new FSharpResult$2(0, [new CustomerDto(undefined, undefined, undefined, undefined, "", undefined, "", empty(), empty(), empty(), undefined, true)]));
                        }
                        else {
                            const option_1 = state.FetchedCustomer;
                            value_1_2 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Customer not assigned.")])) : (new FSharpResult$2(0, [option_1])));
                        }
                        asyncResult = singleton.Return(value_1_2);
                        return singleton.Bind(asyncResult, (input_1_3) => {
                            let input_6;
                            const input_16 = input_1_3;
                            if (input_16.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_16.fields[0]]));
                            }
                            else {
                                const customer = input_16.fields[0];
                                const customer_1 = new CustomerDto(customer.Id, customer.CustomerGroupId, Option_ofString(state.BusinessName), Option_ofString(state.Title), state.FirstName, Option_ofString(state.MiddleName), state.LastName, input_19.fields[0], input_22.fields[0], customer.Members, (input_6 = Option_ofString(state.XeroCustomerId), (input_6 == null) ? undefined : parse(input_6)), customer.IsActive);
                                if (state.IsNewCustomer) {
                                    const x_5 = addNewCustomer(appRoot.CustomerService, AppRootModule_getSelectedOrgId(), customer_1);
                                    return singleton.Bind(x_5, (x$0027) => {
                                        let value_9;
                                        const input_8 = x$0027;
                                        value_9 = ((input_8.tag === 1) ? (new FSharpResult$2(1, [input_8.fields[0]])) : (new FSharpResult$2(0, [input_8.fields[0]])));
                                        return singleton.Return(value_9);
                                    });
                                }
                                else {
                                    const x_7 = saveCustomer(appRoot.CustomerService, AppRootModule_getSelectedOrgId(), customer_1);
                                    return singleton.Bind(x_7, (x$0027_1) => {
                                        let value_11;
                                        const input_12 = x$0027_1;
                                        value_11 = ((input_12.tag === 1) ? (new FSharpResult$2(1, [input_12.fields[0]])) : (new FSharpResult$2(0, [undefined])));
                                        return singleton.Return(value_11);
                                    });
                                }
                            }
                        });
                    }
                });
            }
        });
    }));
}

export function init(customerId) {
    const isNewCustomer = isNullOrEmpty(customerId);
    return [new State("00000000-0000-0000-0000-000000000000", new Tab(0, []), isNewCustomer, "", "", "", "", "", empty(), empty(), empty(), undefined, false, "", empty(), isNewCustomer ? (new Busy(0, [])) : (new Busy(1, [])), empty()), isNewCustomer ? Cmd_none() : getCustomerCmd(customerId)];
}

export function updateAddress(state, f) {
    const updatedAddress = f();
    return map((x) => {
        if (x.Index === updatedAddress.Index) {
            return updatedAddress;
        }
        else {
            return x;
        }
    }, state.Addresses);
}

export function updateContact(state, f) {
    const updatedContact = f();
    return map((x) => {
        if (x.Index === updatedContact.Index) {
            return updatedContact;
        }
        else {
            return x;
        }
    }, state.Contacts);
}

export function update(msg, state) {
    let input_1;
    switch (msg.tag) {
        case 0:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, msg.fields[0], state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 1:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, msg.fields[0], state.FirstName, state.MiddleName, state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, msg.fields[0], state.MiddleName, state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, msg.fields[0], state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, msg.fields[0], state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(defaultArg(msg.fields[0].fields[0][1].Id, "00000000-0000-0000-0000-000000000000"), state.ActiveTab, false, Option_toString(msg.fields[0].fields[0][1].BusinessName), Option_toString(msg.fields[0].fields[0][1].Title), msg.fields[0].fields[0][1].FirstName, defaultArg(msg.fields[0].fields[0][1].MiddleName, ""), msg.fields[0].fields[0][1].LastName, map(AddressVMModule_ofAddressDto, msg.fields[0].fields[0][1].Addresses), map(ContactVMModule_ofContactDto, msg.fields[0].fields[0][1].Contacts), msg.fields[0].fields[0][1].Members, msg.fields[0].fields[0][1], msg.fields[0].fields[0][2], defaultArg((input_1 = msg.fields[0].fields[0][1].XeroCustomerId, (input_1 == null) ? undefined : input_1), ""), msg.fields[0].fields[0][0], new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 6:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, updateAddress(state, () => (new AddressVM(msg.fields[0].Id, msg.fields[0].Index, msg.fields[1], msg.fields[0].Street, msg.fields[0].Suburb, msg.fields[0].City, msg.fields[0].Country, msg.fields[0].PostCode, msg.fields[0].Latitude, msg.fields[0].Longitude, msg.fields[0].IsActive))), state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 7:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, updateAddress(state, () => (new AddressVM(msg.fields[0].Id, msg.fields[0].Index, msg.fields[0].UnitNumber, msg.fields[1], msg.fields[0].Suburb, msg.fields[0].City, msg.fields[0].Country, msg.fields[0].PostCode, msg.fields[0].Latitude, msg.fields[0].Longitude, msg.fields[0].IsActive))), state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 8:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, updateAddress(state, () => (new AddressVM(msg.fields[0].Id, msg.fields[0].Index, msg.fields[0].UnitNumber, msg.fields[0].Street, msg.fields[1], msg.fields[0].City, msg.fields[0].Country, msg.fields[0].PostCode, msg.fields[0].Latitude, msg.fields[0].Longitude, msg.fields[0].IsActive))), state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 9:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, updateAddress(state, () => (new AddressVM(msg.fields[0].Id, msg.fields[0].Index, msg.fields[0].UnitNumber, msg.fields[0].Street, msg.fields[0].Suburb, msg.fields[1], msg.fields[0].Country, msg.fields[0].PostCode, msg.fields[0].Latitude, msg.fields[0].Longitude, msg.fields[0].IsActive))), state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 10:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, updateAddress(state, () => (new AddressVM(msg.fields[0].Id, msg.fields[0].Index, msg.fields[0].UnitNumber, msg.fields[0].Street, msg.fields[0].Suburb, msg.fields[0].City, msg.fields[1], msg.fields[0].PostCode, msg.fields[0].Latitude, msg.fields[0].Longitude, msg.fields[0].IsActive))), state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 11:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, updateAddress(state, () => (new AddressVM(msg.fields[0].Id, msg.fields[0].Index, msg.fields[0].UnitNumber, msg.fields[0].Street, msg.fields[0].Suburb, msg.fields[0].City, msg.fields[0].Country, msg.fields[1], msg.fields[0].Latitude, msg.fields[0].Longitude, msg.fields[0].IsActive))), state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 12:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, map((x_15) => {
                if (equals(x_15, msg.fields[0])) {
                    return new AddressVM(x_15.Id, x_15.Index, x_15.UnitNumber, x_15.Street, x_15.Suburb, x_15.City, x_15.Country, x_15.PostCode, x_15.Latitude, x_15.Longitude, msg.fields[1]);
                }
                else {
                    return x_15;
                }
            }, state.Addresses), state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 14:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, append(state.Contacts, singleton_1(new ContactVM(undefined, undefined, length(state.Contacts) + 1, new ContactTypeVM(1, [""]), true))), state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 15:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, updateContact(state, () => (new ContactVM(msg.fields[0].Id, msg.fields[0].ParentId, msg.fields[0].Index, new ContactTypeVM(1, [msg.fields[1]]), msg.fields[0].IsActive))), state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 16:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, append(state.Contacts, singleton_1(new ContactVM(undefined, undefined, length(state.Contacts) + 1, new ContactTypeVM(0, [""]), true))), state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 17:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, updateContact(state, () => (new ContactVM(msg.fields[0].Id, msg.fields[0].ParentId, msg.fields[0].Index, new ContactTypeVM(0, [msg.fields[1]]), msg.fields[0].IsActive))), state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 18:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, map((x_18) => {
                if (equals(x_18, msg.fields[0])) {
                    return new ContactVM(x_18.Id, x_18.ParentId, x_18.Index, x_18.ContactType, msg.fields[1]);
                }
                else {
                    return x_18;
                }
            }, state.Contacts), state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 13:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, msg.fields[0], state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 19:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, append(state.Addresses, singleton_1(new AddressVM(undefined, length(state.Addresses) + 1, "", "", "", "", "", "", fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), true))), state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
        case 20:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, new Busy(2, []), state.Errors), saveCustomerCmd(state)];
        case 21:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, new Busy(0, []), state.Errors), Cmd_none()];
            }
            else {
                const x_20 = msg.fields[0].fields[0];
                return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, new Busy(0, []), state.Errors), state.IsNewCustomer ? getCustomerCmd(x_20) : Cmd_none()];
            }
        case 23: {
            const customerId = msg.fields[0];
            Router_navigatePage(new Page(26, [customerId]));
            return init(customerId);
        }
        case 24:
            return [new State(state.Id, state.ActiveTab, state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, new Busy(0, []), singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [new State(state.Id, msg.fields[0], state.IsNewCustomer, state.BusinessName, state.Title, state.FirstName, state.MiddleName, state.LastName, state.Addresses, state.Contacts, state.CustomerMembers, state.FetchedCustomer, state.IsUsingXero, state.XeroCustomerId, state.XeroCustomers, state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function businessDetail(state, isSaving, dispatch) {
    let elems_8, children_4, children_2, children_9, children_7, children_14, children_12, children_19, children_17;
    return createElement("div", createObj(ofArray([["className", join(" ", ["box-content", "border-2", "p-8", "mb-4", "rounded"])], (elems_8 = [Daisy_h5("Main Contact"), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), (children_4 = ofArray([(children_2 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Title",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["value", state.Title], ["placeholder", "Title"], ["readOnly", isSaving], ["onChange", (ev) => {
        dispatch(new Msg(1, [ev.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_4)),
    })), (children_9 = ofArray([(children_7 = singleton_1(createElement("span", {
        className: "label-text",
        children: "First name",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_7)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state.FirstName], ["placeholder", "First name"], ["readOnly", isSaving], ["onChange", (ev_1) => {
        dispatch(new Msg(2, [ev_1.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_9)),
    })), (children_14 = ofArray([(children_12 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Middle name",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_12)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state.MiddleName], ["placeholder", "Middle name"], ["readOnly", isSaving], ["onChange", (ev_2) => {
        dispatch(new Msg(3, [ev_2.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_14)),
    })), (children_19 = ofArray([(children_17 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Last name",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_17)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state.LastName], ["placeholder", "Last name"], ["readOnly", isSaving], ["onChange", (ev_3) => {
        dispatch(new Msg(4, [ev_3.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_19)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])));
}

export function customerMembers(state, isSaving, dispatch) {
    let elems_2, elems_1, children_3, children_1, children_14;
    return createElement("div", createObj(ofArray([["className", join(" ", ["box-content", "border-2", "p-8", "m-4", "rounded"])], (elems_2 = [Daisy_h5("Members"), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("table", createObj(ofArray([["className", "table-xs"], ["className", join(" ", ["w-full"])], (elems_1 = [(children_3 = singleton_1((children_1 = ofArray([createElement("th", {
        width: 30 + "%",
        className: join(" ", ["text-left"]),
        children: "First name",
    }), createElement("th", {
        width: 30 + "%",
        className: join(" ", ["text-left"]),
        children: "Middle name",
    }), createElement("th", {
        width: 30 + "%",
        className: join(" ", ["text-left"]),
        children: "Last name",
    }), createElement("th", {
        width: 10 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), (children_14 = toList(delay(() => map_1((x) => {
        let children_7, elems;
        const children_12 = ofArray([createElement("td", {
            children: reactApi.Children.toArray([x.FirstName]),
        }), (children_7 = singleton_1(defaultArg(x.MiddleName, "")), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_7)),
        })), createElement("td", {
            children: reactApi.Children.toArray([x.LastName]),
        }), createElement("td", createObj(ofArray([["className", join(" ", ["flex", "justify-end"])], (elems = [createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["children", "View"], ["onClick", (_arg) => {
            dispatch(new Msg(23, [defaultArg(x.Id, "00000000-0000-0000-0000-000000000000")]));
        }]]))))], ["children", reactApi.Children.toArray(Array.from(elems))])])))]);
        return createElement("tr", {
            children: reactApi.Children.toArray(Array.from(children_12)),
        });
    }, state.CustomerMembers))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_14)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function address(index, address_1, isSaving, dispatch) {
    let elems_15, elems_2, elems, elems_1, children_5, children_3, children_10, children_8, children_15, children_13, children_20, children_18, children_25, children_23, children_30, children_28;
    return createElement("div", createObj(ofArray([["className", join(" ", ["box-content", "border-2", "p-8", "m-1", "rounded", "w-1/4"])], (elems_15 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "lg:grid-cols-3", "items-center"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2", "lg:col-span-2"])], (elems = [Daisy_title6(`Address ${index + 1}`)], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", join(" ", ["right-auto"])], (elems_1 = [createElement("span", {
        className: "label-text",
        children: "Active",
    }), createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-sm"], ["checked", address_1.IsActive], ["onChange", (ev) => {
        let tupledArg;
        dispatch((tupledArg = [address_1, ev.target.checked], new Msg(12, [tupledArg[0], tupledArg[1]])));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), (children_5 = ofArray([(children_3 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Unit Number",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", address_1.UnitNumber], ["placeholder", "Unit Number"], ["readOnly", isSaving], ["onChange", (ev_1) => {
        let tupledArg_1;
        dispatch((tupledArg_1 = [address_1, ev_1.target.value], new Msg(6, [tupledArg_1[0], tupledArg_1[1]])));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), (children_10 = ofArray([(children_8 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Street",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_8)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", address_1.Street], ["placeholder", "Street"], ["readOnly", isSaving], ["onChange", (ev_2) => {
        let tupledArg_2;
        dispatch((tupledArg_2 = [address_1, ev_2.target.value], new Msg(7, [tupledArg_2[0], tupledArg_2[1]])));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_10)),
    })), (children_15 = ofArray([(children_13 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Suburb",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_13)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", address_1.Suburb], ["placeholder", "Suburb"], ["readOnly", isSaving], ["onChange", (ev_3) => {
        let tupledArg_3;
        dispatch((tupledArg_3 = [address_1, ev_3.target.value], new Msg(8, [tupledArg_3[0], tupledArg_3[1]])));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_15)),
    })), (children_20 = ofArray([(children_18 = singleton_1(createElement("span", {
        className: "label-text",
        children: "City",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_18)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", address_1.City], ["placeholder", "City"], ["readOnly", isSaving], ["onChange", (ev_4) => {
        let tupledArg_4;
        dispatch((tupledArg_4 = [address_1, ev_4.target.value], new Msg(9, [tupledArg_4[0], tupledArg_4[1]])));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_20)),
    })), (children_25 = ofArray([(children_23 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Country",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_23)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", address_1.Country], ["placeholder", "Country"], ["readOnly", isSaving], ["onChange", (ev_5) => {
        let tupledArg_5;
        dispatch((tupledArg_5 = [address_1, ev_5.target.value], new Msg(10, [tupledArg_5[0], tupledArg_5[1]])));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_25)),
    })), (children_30 = ofArray([(children_28 = singleton_1(createElement("span", {
        className: "label-text",
        children: "PostCode",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_28)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", address_1.PostCode], ["placeholder", "PostCode"], ["readOnly", isSaving], ["onChange", (ev_6) => {
        let tupledArg_6;
        dispatch((tupledArg_6 = [address_1, ev_6.target.value], new Msg(11, [tupledArg_6[0], tupledArg_6[1]])));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_30)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_15))])])));
}

function Contact_contact(msg, index, contact, isSaving, dispatch) {
    let elems_4, elems_2, elems, elems_1, children_2;
    const contactType = ContactVMModule_typeName(contact);
    return createElement("div", createObj(ofArray([["className", join(" ", ["box-content", "border-2", "p-4", "m-1", "rounded"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "lg:grid-cols-5", "items-center"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2", "lg:col-span-4"])], (elems = [Daisy_title6(`${contactType} ${index + 1}`)], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", join(" ", ["right-auto"])], (elems_1 = [createElement("span", {
        className: "label-text",
        children: "Active",
    }), createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-sm"], ["checked", contact.IsActive], ["onChange", (ev) => {
        let tupledArg;
        dispatch((tupledArg = [contact, ev.target.checked], new Msg(18, [tupledArg[0], tupledArg[1]])));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), (children_2 = singleton_1(createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", ContactVMModule_value(contact)], ["placeholder", ContactVMModule_typeName(contact)], ["readOnly", isSaving], ["onChange", (ev_1) => {
        dispatch(msg([contact, ev_1.target.value]));
    }]]))))), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

export const Contact_contactEmail = (index) => ((contact) => ((isSaving) => ((dispatch) => Contact_contact((tupledArg) => (new Msg(15, [tupledArg[0], tupledArg[1]])), index, contact, isSaving, dispatch))));

export const Contact_contactPhone = (index) => ((contact) => ((isSaving) => ((dispatch) => Contact_contact((tupledArg) => (new Msg(17, [tupledArg[0], tupledArg[1]])), index, contact, isSaving, dispatch))));

export function phones(state, isSaving, dispatch) {
    let elems;
    return viewBox("Phones", new TitleSize(2, []), ofArray([createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", join(" ", ["my-4"])], ["className", "btn-secondary"], ["className", "btn-sm"], ["children", "Add Phone"], ["onClick", (_arg) => {
        dispatch(new Msg(16, []));
    }]])))), createElement("div", createObj(singleton_1((elems = mapIndexed((i, x_1) => Contact_contactPhone(i)(x_1)(isSaving)(dispatch), filter((x) => {
        if (x.ContactType.tag === 0) {
            return true;
        }
        else {
            return false;
        }
    }, state.Contacts)), ["children", reactApi.Children.toArray(Array.from(elems))]))))]));
}

export function emails(state, isSaving, dispatch) {
    let elems;
    return viewBox("Emails", new TitleSize(2, []), ofArray([createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", join(" ", ["my-4"])], ["className", "btn-secondary"], ["className", "btn-sm"], ["children", "Add Email"], ["onClick", (_arg) => {
        dispatch(new Msg(14, []));
    }]])))), createElement("div", createObj(singleton_1((elems = mapIndexed((i, x_1) => Contact_contactEmail(i)(x_1)(isSaving)(dispatch), filter((x) => {
        if (x.ContactType.tag === 1) {
            return true;
        }
        else {
            return false;
        }
    }, state.Contacts)), ["children", reactApi.Children.toArray(Array.from(elems))]))))]));
}

export function addresses(state, isSaving, dispatch) {
    let elems;
    return viewBox("Addresses", new TitleSize(2, []), ofArray([createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", join(" ", ["my-4"])], ["className", "btn-secondary"], ["className", "btn-sm"], ["children", "Add"], ["onClick", (_arg) => {
        dispatch(new Msg(19, []));
    }]])))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "flex-wrap"])], (elems = mapIndexed((i, x) => address(i, x, isSaving, dispatch), state.Addresses), ["children", reactApi.Children.toArray(Array.from(elems))])])))]));
}

export function customerPersonalInfo(state, dispatch, isSaving, customerId) {
    let elems_14, elems_7, elems_6, elems_5, elems_9, elems_8, elems_12, elems_10, elems_11, elems_13;
    return createElement("div", createObj(singleton_1((elems_14 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-1", "bg-white", "box-content"])], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["box-content", "border-2", "p-8", "mb-4", "rounded"])], (elems_5 = toList(delay(() => append_1(singleton_2(Daisy_h5("Business")), delay(() => append_1(singleton_2(createElement("div", createObj(Helpers_combineClasses("divider", empty())))), delay(() => {
        let children_4, children_2;
        return append_1(singleton_2((children_4 = ofArray([(children_2 = singleton_1(createElement("span", {
            className: "label-text",
            children: "Name",
        })), createElement("label", {
            className: "label",
            children: reactApi.Children.toArray(Array.from(children_2)),
        })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["value", state.BusinessName], ["placeholder", "Name"], ["readOnly", isSaving], ["onChange", (ev) => {
            dispatch(new Msg(0, [ev.target.value]));
        }]]))))]), createElement("div", {
            className: "form-control",
            children: reactApi.Children.toArray(Array.from(children_4)),
        }))), delay(() => {
            let children_10, children_7, elems_3;
            return state.IsUsingXero ? singleton_2((children_10 = ofArray([(children_7 = singleton_1(createElement("span", {
                className: "label-text",
                children: "Xero Customer Id",
            })), createElement("label", {
                className: "label",
                children: reactApi.Children.toArray(Array.from(children_7)),
            })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Xero Customer Id"], ["readOnly", isSaving], ["value", state.XeroCustomerId], ["onChange", (ev_1) => {
                dispatch(new Msg(13, [ev_1.target.value]));
            }], (elems_3 = toList(delay(() => map_1((xeroCustomer) => createElement("option", {
                value: xeroCustomer.Id,
                children: xeroCustomer.CustomerName,
            }), state.XeroCustomers))), ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))]), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_10)),
            }))) : empty_1();
        }));
    })))))), ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1"])], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-1", "bg-white", "box-content"])], (elems_8 = [businessDetail(state, isSaving, dispatch)], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "mb-4"])], (elems_12 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-1", "bg-white", "box-content"])], (elems_10 = [phones(state, isSaving, dispatch)], ["children", reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["col-start-2", "bg-white", "box-content"])], (elems_11 = [emails(state, isSaving, dispatch)], ["children", reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["bg-white", "box-content", "rounded"])], (elems_13 = [addresses(state, isSaving, dispatch)], ["children", reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", reactApi.Children.toArray(Array.from(elems_14))]))));
}

export function customerAssessment(state, dispatch, isSaving, customerId) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["col-span-12", "md:col-span-6", "lg:col-span-6", "xl:col-span-4", "bg-white", "box-content", "rounded"])], (elems = [createElement(IndexView_1, {
        customerId: customerId,
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function IndexView(indexViewInputProps) {
    let elems_4, children, children_2, elems_3, elems_2, elems_1;
    const customerId = indexViewInputProps.customerId;
    let patternInput;
    const init_1 = init(customerId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isSaving = BusyModule_isSaving(state_1.IsBusy);
    return container([createElement("div", createObj(singleton_1((elems_4 = [(children = singleton_1(Daisy_h4((`Customer - ${state_1.BusinessName}`).trim())), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append_1(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append_1(singleton_2(["className", join(" ", ["my-4"])]), delay(() => append_1(singleton_2(["className", "btn-primary"]), delay(() => append_1(singleton_2(["className", "btn-sm"]), delay(() => append_1(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_4) => {
        dispatch(new Msg(20, []));
    }])))))))))))))))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(singleton_1((elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-10"])], (elems_2 = [createElement("div", createObj(singleton_1((elems_1 = toList(delay(() => ((state_1.ActiveTab.tag === 1) ? singleton_2(customerAssessment(state_1, dispatch, isSaving, customerId)) : singleton_2(customerPersonalInfo(state_1, dispatch, isSaving, customerId))))), ["children", reactApi.Children.toArray(Array.from(elems_1))]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))]))))], ["children", reactApi.Children.toArray(Array.from(elems_4))]))))]);
}

