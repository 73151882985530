import { Union, Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { option_type, union_type, list_type, bool_type, class_type, record_type, decimal_type, string_type, int32_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";

export class SimproJob extends Record {
    constructor(Id, CompanyId, Description, TotalExTax, Tax, IncTax) {
        super();
        this.Id = (Id | 0);
        this.CompanyId = (CompanyId | 0);
        this.Description = Description;
        this.TotalExTax = TotalExTax;
        this.Tax = Tax;
        this.IncTax = IncTax;
    }
}

export function SimproJob_$reflection() {
    return record_type("Domain.Entity.Simpro.SimproJob", [], SimproJob, () => [["Id", int32_type], ["CompanyId", int32_type], ["Description", string_type], ["TotalExTax", decimal_type], ["Tax", decimal_type], ["IncTax", decimal_type]]);
}

export class SimproJobSection extends Record {
    constructor(Id, JobId, Name, Description) {
        super();
        this.Id = (Id | 0);
        this.JobId = (JobId | 0);
        this.Name = Name;
        this.Description = Description;
    }
}

export function SimproJobSection_$reflection() {
    return record_type("Domain.Entity.Simpro.SimproJobSection", [], SimproJobSection, () => [["Id", int32_type], ["JobId", int32_type], ["Name", string_type], ["Description", string_type]]);
}

export class SimproJobCostCentre extends Record {
    constructor(Id, JobId, Name, SectionId) {
        super();
        this.Id = (Id | 0);
        this.JobId = (JobId | 0);
        this.Name = Name;
        this.SectionId = (SectionId | 0);
    }
}

export function SimproJobCostCentre_$reflection() {
    return record_type("Domain.Entity.Simpro.SimproJobCostCentre", [], SimproJobCostCentre, () => [["Id", int32_type], ["JobId", int32_type], ["Name", string_type], ["SectionId", int32_type]]);
}

export class SimproContractorWorkOrder extends Record {
    constructor(Id, PaymentRunId, JobId, SimproJobId, SimproJobSectionId, SimproJobCostCentreId, SimproContractorId, Date$, LabourCost, LabourHours, TaxCode, OverheadRate, OverheadCost) {
        super();
        this.Id = Id;
        this.PaymentRunId = PaymentRunId;
        this.JobId = JobId;
        this.SimproJobId = (SimproJobId | 0);
        this.SimproJobSectionId = (SimproJobSectionId | 0);
        this.SimproJobCostCentreId = (SimproJobCostCentreId | 0);
        this.SimproContractorId = (SimproContractorId | 0);
        this.Date = Date$;
        this.LabourCost = LabourCost;
        this.LabourHours = LabourHours;
        this.TaxCode = (TaxCode | 0);
        this.OverheadRate = OverheadRate;
        this.OverheadCost = OverheadCost;
    }
}

export function SimproContractorWorkOrder_$reflection() {
    return record_type("Domain.Entity.Simpro.SimproContractorWorkOrder", [], SimproContractorWorkOrder, () => [["Id", class_type("System.Guid")], ["PaymentRunId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["SimproJobId", int32_type], ["SimproJobSectionId", int32_type], ["SimproJobCostCentreId", int32_type], ["SimproContractorId", int32_type], ["Date", class_type("System.DateTimeOffset")], ["LabourCost", decimal_type], ["LabourHours", decimal_type], ["TaxCode", int32_type], ["OverheadRate", decimal_type], ["OverheadCost", decimal_type]]);
}

export class SimproContractorWorkOrderDetail extends Record {
    constructor(PaymentRunNumber, JobTitle, Date$, LabourHours, LabourCost, PaymentRunId) {
        super();
        this.PaymentRunNumber = PaymentRunNumber;
        this.JobTitle = JobTitle;
        this.Date = Date$;
        this.LabourHours = LabourHours;
        this.LabourCost = LabourCost;
        this.PaymentRunId = PaymentRunId;
    }
}

export function SimproContractorWorkOrderDetail_$reflection() {
    return record_type("Domain.Entity.Simpro.SimproContractorWorkOrderDetail", [], SimproContractorWorkOrderDetail, () => [["PaymentRunNumber", string_type], ["JobTitle", string_type], ["Date", class_type("System.DateTimeOffset")], ["LabourHours", decimal_type], ["LabourCost", decimal_type], ["PaymentRunId", class_type("System.Guid")]]);
}

export class SimproLookup extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function SimproLookup_$reflection() {
    return record_type("Domain.Entity.Simpro.SimproLookup", [], SimproLookup, () => [["Id", int32_type], ["Name", string_type]]);
}

export const SimproLookupModule_notSelected = new SimproLookup(-1, "Not Selected");

export class SimproConnectionDetail extends Record {
    constructor(Id, IsDefaultOrgConnection, Name, OrganisationId, CompanyURL, ApiKey, CompanyId, Companies, ContractorId, Contractors, TaxCodeId, TaxCodes) {
        super();
        this.Id = Id;
        this.IsDefaultOrgConnection = IsDefaultOrgConnection;
        this.Name = Name;
        this.OrganisationId = OrganisationId;
        this.CompanyURL = CompanyURL;
        this.ApiKey = ApiKey;
        this.CompanyId = (CompanyId | 0);
        this.Companies = Companies;
        this.ContractorId = (ContractorId | 0);
        this.Contractors = Contractors;
        this.TaxCodeId = (TaxCodeId | 0);
        this.TaxCodes = TaxCodes;
    }
}

export function SimproConnectionDetail_$reflection() {
    return record_type("Domain.Entity.Simpro.SimproConnectionDetail", [], SimproConnectionDetail, () => [["Id", class_type("System.Guid")], ["IsDefaultOrgConnection", bool_type], ["Name", string_type], ["OrganisationId", class_type("System.Guid")], ["CompanyURL", string_type], ["ApiKey", string_type], ["CompanyId", int32_type], ["Companies", list_type(SimproLookup_$reflection())], ["ContractorId", int32_type], ["Contractors", list_type(SimproLookup_$reflection())], ["TaxCodeId", int32_type], ["TaxCodes", list_type(SimproLookup_$reflection())]]);
}

export class SimproApiKey extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["SimproApiKey"];
    }
}

export function SimproApiKey_$reflection() {
    return union_type("Domain.Entity.Simpro.SimproApiKey", [], SimproApiKey, () => [[["Item", string_type]]]);
}

export class SimproSetupDetail extends Record {
    constructor(ApiKey, CompanyURL, CompanyId, ContractorId, TaxCodeId) {
        super();
        this.ApiKey = ApiKey;
        this.CompanyURL = CompanyURL;
        this.CompanyId = CompanyId;
        this.ContractorId = ContractorId;
        this.TaxCodeId = TaxCodeId;
    }
}

export function SimproSetupDetail_$reflection() {
    return record_type("Domain.Entity.Simpro.SimproSetupDetail", [], SimproSetupDetail, () => [["ApiKey", SimproApiKey_$reflection()], ["CompanyURL", string_type], ["CompanyId", option_type(int32_type)], ["ContractorId", option_type(int32_type)], ["TaxCodeId", option_type(int32_type)]]);
}

