import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, unit_type, lambda_type, union_type, list_type, array_type, uint8_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ScanResultBase$1_$reflection, ScanResultStatementDetail_$reflection } from "../Entity/Document.fs.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { printf, toText } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";

export class DocumentService extends Record {
    constructor(scanBankStatement, getUnprocessedScans, deleteScans) {
        super();
        this.scanBankStatement = scanBankStatement;
        this.getUnprocessedScans = getUnprocessedScans;
        this.deleteScans = deleteScans;
    }
}

export function DocumentService_$reflection() {
    return record_type("Logos.Shared.Domain.Remoting.DocumentService", [], DocumentService, () => [["scanBankStatement", lambda_type(class_type("System.Guid"), lambda_type(string_type, lambda_type(array_type(uint8_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [ScanResultBase$1_$reflection(ScanResultStatementDetail_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", ScanResultBase$1_$reflection(ScanResultStatementDetail_$reflection())]], [["ErrorValue", list_type(string_type)]]])]))))], ["getUnprocessedScans", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(ScanResultBase$1_$reflection(ScanResultStatementDetail_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(ScanResultBase$1_$reflection(ScanResultStatementDetail_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]))], ["deleteScans", lambda_type(list_type(ScanResultBase$1_$reflection(ScanResultStatementDetail_$reflection())), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]))]]);
}

export function DocumentService_RouteBuilder(s, m) {
    return toText(printf("/api/%s/%s"))(s)(m);
}

