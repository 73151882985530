import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { tuple_type, union_type, record_type, string_type, class_type, list_type, anonRecord_type, bool_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { JobSearchDetails, Job_$reflection } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { getDefaultConfig, DateRange, DateRange_$reflection } from "../../Component/ReactTailwindDatePicker.fs.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Pagination } from "../../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { Status } from "../../../../Logos.Shared/Domain/Entity/Common.fs.js";
import { searchJobs } from "../../../AppDomain/UseCase/Job.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { exists, ofArray, singleton as singleton_1, empty, choose, map } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { JobDtoModule_toJob } from "../../../../Logos.Shared/Domain/Dto/Job.fs.js";
import { create } from "../../../../Logos.Shared/Domain/Entity/DateRange.fs.js";
import { parse, now, addDays, toUniversalTime } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { generateTimesheetRun } from "../../../AppDomain/UseCase/Timesheet.fs.js";
import { createObj, equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_info, Daisy_progressState, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import react_tailwindcss_datepicker from "react-tailwindcss-datepicker";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";

export class State extends Record {
    constructor(ActiveJobs, StartRunDate, FinishRunDate, IsTimesheetRunGenerated, GeneratedTimesheetRunNumber, IsSelectAll, IsBusy, Errors) {
        super();
        this.ActiveJobs = ActiveJobs;
        this.StartRunDate = StartRunDate;
        this.FinishRunDate = FinishRunDate;
        this.IsTimesheetRunGenerated = IsTimesheetRunGenerated;
        this.GeneratedTimesheetRunNumber = GeneratedTimesheetRunNumber;
        this.IsSelectAll = IsSelectAll;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.TimesheetRun.State", [], State, () => [["ActiveJobs", list_type(anonRecord_type(["IsChecked", bool_type], ["Job", Job_$reflection()]))], ["StartRunDate", class_type("System.DateTime")], ["FinishRunDate", class_type("System.DateTime")], ["IsTimesheetRunGenerated", bool_type], ["GeneratedTimesheetRunNumber", string_type], ["IsSelectAll", bool_type], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetJobsResponse", "SetSelectedJob", "SetRunDate", "GenerateTimesheet", "GenerateTimesheetResponse", "SetSelectAll", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.TimesheetRun.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(anonRecord_type(["IsChecked", bool_type], ["Job", Job_$reflection()])), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(anonRecord_type(["IsChecked", bool_type], ["Job", Job_$reflection()]))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", tuple_type(bool_type, Job_$reflection())]], [["Item", DateRange_$reflection()]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", bool_type]], [["Item", class_type("System.Exception")]]]);
}

export function getJobsCmd() {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        const pagination = new Pagination(10000n, 0n, 1n);
        const jobSearchDetails = new JobSearchDetails("", new Status(0, []));
        const input = searchJobs(appRoot.JobService, AppRootModule_getSelectedOrgId(), jobSearchDetails, pagination);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map((x) => ({
                IsChecked: false,
                Job: JobDtoModule_toJob(x.Job),
            }), input_2.fields[0])])));
            return singleton.Return(value);
        });
    }));
}

export function generateTimesheetsCmd(state) {
    return cmdOfAsync((Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = create(toUniversalTime(state.StartRunDate), toUniversalTime(state.FinishRunDate));
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return generateTimesheetRun(appRoot.ShiftService, AppRootModule_getSelectedOrgId(), input_2.fields[0], choose((x) => {
                    if (x.IsChecked) {
                        return x.Job.Id;
                    }
                    else {
                        return undefined;
                    }
                }, state.ActiveJobs));
            }
        });
    }));
}

export function init() {
    return [new State(empty(), addDays(now(), -7), now(), false, "", false, new Busy(1, []), empty()), getJobsCmd()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(map((y) => {
                if (equals(msg.fields[0][1], y.Job)) {
                    return {
                        IsChecked: msg.fields[0][0],
                        Job: y.Job,
                    };
                }
                else {
                    return y;
                }
            }, state.ActiveJobs), state.StartRunDate, state.FinishRunDate, state.IsTimesheetRunGenerated, state.GeneratedTimesheetRunNumber, state.IsSelectAll, state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.ActiveJobs, msg.fields[0].startDate, msg.fields[0].endDate, state.IsTimesheetRunGenerated, state.GeneratedTimesheetRunNumber, state.IsSelectAll, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.ActiveJobs, state.StartRunDate, state.FinishRunDate, state.IsTimesheetRunGenerated, state.GeneratedTimesheetRunNumber, state.IsSelectAll, new Busy(1, []), empty()), generateTimesheetsCmd(state)];
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ActiveJobs, state.StartRunDate, state.FinishRunDate, state.IsTimesheetRunGenerated, state.GeneratedTimesheetRunNumber, state.IsSelectAll, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.ActiveJobs, state.StartRunDate, state.FinishRunDate, true, msg.fields[0].fields[0], state.IsSelectAll, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 5:
            return [new State(map((y_1) => ({
                IsChecked: msg.fields[0],
                Job: y_1.Job,
            }), state.ActiveJobs), state.StartRunDate, state.FinishRunDate, state.IsTimesheetRunGenerated, state.GeneratedTimesheetRunNumber, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 6:
            return [new State(state.ActiveJobs, state.StartRunDate, state.FinishRunDate, state.IsTimesheetRunGenerated, state.GeneratedTimesheetRunNumber, state.IsSelectAll, new Busy(0, []), singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.ActiveJobs, state.StartRunDate, state.FinishRunDate, state.IsTimesheetRunGenerated, state.GeneratedTimesheetRunNumber, state.IsSelectAll, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(msg.fields[0].fields[0], state.StartRunDate, state.FinishRunDate, state.IsTimesheetRunGenerated, state.GeneratedTimesheetRunNumber, state.IsSelectAll, new Busy(0, []), state.Errors), Cmd_none()];
            }
    }
}

export function IndexView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container(toList(delay(() => {
        let children;
        return append(singleton_2((children = singleton_1(Daisy_h4("Generate Timesheets")), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
            let children_2;
            return append(singleton_2((children_2 = singleton_1(createElement(Daisy_progressState, {
                busy: state_1.IsBusy,
            })), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_2)),
            }))), delay(() => append(state_1.IsTimesheetRunGenerated ? singleton_2(Daisy_info(singleton_1(`Timesheets generated successfully. New Timesheet Run number is ${state_1.GeneratedTimesheetRunNumber}.`))) : empty_1(), delay(() => {
                let children_8, children_5, props_5;
                return append(singleton_2((children_8 = ofArray([(children_5 = singleton_1(createElement("span", {
                    className: "label-text",
                    children: "Date range to process Shifts for",
                })), createElement("label", {
                    className: "label",
                    children: reactApi.Children.toArray(Array.from(children_5)),
                })), (props_5 = {
                    value: new DateRange(state_1.StartRunDate, state_1.FinishRunDate),
                    onChange: (x) => {
                        dispatch(new Msg(2, [new DateRange(parse(x.startDate), parse(x.endDate))]));
                    },
                    showShortcuts: true,
                    configs: getDefaultConfig(now()),
                }, react.createElement(react_tailwindcss_datepicker, props_5))]), createElement("div", {
                    className: "form-control",
                    children: reactApi.Children.toArray(Array.from(children_8)),
                }))), delay(() => {
                    let children_15, children_11, elems_4, elems_6;
                    return append(singleton_2((children_15 = ofArray([(children_11 = singleton_1(createElement("span", {
                        className: "label-text",
                        children: "Jobs to process Shifts for",
                    })), createElement("label", {
                        className: "label",
                        children: reactApi.Children.toArray(Array.from(children_11)),
                    })), createElement("div", createObj(ofArray([["className", join(" ", ["m-2", "flex", "items-center"])], (elems_4 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-sm"], ["className", join(" ", ["mr-2"])], ["checked", state_1.IsSelectAll], ["onChange", (ev) => {
                        dispatch(new Msg(5, [ev.target.checked]));
                    }]])))), createElement("span", {
                        className: "label-text",
                        children: "Select All",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["w-full", "h-64", "overflow-auto", "box-content", "border-2", "rounded"])], (elems_6 = map((x_1) => {
                        let elems_5;
                        return createElement("div", createObj(ofArray([["className", join(" ", ["m-2", "flex", "items-center"])], (elems_5 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-sm"], ["className", join(" ", ["mr-2"])], ["checked", x_1.IsChecked], ["onChange", (ev_1) => {
                            dispatch(new Msg(1, [[ev_1.target.checked, x_1.Job]]));
                        }]])))), createElement("span", {
                            className: "label-text",
                            children: x_1.Job.Title,
                        })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
                    }, state_1.ActiveJobs), ["children", reactApi.Children.toArray(Array.from(elems_6))])])))]), createElement("div", {
                        className: "form-control",
                        children: reactApi.Children.toArray(Array.from(children_15)),
                    }))), delay(() => singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", "Generate Timesheets"], ["disabled", state_1.IsTimesheetRunGenerated ? true : !exists((x_2) => x_2.IsChecked, state_1.ActiveJobs)], ["onClick", (_arg_2) => {
                        dispatch(new Msg(3, []));
                    }]])))))));
                }));
            }))));
        }))));
    })));
}

