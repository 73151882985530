import { Union, Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { getUnionCases, name, decimal_type, getEnumValues, union_type, anonRecord_type, option_type, list_type, bool_type, record_type, enum_type, int32_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Location_$reflection } from "./Location.fs.js";
import { ofSeq } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Set.js";
import { comparePrimitives } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";
import { Address_$reflection } from "./Address.fs.js";
import { DateRange_$reflection } from "./DateRange.fs.js";
import { LeaveRequest_$reflection, PayrollSystemInfo_$reflection, LeaveRequestType_$reflection, EmploymentType_$reflection } from "./User.fs.js";
import { sortBy } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Array.js";
import { DateRangeOffset_$reflection } from "./DateRangeOffset.fs.js";

export class ShiftPreCheckItem extends Record {
    constructor(Id, Name, Description, Priority, Status, Notes, Created) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Priority = (Priority | 0);
        this.Status = (Status | 0);
        this.Notes = Notes;
        this.Created = Created;
    }
}

export function ShiftPreCheckItem_$reflection() {
    return record_type("Domain.Entity.Shift.ShiftPreCheckItem", [], ShiftPreCheckItem, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["Priority", int32_type], ["Status", enum_type("Domain.Entity.Shift.ShiftCheckStatus", int32_type, [["AwaitingAction", 0], ["Accepted", 10], ["Declined", 20]])], ["Notes", string_type], ["Created", class_type("System.DateTimeOffset")]]);
}

export class ShiftPostCheckItem extends Record {
    constructor(Id, Name, Description, Priority, Status, Notes, Created) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Priority = (Priority | 0);
        this.Status = (Status | 0);
        this.Notes = Notes;
        this.Created = Created;
    }
}

export function ShiftPostCheckItem_$reflection() {
    return record_type("Domain.Entity.Shift.ShiftPostCheckItem", [], ShiftPostCheckItem, () => [["Id", class_type("System.Guid")], ["Name", string_type], ["Description", string_type], ["Priority", int32_type], ["Status", enum_type("Domain.Entity.Shift.ShiftCheckStatus", int32_type, [["AwaitingAction", 0], ["Accepted", 10], ["Declined", 20]])], ["Notes", string_type], ["Created", class_type("System.DateTimeOffset")]]);
}

export class Shift extends Record {
    constructor(Id, UserId, OrgId, JobId, JobTitle, JobLocation, JobGeofenceRadius, AppVersion, Os, IsShiftStartAndFinishOutsideGeofenceAllowed, IsShiftStartAndFinishOutsideScheduleAllowed, ShiftScheduleStartTime, ShiftScheduleFinishTime, ShiftPreCheckItems, ShiftPostCheckItems, Created, IsFinished, Updated, ServerUpdated) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.OrgId = OrgId;
        this.JobId = JobId;
        this.JobTitle = JobTitle;
        this.JobLocation = JobLocation;
        this.JobGeofenceRadius = (JobGeofenceRadius | 0);
        this.AppVersion = AppVersion;
        this.Os = Os;
        this.IsShiftStartAndFinishOutsideGeofenceAllowed = IsShiftStartAndFinishOutsideGeofenceAllowed;
        this.IsShiftStartAndFinishOutsideScheduleAllowed = IsShiftStartAndFinishOutsideScheduleAllowed;
        this.ShiftScheduleStartTime = ShiftScheduleStartTime;
        this.ShiftScheduleFinishTime = ShiftScheduleFinishTime;
        this.ShiftPreCheckItems = ShiftPreCheckItems;
        this.ShiftPostCheckItems = ShiftPostCheckItems;
        this.Created = Created;
        this.IsFinished = IsFinished;
        this.Updated = Updated;
        this.ServerUpdated = ServerUpdated;
    }
}

export function Shift_$reflection() {
    return record_type("Domain.Entity.Shift.Shift", [], Shift, () => [["Id", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["JobTitle", string_type], ["JobLocation", Location_$reflection()], ["JobGeofenceRadius", int32_type], ["AppVersion", string_type], ["Os", string_type], ["IsShiftStartAndFinishOutsideGeofenceAllowed", bool_type], ["IsShiftStartAndFinishOutsideScheduleAllowed", bool_type], ["ShiftScheduleStartTime", class_type("System.TimeOnly")], ["ShiftScheduleFinishTime", class_type("System.TimeOnly")], ["ShiftPreCheckItems", list_type(ShiftPreCheckItem_$reflection())], ["ShiftPostCheckItems", list_type(ShiftPostCheckItem_$reflection())], ["Created", class_type("System.DateTime")], ["IsFinished", bool_type], ["Updated", class_type("System.DateTime")], ["ServerUpdated", option_type(class_type("System.DateTime"))]]);
}

export class ShiftComprehensive extends Record {
    constructor(Id, UserId, Username, OrgId, JobId, JobTitle, JobLocation, JobGeofenceRadius, Created, ShiftStarted, ShiftFinished, IsFinished, AppVersion, ShiftPreCheckItems, ShiftPostCheckItems, Os, Updated, ServerUpdated) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.Username = Username;
        this.OrgId = OrgId;
        this.JobId = JobId;
        this.JobTitle = JobTitle;
        this.JobLocation = JobLocation;
        this.JobGeofenceRadius = (JobGeofenceRadius | 0);
        this.Created = Created;
        this.ShiftStarted = ShiftStarted;
        this.ShiftFinished = ShiftFinished;
        this.IsFinished = IsFinished;
        this.AppVersion = AppVersion;
        this.ShiftPreCheckItems = ShiftPreCheckItems;
        this.ShiftPostCheckItems = ShiftPostCheckItems;
        this.Os = Os;
        this.Updated = Updated;
        this.ServerUpdated = ServerUpdated;
    }
}

export function ShiftComprehensive_$reflection() {
    return record_type("Domain.Entity.Shift.ShiftComprehensive", [], ShiftComprehensive, () => [["Id", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["Username", string_type], ["OrgId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["JobTitle", string_type], ["JobLocation", Location_$reflection()], ["JobGeofenceRadius", int32_type], ["Created", class_type("System.DateTime")], ["ShiftStarted", option_type(class_type("System.DateTime"))], ["ShiftFinished", option_type(class_type("System.DateTime"))], ["IsFinished", bool_type], ["AppVersion", string_type], ["ShiftPreCheckItems", list_type(ShiftPreCheckItem_$reflection())], ["ShiftPostCheckItems", list_type(ShiftPostCheckItem_$reflection())], ["Os", string_type], ["Updated", class_type("System.DateTime")], ["ServerUpdated", option_type(class_type("System.DateTime"))]]);
}

export class ShiftLogType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoState", "WorkStarted", "BreakStarted", "BreakFinished", "WorkFinished"];
    }
}

export function ShiftLogType_$reflection() {
    return union_type("Domain.Entity.Shift.ShiftLogType", [], ShiftLogType, () => [[], [["Item", anonRecord_type(["StartTime", class_type("System.DateTime")])]], [["Item", anonRecord_type(["StartTime", class_type("System.DateTime")])]], [["Item", anonRecord_type(["FinishTime", class_type("System.DateTime")])]], [["Item", anonRecord_type(["FinishTime", class_type("System.DateTime")])]]]);
}

export function ShiftLogTypeModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return [1, _arg.fields[0].StartTime];
        case 2:
            return [2, _arg.fields[0].StartTime];
        case 3:
            return [3, _arg.fields[0].FinishTime];
        case 4:
            return [4, _arg.fields[0].FinishTime];
        default:
            return [0, undefined];
    }
}

export function ShiftLogTypeModule_ofInt(_arg1_, _arg1__1) {
    const _arg = [_arg1_, _arg1__1];
    let matchResult;
    switch (_arg[0]) {
        case 1: {
            if (_arg[1] != null) {
                matchResult = 0;
            }
            else {
                matchResult = 4;
            }
            break;
        }
        case 2: {
            if (_arg[1] != null) {
                matchResult = 1;
            }
            else {
                matchResult = 4;
            }
            break;
        }
        case 3: {
            if (_arg[1] != null) {
                matchResult = 2;
            }
            else {
                matchResult = 4;
            }
            break;
        }
        case 4: {
            if (_arg[1] != null) {
                matchResult = 3;
            }
            else {
                matchResult = 4;
            }
            break;
        }
        default:
            matchResult = 4;
    }
    switch (matchResult) {
        case 0:
            return new ShiftLogType(1, [{
                StartTime: _arg[1],
            }]);
        case 1:
            return new ShiftLogType(2, [{
                StartTime: _arg[1],
            }]);
        case 2:
            return new ShiftLogType(3, [{
                FinishTime: _arg[1],
            }]);
        case 3:
            return new ShiftLogType(4, [{
                FinishTime: _arg[1],
            }]);
        default:
            return new ShiftLogType(0, []);
    }
}

export function ShiftLogTypeModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "Work Started";
        case 2:
            return "Break Started";
        case 3:
            return "Break Finished";
        case 4:
            return "Work Finished";
        default:
            return "No State";
    }
}

export class ShiftLog extends Record {
    constructor(Id, ShiftId, GroupId, Version, ShiftLogType, Comment$, Location, DistanceFromJobLocation, Created, Updated, ServerUpdated) {
        super();
        this.Id = Id;
        this.ShiftId = ShiftId;
        this.GroupId = GroupId;
        this.Version = (Version | 0);
        this.ShiftLogType = ShiftLogType;
        this.Comment = Comment$;
        this.Location = Location;
        this.DistanceFromJobLocation = (DistanceFromJobLocation | 0);
        this.Created = Created;
        this.Updated = Updated;
        this.ServerUpdated = ServerUpdated;
    }
}

export function ShiftLog_$reflection() {
    return record_type("Domain.Entity.Shift.ShiftLog", [], ShiftLog, () => [["Id", class_type("System.Guid")], ["ShiftId", class_type("System.Guid")], ["GroupId", class_type("System.Guid")], ["Version", int32_type], ["ShiftLogType", ShiftLogType_$reflection()], ["Comment", string_type], ["Location", Location_$reflection()], ["DistanceFromJobLocation", int32_type], ["Created", class_type("System.DateTime")], ["Updated", class_type("System.DateTime")], ["ServerUpdated", option_type(class_type("System.DateTime"))]]);
}

export const TimesheetRunStatusModule_allStatuses = ofSeq(getEnumValues(enum_type("Domain.Entity.Shift.TimesheetRunStatus", int32_type, [["NoState", 0], ["Created", 10], ["Canceled", 20], ["Approved", 30]])), {
    Compare: comparePrimitives,
});

export class TimesheetJob extends Record {
    constructor(Id, TimesheetRunId, JobId, Title, Address) {
        super();
        this.Id = Id;
        this.TimesheetRunId = TimesheetRunId;
        this.JobId = JobId;
        this.Title = Title;
        this.Address = Address;
    }
}

export function TimesheetJob_$reflection() {
    return record_type("Domain.Entity.Shift.TimesheetJob", [], TimesheetJob, () => [["Id", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["Title", string_type], ["Address", Address_$reflection()]]);
}

export class TimesheetRun extends Record {
    constructor(Id, RunNumber, OrgId, DateRange, Jobs, TimesheetRunStatus, Created, Canceled, Approved) {
        super();
        this.Id = Id;
        this.RunNumber = RunNumber;
        this.OrgId = OrgId;
        this.DateRange = DateRange;
        this.Jobs = Jobs;
        this.TimesheetRunStatus = (TimesheetRunStatus | 0);
        this.Created = Created;
        this.Canceled = Canceled;
        this.Approved = Approved;
    }
}

export function TimesheetRun_$reflection() {
    return record_type("Domain.Entity.Shift.TimesheetRun", [], TimesheetRun, () => [["Id", class_type("System.Guid")], ["RunNumber", string_type], ["OrgId", class_type("System.Guid")], ["DateRange", DateRange_$reflection()], ["Jobs", list_type(TimesheetJob_$reflection())], ["TimesheetRunStatus", enum_type("Domain.Entity.Shift.TimesheetRunStatus", int32_type, [["NoState", 0], ["Created", 10], ["Canceled", 20], ["Approved", 30]])], ["Created", anonRecord_type(["CreatedBy", class_type("System.Guid")], ["CreatedByName", string_type], ["DateCreated", class_type("System.DateTime")])], ["Canceled", option_type(anonRecord_type(["CanceledBy", class_type("System.Guid")], ["CanceledByName", string_type], ["DateCanceled", class_type("System.DateTime")]))], ["Approved", option_type(anonRecord_type(["ApprovedBy", class_type("System.Guid")], ["ApprovedByName", string_type], ["DateApproved", class_type("System.DateTime")]))]]);
}

export class Timesheet extends Record {
    constructor(Id, TimesheetRunId, ShiftId, TotalWorkDuration, TotalWorkDurationAdjustment, IsTotalWorkDurationAdjusted, TotalBreakDuration, TotalBreakDurationAdjustment, IsTotalBreakDurationAdjusted, IsApproved, SupervisorComment, WorkerShiftComments) {
        super();
        this.Id = Id;
        this.TimesheetRunId = TimesheetRunId;
        this.ShiftId = ShiftId;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalWorkDurationAdjustment = TotalWorkDurationAdjustment;
        this.IsTotalWorkDurationAdjusted = IsTotalWorkDurationAdjusted;
        this.TotalBreakDuration = TotalBreakDuration;
        this.TotalBreakDurationAdjustment = TotalBreakDurationAdjustment;
        this.IsTotalBreakDurationAdjusted = IsTotalBreakDurationAdjusted;
        this.IsApproved = IsApproved;
        this.SupervisorComment = SupervisorComment;
        this.WorkerShiftComments = WorkerShiftComments;
    }
}

export function Timesheet_$reflection() {
    return record_type("Domain.Entity.Shift.Timesheet", [], Timesheet, () => [["Id", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["ShiftId", class_type("System.Guid")], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalWorkDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalWorkDurationAdjusted", bool_type], ["TotalBreakDuration", class_type("System.TimeSpan")], ["TotalBreakDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalBreakDurationAdjusted", bool_type], ["IsApproved", bool_type], ["SupervisorComment", string_type], ["WorkerShiftComments", string_type]]);
}

export class TimesheetComprehensive extends Record {
    constructor(Id, TimesheetRunId, ShiftId, ShiftStart, ShiftFinish, JobId, UserId, Username, HourlyRate, ChargeOutRate, TotalWorkDuration, TotalWorkDurationAdjustment, IsTotalWorkDurationAdjusted, TotalBreakDuration, TotalBreakDurationAdjustment, IsTotalBreakDurationAdjusted, OvertimeAfterWorkHours, IsApproved, SupervisorComment, WorkerShiftComments, IsStartOrFinishOutsideJobGeofence, EmploymentType, WeeklyFulltimeWorkHours) {
        super();
        this.Id = Id;
        this.TimesheetRunId = TimesheetRunId;
        this.ShiftId = ShiftId;
        this.ShiftStart = ShiftStart;
        this.ShiftFinish = ShiftFinish;
        this.JobId = JobId;
        this.UserId = UserId;
        this.Username = Username;
        this.HourlyRate = HourlyRate;
        this.ChargeOutRate = ChargeOutRate;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalWorkDurationAdjustment = TotalWorkDurationAdjustment;
        this.IsTotalWorkDurationAdjusted = IsTotalWorkDurationAdjusted;
        this.TotalBreakDuration = TotalBreakDuration;
        this.TotalBreakDurationAdjustment = TotalBreakDurationAdjustment;
        this.IsTotalBreakDurationAdjusted = IsTotalBreakDurationAdjusted;
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.IsApproved = IsApproved;
        this.SupervisorComment = SupervisorComment;
        this.WorkerShiftComments = WorkerShiftComments;
        this.IsStartOrFinishOutsideJobGeofence = IsStartOrFinishOutsideJobGeofence;
        this.EmploymentType = EmploymentType;
        this.WeeklyFulltimeWorkHours = WeeklyFulltimeWorkHours;
    }
}

export function TimesheetComprehensive_$reflection() {
    return record_type("Domain.Entity.Shift.TimesheetComprehensive", [], TimesheetComprehensive, () => [["Id", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["ShiftId", class_type("System.Guid")], ["ShiftStart", option_type(class_type("System.DateTime"))], ["ShiftFinish", option_type(class_type("System.DateTime"))], ["JobId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["Username", string_type], ["HourlyRate", decimal_type], ["ChargeOutRate", decimal_type], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalWorkDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalWorkDurationAdjusted", bool_type], ["TotalBreakDuration", class_type("System.TimeSpan")], ["TotalBreakDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalBreakDurationAdjusted", bool_type], ["OvertimeAfterWorkHours", class_type("System.TimeSpan")], ["IsApproved", bool_type], ["SupervisorComment", string_type], ["WorkerShiftComments", list_type(string_type)], ["IsStartOrFinishOutsideJobGeofence", bool_type], ["EmploymentType", EmploymentType_$reflection()], ["WeeklyFulltimeWorkHours", option_type(class_type("System.TimeSpan"))]]);
}

export class PaymentRun extends Record {
    constructor(Id, OrgId, RunNumber, PaymentRunStatus, Created, Canceled, Paid, PayPeriodStart, PayPeriodFinish) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.RunNumber = RunNumber;
        this.PaymentRunStatus = (PaymentRunStatus | 0);
        this.Created = Created;
        this.Canceled = Canceled;
        this.Paid = Paid;
        this.PayPeriodStart = PayPeriodStart;
        this.PayPeriodFinish = PayPeriodFinish;
    }
}

export function PaymentRun_$reflection() {
    return record_type("Domain.Entity.Shift.PaymentRun", [], PaymentRun, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["RunNumber", string_type], ["PaymentRunStatus", enum_type("Domain.Entity.Shift.PaymentRunStatus", int32_type, [["NoState", 0], ["Created", 10], ["Canceled", 20], ["Paid", 30]])], ["Created", anonRecord_type(["CreatedBy", class_type("System.Guid")], ["DateCreated", class_type("System.DateTime")])], ["Canceled", option_type(anonRecord_type(["CanceledBy", class_type("System.Guid")], ["DateCanceled", class_type("System.DateTime")]))], ["Paid", option_type(anonRecord_type(["DatePaid", class_type("System.DateTime")], ["PaidBy", class_type("System.Guid")]))], ["PayPeriodStart", class_type("System.DateTimeOffset")], ["PayPeriodFinish", class_type("System.DateTimeOffset")]]);
}

export class TimesheetRunDetail extends Record {
    constructor(Id, RunNumber, DateRange) {
        super();
        this.Id = Id;
        this.RunNumber = RunNumber;
        this.DateRange = DateRange;
    }
}

export function TimesheetRunDetail_$reflection() {
    return record_type("Domain.Entity.Shift.TimesheetRunDetail", [], TimesheetRunDetail, () => [["Id", class_type("System.Guid")], ["RunNumber", string_type], ["DateRange", DateRange_$reflection()]]);
}

export class PaymentRunComprehensive extends Record {
    constructor(Id, OrgId, RunNumber, PaymentRunStatus, Created, Canceled, Paid, TimesheetRuns, PayPeriodStart, PayPeriodFinish) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.RunNumber = RunNumber;
        this.PaymentRunStatus = (PaymentRunStatus | 0);
        this.Created = Created;
        this.Canceled = Canceled;
        this.Paid = Paid;
        this.TimesheetRuns = TimesheetRuns;
        this.PayPeriodStart = PayPeriodStart;
        this.PayPeriodFinish = PayPeriodFinish;
    }
}

export function PaymentRunComprehensive_$reflection() {
    return record_type("Domain.Entity.Shift.PaymentRunComprehensive", [], PaymentRunComprehensive, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["RunNumber", string_type], ["PaymentRunStatus", enum_type("Domain.Entity.Shift.PaymentRunStatus", int32_type, [["NoState", 0], ["Created", 10], ["Canceled", 20], ["Paid", 30]])], ["Created", anonRecord_type(["CreatedByName", string_type], ["DateCreated", class_type("System.DateTime")])], ["Canceled", option_type(anonRecord_type(["CanceledByName", string_type], ["DateCanceled", class_type("System.DateTime")]))], ["Paid", option_type(anonRecord_type(["DatePaid", class_type("System.DateTime")], ["PaidByName", string_type]))], ["TimesheetRuns", list_type(TimesheetRunDetail_$reflection())], ["PayPeriodStart", class_type("System.DateTimeOffset")], ["PayPeriodFinish", class_type("System.DateTimeOffset")]]);
}

export class PaymentRunTimesheetRun extends Record {
    constructor(Id, PaymentRunId, TimesheetRunId) {
        super();
        this.Id = Id;
        this.PaymentRunId = PaymentRunId;
        this.TimesheetRunId = TimesheetRunId;
    }
}

export function PaymentRunTimesheetRun_$reflection() {
    return record_type("Domain.Entity.Shift.PaymentRunTimesheetRun", [], PaymentRunTimesheetRun, () => [["Id", class_type("System.Guid")], ["PaymentRunId", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")]]);
}

export class Payment extends Record {
    constructor(Id, PaymentRunId, UserId, HourlyRatePaid, OvertimeRatePaid, DailyWorkDuration, WorkDuration, WorkPay, OvertimeDuration, OvertimeAfterWorkHours, OvertimePay, TotalPay, TotalDaysWorked, TotalLeaveDays, TotalStatDays, DateCreated, Comment$, EmploymentType, WeeklyFulltimeWorkHours) {
        super();
        this.Id = Id;
        this.PaymentRunId = PaymentRunId;
        this.UserId = UserId;
        this.HourlyRatePaid = HourlyRatePaid;
        this.OvertimeRatePaid = OvertimeRatePaid;
        this.DailyWorkDuration = DailyWorkDuration;
        this.WorkDuration = WorkDuration;
        this.WorkPay = WorkPay;
        this.OvertimeDuration = OvertimeDuration;
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.OvertimePay = OvertimePay;
        this.TotalPay = TotalPay;
        this.TotalDaysWorked = (TotalDaysWorked | 0);
        this.TotalLeaveDays = (TotalLeaveDays | 0);
        this.TotalStatDays = (TotalStatDays | 0);
        this.DateCreated = DateCreated;
        this.Comment = Comment$;
        this.EmploymentType = EmploymentType;
        this.WeeklyFulltimeWorkHours = WeeklyFulltimeWorkHours;
    }
}

export function Payment_$reflection() {
    return record_type("Domain.Entity.Shift.Payment", [], Payment, () => [["Id", class_type("System.Guid")], ["PaymentRunId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["HourlyRatePaid", decimal_type], ["OvertimeRatePaid", decimal_type], ["DailyWorkDuration", class_type("System.TimeSpan")], ["WorkDuration", class_type("System.TimeSpan")], ["WorkPay", decimal_type], ["OvertimeDuration", class_type("System.TimeSpan")], ["OvertimeAfterWorkHours", decimal_type], ["OvertimePay", decimal_type], ["TotalPay", decimal_type], ["TotalDaysWorked", int32_type], ["TotalLeaveDays", int32_type], ["TotalStatDays", int32_type], ["DateCreated", class_type("System.DateTime")], ["Comment", string_type], ["EmploymentType", EmploymentType_$reflection()], ["WeeklyFulltimeWorkHours", option_type(class_type("System.TimeSpan"))]]);
}

export class PaymentHolidayDetails extends Record {
    constructor(Id, PaymentRunId, UserId, StartDate, FinishDate, TotalHours, IsHoliday, LeaveType) {
        super();
        this.Id = Id;
        this.PaymentRunId = PaymentRunId;
        this.UserId = UserId;
        this.StartDate = StartDate;
        this.FinishDate = FinishDate;
        this.TotalHours = TotalHours;
        this.IsHoliday = IsHoliday;
        this.LeaveType = LeaveType;
    }
}

export function PaymentHolidayDetails_$reflection() {
    return record_type("Domain.Entity.Shift.PaymentHolidayDetails", [], PaymentHolidayDetails, () => [["Id", class_type("System.Guid")], ["PaymentRunId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["StartDate", class_type("System.DateTimeOffset")], ["FinishDate", class_type("System.DateTimeOffset")], ["TotalHours", class_type("System.TimeSpan")], ["IsHoliday", bool_type], ["LeaveType", LeaveRequestType_$reflection()]]);
}

export class Holiday extends Record {
    constructor(Id, OrgId, Name, Date$, IsActive) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.Name = Name;
        this.Date = Date$;
        this.IsActive = IsActive;
    }
}

export function Holiday_$reflection() {
    return record_type("Domain.Entity.Shift.Holiday", [], Holiday, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["Name", string_type], ["Date", class_type("System.DateTimeOffset")], ["IsActive", bool_type]]);
}

export class PaymentComprehensive extends Record {
    constructor(Id, PaymentRunId, UserId, Username, FirstName, MiddleName, LastName, HourlyRatePaid, OvertimeRatePaid, DailyWorkDuration, PayrollSystemInfo, WorkDuration, WorkPay, OvertimeDuration, OvertimeAfterWorkHours, OvertimePay, TotalPay, TotalDaysWorked, TotalLeaveDays, TotalStatDays, DateCreated, Comment$, EmploymentType, WeeklyFulltimeWorkHours, PayPeriodStart, PayPeriodFinish, ExpensePay) {
        super();
        this.Id = Id;
        this.PaymentRunId = PaymentRunId;
        this.UserId = UserId;
        this.Username = Username;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.HourlyRatePaid = HourlyRatePaid;
        this.OvertimeRatePaid = OvertimeRatePaid;
        this.DailyWorkDuration = DailyWorkDuration;
        this.PayrollSystemInfo = PayrollSystemInfo;
        this.WorkDuration = WorkDuration;
        this.WorkPay = WorkPay;
        this.OvertimeDuration = OvertimeDuration;
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.OvertimePay = OvertimePay;
        this.TotalPay = TotalPay;
        this.TotalDaysWorked = (TotalDaysWorked | 0);
        this.TotalLeaveDays = (TotalLeaveDays | 0);
        this.TotalStatDays = (TotalStatDays | 0);
        this.DateCreated = DateCreated;
        this.Comment = Comment$;
        this.EmploymentType = EmploymentType;
        this.WeeklyFulltimeWorkHours = WeeklyFulltimeWorkHours;
        this.PayPeriodStart = PayPeriodStart;
        this.PayPeriodFinish = PayPeriodFinish;
        this.ExpensePay = ExpensePay;
    }
}

export function PaymentComprehensive_$reflection() {
    return record_type("Domain.Entity.Shift.PaymentComprehensive", [], PaymentComprehensive, () => [["Id", class_type("System.Guid")], ["PaymentRunId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["Username", string_type], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["HourlyRatePaid", decimal_type], ["OvertimeRatePaid", decimal_type], ["DailyWorkDuration", class_type("System.TimeSpan")], ["PayrollSystemInfo", option_type(PayrollSystemInfo_$reflection())], ["WorkDuration", class_type("System.TimeSpan")], ["WorkPay", decimal_type], ["OvertimeDuration", class_type("System.TimeSpan")], ["OvertimeAfterWorkHours", decimal_type], ["OvertimePay", decimal_type], ["TotalPay", decimal_type], ["TotalDaysWorked", int32_type], ["TotalLeaveDays", int32_type], ["TotalStatDays", int32_type], ["DateCreated", class_type("System.DateTime")], ["Comment", string_type], ["EmploymentType", EmploymentType_$reflection()], ["WeeklyFulltimeWorkHours", option_type(class_type("System.TimeSpan"))], ["PayPeriodStart", class_type("System.DateTimeOffset")], ["PayPeriodFinish", class_type("System.DateTimeOffset")], ["ExpensePay", decimal_type]]);
}

export class PaymentTimesheetRun extends Record {
    constructor(TimesheetRun, Timesheets) {
        super();
        this.TimesheetRun = TimesheetRun;
        this.Timesheets = Timesheets;
    }
}

export function PaymentTimesheetRun_$reflection() {
    return record_type("Domain.Entity.Shift.PaymentTimesheetRun", [], PaymentTimesheetRun, () => [["TimesheetRun", TimesheetRun_$reflection()], ["Timesheets", list_type(TimesheetComprehensive_$reflection())]]);
}

export class TimesheetShift extends Record {
    constructor(Shift, ShiftLogs, TotalWorkDuration, TotalBreakDuration) {
        super();
        this.Shift = Shift;
        this.ShiftLogs = ShiftLogs;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalBreakDuration = TotalBreakDuration;
    }
}

export function TimesheetShift_$reflection() {
    return record_type("Domain.Entity.Shift.TimesheetShift", [], TimesheetShift, () => [["Shift", Shift_$reflection()], ["ShiftLogs", list_type(ShiftLog_$reflection())], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalBreakDuration", class_type("System.TimeSpan")]]);
}

export class ShiftDetail extends Record {
    constructor(Shift, ShiftLogs, TotalWorkDuration, TotalBreakDuration) {
        super();
        this.Shift = Shift;
        this.ShiftLogs = ShiftLogs;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalBreakDuration = TotalBreakDuration;
    }
}

export function ShiftDetail_$reflection() {
    return record_type("Domain.Entity.Shift.ShiftDetail", [], ShiftDetail, () => [["Shift", ShiftComprehensive_$reflection()], ["ShiftLogs", list_type(ShiftLog_$reflection())], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalBreakDuration", class_type("System.TimeSpan")]]);
}

export class TimesheetRunUserSummary extends Record {
    constructor(Id, TimesheetRunId, UserId, JobId, TotalWorkDuration, TotalBreakDuration, TotalOvertimeDuration) {
        super();
        this.Id = Id;
        this.TimesheetRunId = TimesheetRunId;
        this.UserId = UserId;
        this.JobId = JobId;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalBreakDuration = TotalBreakDuration;
        this.TotalOvertimeDuration = TotalOvertimeDuration;
    }
}

export function TimesheetRunUserSummary_$reflection() {
    return record_type("Domain.Entity.Shift.TimesheetRunUserSummary", [], TimesheetRunUserSummary, () => [["Id", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalBreakDuration", class_type("System.TimeSpan")], ["TotalOvertimeDuration", class_type("System.TimeSpan")]]);
}

export class TimesheetRunUserSummaryReport extends Record {
    constructor(JobName, TotalWorkDuration, TotalOvertimeDuration) {
        super();
        this.JobName = JobName;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalOvertimeDuration = TotalOvertimeDuration;
    }
}

export function TimesheetRunUserSummaryReport_$reflection() {
    return record_type("Domain.Entity.Shift.TimesheetRunUserSummaryReport", [], TimesheetRunUserSummaryReport, () => [["JobName", string_type], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalOvertimeDuration", class_type("System.TimeSpan")]]);
}

export class TimesheetReportByWorker extends Record {
    constructor(JobName, WorkerName, TotalWorkDuration, TotalOvertimeDuration) {
        super();
        this.JobName = JobName;
        this.WorkerName = WorkerName;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalOvertimeDuration = TotalOvertimeDuration;
    }
}

export function TimesheetReportByWorker_$reflection() {
    return record_type("Domain.Entity.Shift.TimesheetReportByWorker", [], TimesheetReportByWorker, () => [["JobName", string_type], ["WorkerName", string_type], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalOvertimeDuration", class_type("System.TimeSpan")]]);
}

export class StatusToSearch extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["All", "InProgress", "Finished"];
    }
}

export function StatusToSearch_$reflection() {
    return union_type("Domain.Entity.Shift.StatusToSearch", [], StatusToSearch, () => [[], [], []]);
}

export function StatusToSearchModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "InProgress";
        case 2:
            return "Finished";
        default:
            return "All";
    }
}

export function StatusToSearchModule_ofString(x) {
    switch (x) {
        case "All":
            return new StatusToSearch(0, []);
        case "InProgress":
            return new StatusToSearch(1, []);
        case "Finished":
            return new StatusToSearch(2, []);
        default:
            return new StatusToSearch(0, []);
    }
}

export const statusesToSearch = sortBy(name, getUnionCases(StatusToSearch_$reflection(), 0), {
    Compare: comparePrimitives,
});

export class ShiftSearchDetails extends Record {
    constructor(SearchText, StatusToSearch, DateRange) {
        super();
        this.SearchText = SearchText;
        this.StatusToSearch = StatusToSearch;
        this.DateRange = DateRange;
    }
}

export function ShiftSearchDetails_$reflection() {
    return record_type("Domain.Entity.Shift.ShiftSearchDetails", [], ShiftSearchDetails, () => [["SearchText", string_type], ["StatusToSearch", StatusToSearch_$reflection()], ["DateRange", option_type(DateRangeOffset_$reflection())]]);
}

export class TimesheetOfUser extends Record {
    constructor(Id, TimesheetRunId, TimesheetRunNumber, ShiftId, ShiftStartDate, TotalWorkDuration, TotalWorkDurationAdjustment, IsTotalWorkDurationAdjusted, TotalBreakDuration, TotalBreakDurationAdjustment, IsTotalBreakDurationAdjusted, IsApproved, ApprovedBy, ApproveDate, SupervisorComment, WorkerShiftComments, OvertimeAfterWorkHours, Leaves, Holidays) {
        super();
        this.Id = Id;
        this.TimesheetRunId = TimesheetRunId;
        this.TimesheetRunNumber = TimesheetRunNumber;
        this.ShiftId = ShiftId;
        this.ShiftStartDate = ShiftStartDate;
        this.TotalWorkDuration = TotalWorkDuration;
        this.TotalWorkDurationAdjustment = TotalWorkDurationAdjustment;
        this.IsTotalWorkDurationAdjusted = IsTotalWorkDurationAdjusted;
        this.TotalBreakDuration = TotalBreakDuration;
        this.TotalBreakDurationAdjustment = TotalBreakDurationAdjustment;
        this.IsTotalBreakDurationAdjusted = IsTotalBreakDurationAdjusted;
        this.IsApproved = IsApproved;
        this.ApprovedBy = ApprovedBy;
        this.ApproveDate = ApproveDate;
        this.SupervisorComment = SupervisorComment;
        this.WorkerShiftComments = WorkerShiftComments;
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.Leaves = Leaves;
        this.Holidays = Holidays;
    }
}

export function TimesheetOfUser_$reflection() {
    return record_type("Domain.Entity.Shift.TimesheetOfUser", [], TimesheetOfUser, () => [["Id", class_type("System.Guid")], ["TimesheetRunId", class_type("System.Guid")], ["TimesheetRunNumber", string_type], ["ShiftId", class_type("System.Guid")], ["ShiftStartDate", class_type("System.DateTime")], ["TotalWorkDuration", class_type("System.TimeSpan")], ["TotalWorkDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalWorkDurationAdjusted", bool_type], ["TotalBreakDuration", class_type("System.TimeSpan")], ["TotalBreakDurationAdjustment", class_type("System.TimeSpan")], ["IsTotalBreakDurationAdjusted", bool_type], ["IsApproved", bool_type], ["ApprovedBy", option_type(string_type)], ["ApproveDate", option_type(class_type("System.DateTime"))], ["SupervisorComment", string_type], ["WorkerShiftComments", string_type], ["OvertimeAfterWorkHours", class_type("System.TimeSpan")], ["Leaves", list_type(LeaveRequest_$reflection())], ["Holidays", list_type(Holiday_$reflection())]]);
}

export class ShiftUser extends Record {
    constructor(ShiftId, UserId, Username, JobTitle, ShiftStarted, ShiftFinished) {
        super();
        this.ShiftId = ShiftId;
        this.UserId = UserId;
        this.Username = Username;
        this.JobTitle = JobTitle;
        this.ShiftStarted = ShiftStarted;
        this.ShiftFinished = ShiftFinished;
    }
}

export function ShiftUser_$reflection() {
    return record_type("Domain.Entity.Shift.ShiftUser", [], ShiftUser, () => [["ShiftId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["Username", string_type], ["JobTitle", string_type], ["ShiftStarted", option_type(class_type("System.DateTime"))], ["ShiftFinished", option_type(class_type("System.DateTime"))]]);
}

export class TimesheetReportGroupedBy extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Job", "User"];
    }
}

export function TimesheetReportGroupedBy_$reflection() {
    return union_type("Domain.Entity.Shift.TimesheetReportGroupedBy", [], TimesheetReportGroupedBy, () => [[], []]);
}

