import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchTimeSheetPayments } from "../../../AppDomain/UseCase/Timesheet.fs.js";
import { IndexView as IndexView_3, Msg$1, update as update_1, init as init_1 } from "../BaseSearch.fs.js";
import { createObj, uncurry2 } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { Daisy_h5, alignIconCenter, alignCellTextTop, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_1 } from "./TimesheetPaymentRun.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { singleton as singleton_1, append, map, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { DateTime_dateTimeToStringWithDayName, DateTime_dateToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { toLocalTime } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { IndexView as IndexView_2 } from "./TimesheetPaymenRunEdit.fs.js";

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchTimeSheetPayments(appRoot.ShiftService, selectedOrgId, searchText, pagination));
}

export function init() {
    return init_1(uncurry2(searchFunc()));
}

export function update(msg, state) {
    return update_1(uncurry2(searchFunc()), msg, state);
}

export function paymentRunTable(props) {
    let elems, elems_10, children_3, children_1, children_15;
    const children_18 = ofArray([createElement("div", createObj(Helpers_combineClasses("navbar", singleton((elems = [createElement(showModalEx, {
        dialogId: "id-timesheet-search-add-timesheet",
        header: "",
        buttonType: new ButtonType(0, ["Generate Payroll"]),
        width: new Daisy_DialogWidth(3, []),
        heightPercent: "80",
        body: () => createElement(IndexView_1, null),
        onShow: () => {
            props.dispatch(new Msg$1(4, [undefined]));
        },
        onClose: () => {
            props.dispatch(new Msg$1(4, [undefined]));
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_10 = [(children_3 = singleton((children_1 = ofArray([createElement("th", {
        width: 20 + "%",
        children: "Run #",
    }), createElement("th", {
        width: 20 + "%",
        children: "Period",
    }), createElement("th", {
        width: 15 + "%",
        children: "Created",
    }), createElement("th", {
        width: 15 + "%",
        children: "Canceled",
    }), createElement("th", {
        width: 15 + "%",
        children: "Paid",
    }), createElement("th", {
        width: 5 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), (children_15 = toList(delay(() => map((payment) => {
        let elems_9, elems_3, elems_2, elems_1, children_5, elems_4, children_7, elems_5, children_9, elems_6, children_11, elems_7, children_13, elems_8;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_9 = [createElement("td", createObj(ofArray([alignCellTextTop, (elems_3 = [createElement("div", createObj(singleton((elems_2 = [createElement("span", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "center",
        }], (elems_1 = [createElement("i", {
            className: join(" ", toList(delay(() => append(singleton_1("fa"), delay(() => ((payment.Paid != null) ? singleton_1("fa-check") : ((payment.Canceled != null) ? singleton_1("fa-times") : singleton_1("fa-hourglass")))))))),
            style: createObj(toList(delay(() => append(singleton_1(["marginRight", "8px"]), delay(() => ((payment.Paid != null) ? singleton_1(["color", "green"]) : ((payment.Canceled != null) ? singleton_1(["color", "red"]) : singleton_1(["color", "orange"])))))))),
        }), createElement("div", createObj(ofArray([alignCellTextTop, ["children", payment.RunNumber]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), (children_5 = singleton(createElement("div", createObj(ofArray([alignCellTextTop, (elems_4 = [createElement("p", {
            children: [DateTime_dateToStringWithDayName(toLocalTime(payment.PayPeriodStart))],
        }), createElement("p", {
            children: [DateTime_dateToStringWithDayName(toLocalTime(payment.PayPeriodFinish))],
        })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_5)),
        })), (children_7 = singleton(createElement("div", createObj(ofArray([alignCellTextTop, (elems_5 = [createElement("p", {
            children: [DateTime_dateTimeToStringWithDayName(payment.Created.DateCreated)],
        }), createElement("p", {
            children: [payment.Created.CreatedByName],
        })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_7)),
        })), (children_9 = singleton(createElement("div", createObj(ofArray([alignCellTextTop, (elems_6 = toList(delay(() => {
            const matchValue_1 = payment.Canceled;
            if (matchValue_1 == null) {
                return singleton_1(react.createElement(react.Fragment, {}));
            }
            else {
                const x_1 = matchValue_1;
                return append(singleton_1(createElement("p", {
                    children: [DateTime_dateTimeToStringWithDayName(x_1.DateCanceled)],
                })), delay(() => singleton_1(createElement("p", {
                    children: [x_1.CanceledByName],
                }))));
            }
        })), ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_9)),
        })), (children_11 = singleton(createElement("div", createObj(ofArray([alignCellTextTop, (elems_7 = toList(delay(() => {
            const matchValue_2 = payment.Paid;
            if (matchValue_2 == null) {
                return singleton_1(react.createElement(react.Fragment, {}));
            }
            else {
                const x_2 = matchValue_2;
                return append(singleton_1(createElement("p", {
                    children: [DateTime_dateTimeToStringWithDayName(x_2.DatePaid)],
                })), delay(() => singleton_1(createElement("p", {
                    children: [x_2.PaidByName],
                }))));
            }
        })), ["children", reactApi.Children.toArray(Array.from(elems_7))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_11)),
        })), (children_13 = singleton(createElement("div", createObj(ofArray([alignIconCenter, (elems_8 = [createElement(showModalEx, {
            dialogId: payment.Id,
            header: "",
            buttonType: new ButtonType(1, ["fa-edit"]),
            width: new Daisy_DialogWidth(4, []),
            heightPercent: "80",
            body: () => {
                const matchValue = props.state.EntityToEdit;
                if (matchValue == null) {
                    const xs = [Daisy_h5("No Payment Run Selected.")];
                    return react.createElement(react.Fragment, {}, ...xs);
                }
                else {
                    return createElement(IndexView_2, {
                        paymentRunId: matchValue,
                    });
                }
            },
            onShow: () => {
                props.dispatch(new Msg$1(4, [payment.Id]));
            },
            onClose: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_13)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])));
    }, props.state.Entities))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_15)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_10))])]))))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_18)),
    });
}

export function IndexView() {
    return createElement(IndexView_3, {
        entityName: "Payrolls",
        entityTable: paymentRunTable,
        searchFunc: uncurry2(searchFunc()),
    });
}

