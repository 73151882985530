import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchCustomer } from "../../../AppDomain/UseCase/Customer.fs.js";
import { IndexView as IndexView_2, Msg$1, update as update_1, init as init_1 } from "../BaseSearch.fs.js";
import { createObj, uncurry2 } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import { IndexView as IndexView_1 } from "./CustomerEdit.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { alignIconCenter, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { map as map_1, fold, ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { map, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Option_toString } from "../../../../Logos.Shared/Util.fs.js";
import { AddressDtoModule_toAddressString } from "../../../../Logos.Shared/Domain/Dto/Address.fs.js";
import { unwrap, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { ContactDtoModule_toContactTypeAndValue } from "../../../../Logos.Shared/Domain/Dto/Customer.fs.js";

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchCustomer(appRoot.CustomerService, selectedOrgId, searchText, pagination));
}

export function init() {
    return init_1(uncurry2(searchFunc()));
}

export function update(msg, state) {
    return update_1(uncurry2(searchFunc()), msg, state);
}

export function customerTable(props) {
    let elems, elems_4, children_3, children_1, children_7;
    const body = () => {
        const matchValue = props.state.EntityToEdit;
        if (matchValue == null) {
            return createElement(IndexView_1, {
                customerId: "",
            });
        }
        else {
            const x = matchValue;
            return createElement(IndexView_1, {
                customerId: x,
            });
        }
    };
    const children_10 = ofArray([createElement("div", createObj(Helpers_combineClasses("navbar", singleton((elems = [createElement(showModalEx, {
        dialogId: "id-customer-search-add-user",
        header: "",
        buttonType: new ButtonType(0, ["Add Customer"]),
        width: new Daisy_DialogWidth(3, []),
        body: body,
        onShow: () => {
            props.dispatch(new Msg$1(4, [undefined]));
        },
        onClose: () => {
            props.dispatch(new Msg$1(4, [undefined]));
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_4 = [(children_3 = singleton((children_1 = ofArray([createElement("th", {
        width: 5 + "%",
        children: "Business Name",
    }), createElement("th", {
        width: 30 + "%",
        children: "Address",
    }), createElement("th", {
        width: 15 + "%",
        children: "First name",
    }), createElement("th", {
        width: 15 + "%",
        children: "Middle name",
    }), createElement("th", {
        width: 15 + "%",
        children: "Last name",
    }), createElement("th", {
        width: 15 + "%",
        children: "Contact",
    }), createElement("th", {
        width: 30 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), (children_7 = toList(delay(() => map((customer) => {
        let elems_3, elems_1, children_5, elems_2;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_3 = [createElement("td", {
            children: [Option_toString(customer.BusinessName)],
        }), createElement("td", {
            children: [fold((acc, x_1) => {
                if (acc === "") {
                    return AddressDtoModule_toAddressString(x_1);
                }
                else {
                    return acc + AddressDtoModule_toAddressString(x_1);
                }
            }, "", customer.Addresses)],
        }), createElement("td", {
            children: [customer.FirstName],
        }), createElement("td", {
            children: [defaultArg(customer.MiddleName, "")],
        }), createElement("td", {
            children: [customer.LastName],
        }), createElement("td", createObj(singleton((elems_1 = map_1((x_2) => createElement("p", {
            children: ContactDtoModule_toContactTypeAndValue(x_2),
        }), customer.Contacts), ["children", reactApi.Children.toArray(Array.from(elems_1))])))), (children_5 = singleton(createElement("div", createObj(ofArray([alignIconCenter, (elems_2 = [createElement(showModalEx, {
            dialogId: unwrap(customer.Id),
            header: "",
            buttonType: new ButtonType(1, ["fa-edit"]),
            width: new Daisy_DialogWidth(3, []),
            body: body,
            onShow: () => {
                props.dispatch(new Msg$1(4, [customer.Id]));
            },
            onClose: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_5)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
    }, props.state.Entities))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_7)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function IndexView() {
    return createElement(IndexView_2, {
        entityName: "Customer",
        entityTable: customerTable,
        searchFunc: uncurry2(searchFunc()),
    });
}

