import { appRoot, AppRootModule_getSelectedOrgId } from "../../../../Infrastructure/AppRoot.fs.js";
import { searchSimproConnectionDetails } from "../../../../AppDomain/UseCase/Simpro.fs.js";
import { IndexView as IndexView_2, Msg$1, update as update_1, init as init_1 } from "../../BaseSearch.fs.js";
import { createObj, uncurry2 } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import { IndexView as IndexView_1 } from "./SimproConnectionDetailEdit.fs.js";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ButtonType, showModalEx } from "../../../Component/ModalDialog.fs.js";
import { alignIconCenter, Daisy_DialogWidth } from "../../../Component/Component.fs.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ofArray, singleton } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { map, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchSimproConnectionDetails(appRoot.SimproService, selectedOrgId, searchText, pagination));
}

export function init() {
    return init_1(uncurry2(searchFunc()));
}

export function update(msg, state) {
    return update_1(uncurry2(searchFunc()), msg, state);
}

export function simproConnectionDetailTable(props) {
    let elems, elems_3, children_3, children_1, children_7;
    const body = () => {
        const matchValue = props.state.EntityToEdit;
        if (matchValue == null) {
            return createElement(IndexView_1, {
                simproConnectionDetailId: "",
            });
        }
        else {
            const x = matchValue;
            return createElement(IndexView_1, {
                simproConnectionDetailId: x,
            });
        }
    };
    const children_10 = ofArray([createElement("div", createObj(Helpers_combineClasses("navbar", singleton((elems = [createElement(showModalEx, {
        dialogId: "id-simpro-connection-detail-search-add",
        header: "",
        buttonType: new ButtonType(0, ["Add Simpro Connection Detail"]),
        width: new Daisy_DialogWidth(3, []),
        body: body,
        onShow: () => {
            props.dispatch(new Msg$1(4, [undefined]));
        },
        onClose: () => {
            props.dispatch(new Msg$1(4, [undefined]));
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_3 = [(children_3 = singleton((children_1 = ofArray([createElement("th", {
        width: 35 + "%",
        children: "Name",
    }), createElement("th", {
        width: 35 + "%",
        children: "Company URL",
    }), createElement("th", {
        width: 20 + "%",
        children: "Company Id",
    }), createElement("th", {
        width: 10 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), (children_7 = toList(delay(() => map((simproConnectionDetail) => {
        let elems_2, children_5, elems_1;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_2 = [createElement("td", {
            children: [simproConnectionDetail.Name],
        }), createElement("td", {
            children: [simproConnectionDetail.CompanyURL],
        }), createElement("td", {
            children: [simproConnectionDetail.CompanyId],
        }), (children_5 = singleton(createElement("div", createObj(ofArray([alignIconCenter, (elems_1 = [createElement(showModalEx, {
            dialogId: simproConnectionDetail.Id,
            header: "",
            buttonType: new ButtonType(1, ["fa-edit"]),
            width: new Daisy_DialogWidth(3, []),
            body: body,
            onShow: () => {
                props.dispatch(new Msg$1(4, [simproConnectionDetail.Id]));
            },
            onClose: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_5)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }, props.state.Entities))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_7)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function IndexView() {
    return createElement(IndexView_2, {
        entityName: "Simpro Connection Detail",
        entityTable: simproConnectionDetailTable,
        searchFunc: uncurry2(searchFunc()),
    });
}

